import { initReactI18next } from "react-i18next"
import type { TFunction } from "react-i18next"

import { Language, LanguageRegion, TranslationNamespace } from "@/models/i18n"
import { httpClient } from "@/utils/api"
import type { LanguageMappings } from "@crowdin/ota-client"
import i18next from "i18next"
import type { InitOptions, i18n } from "i18next"
import ChainedBackend from "i18next-chained-backend"
import type { I18NextChainedBackend } from "i18next-chained-backend"
import HttpBackend from "i18next-http-backend"

const LOCALIZATION_CDN_KEY: string = process.env.REACT_APP_LOCALIZATION_CDN_KEY
const LOCALIZATION_CDN_URL_ORIGIN = "https://distributions.crowdin.net"
const LOCALIZATION_CDN_URL_PATH: string =
  process.env.REACT_APP_LOCALIZATION_CDN_URL_PATH

interface Manifest {
  languageMapping: LanguageMappings | never[]
  languages: string[]
  timestamp: number
}

export const defaultI18nServiceConfig: InitOptions = {
  lng: LanguageRegion.EnglishUnitedStates,
  fallbackLng: Language.English, // "en" resources should only exist in the codebase (not the l10n CDN) and serve as the "source" resources for the cloud l10n service
  load: "currentOnly",
  interpolation: {
    escapeValue: false,
  },
  ns: [
    TranslationNamespace.Common,
    TranslationNamespace.Dashboard,
    TranslationNamespace.Organization,
    TranslationNamespace.Settings,
    TranslationNamespace.Site,
  ],
  defaultNS: TranslationNamespace.Common,
  fallbackNS: TranslationNamespace.Common,
  react: {
    useSuspense: false,
  },
}

const getLocalizationManifest = async (): Promise<Manifest> => {
  // Ideally we would use @crowdin/ota-client, but module importing/exporting isn't working in TypeScript
  // e.g. const crowdinHttpClient = new OtaClient(LOCALIZATION_CDN_KEY)
  const response = await httpClient.get(
    `${LOCALIZATION_CDN_URL_ORIGIN}/${LOCALIZATION_CDN_KEY}/manifest.json`
  )

  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data
}

/**
 * Initializes an i18n service with the correct configuration
 *
 * @returns A promise that resolves when the i18n service has been initialized
 * @example
 * initializeI18nService(i18nService)
 */
export const initializeI18nService = async (
  i18nService: i18n
): Promise<TFunction> => {
  try {
    // Don't call CDN until it's turned back on
    throw new Error("CrowdIn CDN has been intentionally turned off")

    // Initialize i18n with CDN language support and local fallback
    const manifest: Manifest = await getLocalizationManifest()
    const { languages, languageMapping } = manifest
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const languageRegions: LanguageRegion[] = languages.map((language) => {
      const hasLanguageRegion = !!new Intl.Locale(language).region
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      return hasLanguageRegion ? language : languageMapping[language].locale
    })
    const initOptions: InitOptions & I18NextChainedBackend.BackendOptions = {
      ...defaultI18nServiceConfig,
      backend: {
        backends: [HttpBackend, HttpBackend],
        backendOptions: [
          {
            loadPath: `${LOCALIZATION_CDN_URL_ORIGIN}/${LOCALIZATION_CDN_KEY}/content${LOCALIZATION_CDN_URL_PATH}/{{lng}}/{{ns}}.json?timestamp=${manifest.timestamp}`,
          },
          {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
          },
        ],
      },
      supportedLngs: [Language.English, ...languageRegions],
    } as unknown

    return i18nService.init(initOptions)
  } catch {
    // Initialize i18n with local language support only
    const initOptions: InitOptions & I18NextChainedBackend.BackendOptions = {
      ...defaultI18nServiceConfig,
      backend: {
        backends: [HttpBackend],
        backendOptions: [{ loadPath: "/locales/{{lng}}/{{ns}}.json" }],
      },
      supportedLngs: [
        LanguageRegion.DanishDenmark,
        LanguageRegion.GermanBelgium,
        LanguageRegion.GermanSwitzerland,
        LanguageRegion.GermanGermany,
        LanguageRegion.GermanLuxembourg,
        Language.English,
        LanguageRegion.EnglishGreatBritain,
        LanguageRegion.EnglishIreland,
        LanguageRegion.EnglishUnitedStates,
        LanguageRegion.SpanishSpain,
        LanguageRegion.FrenchBelgium,
        LanguageRegion.FrenchCanada,
        LanguageRegion.FrenchSwitzerland,
        LanguageRegion.FrenchFrance,
        LanguageRegion.FrenchLuxembourg,
        LanguageRegion.ItalianSwitzerland,
        LanguageRegion.ItalianItaly,
        LanguageRegion.DutchBelgium,
        LanguageRegion.DutchNetherlands,
        LanguageRegion.NorwegianNorway,
        LanguageRegion.SwedishSweden,
      ],
    } as unknown

    return i18nService.init(initOptions)
  }
}

/**
 * Creates and initializes a new instance of an i18n service with the correct configuration
 *
 * @returns - The configured i18n service instance
 * @example
 * const i18nService: i18n = createI18nService()
 */
export const createI18nService = (): i18n => {
  const i18nService: i18n = i18next
    .createInstance()
    .use(ChainedBackend)
    .use(initReactI18next)

  return i18nService
}

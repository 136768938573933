import { useOrganizationContext } from "@/contexts"
import { ApiQueryName } from "@/models/api"
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

import type { Schemas } from "../types"
import { useQueryKey, useRenameQueryResult } from "../utils"
import type { RenamedQueryResult } from "../utils"
import { getPlans } from "./get-plans"

interface UseGetPlansOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns a sites list that have been planned with recommendations
 *
 * @example
 * const {
 *   recommendationsPlansData,
 *   recommendationsPlansError,
 *   recommendationsPlansIsError
 *   recommendationsPlansIsLoading,
 * } = useGetPlans({ queryOptions })
 */
export const useGetPlans = (
  options: UseGetPlansOptions = {}
): RenamedQueryResult<
  UseQueryResult<Schemas["PlanResponse"][]>,
  ApiQueryName.RecommendationsPlans
> => {
  const { organization } = useOrganizationContext()

  const hasOrg = Boolean(organization)

  const query = {
    organization_id: Number(organization?.id),
  }

  const queryKey = useQueryKey(
    ApiQueryName.RecommendationsPlans,
    "getMany",
    null,
    {
      ...query,
    }
  )

  let queryParams = {
    queryKey,
    queryFn: () =>
      getPlans({
        query,
      }),
    enabled: hasOrg,
  }

  if (options.queryOptions) {
    queryParams = {
      ...options.queryOptions,
      ...queryParams,
    }
  }

  const queryResult = useQuery<Schemas["PlanResponse"][]>(queryParams)

  return useRenameQueryResult(queryResult, ApiQueryName.RecommendationsPlans)
}

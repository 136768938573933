// @ts-strict-ignore
import React from "react"

import classNames from "classnames"

import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

import { CurrencyWithPlaceholder } from "../../../../../../../components/currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { UsageRateDecimal } from "../../../../../../../components/decimal/usageRateDecimal/usageRateDecimal"
import { If } from "../../../../../../../components/if/if"
import type { IDecimalFormat } from "../../../../../../../utils/decimal"

import styles from "./cellRenderer.module.scss"

enum ColumnIndex {
  "MeterName",
  "MeterType",
  "MeterId",
  "TotalUsage",
  "TotalCost",
  "SubMeter",
}

const format: IDecimalFormat = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "currency",
  currency: "$",
}

/**
 * @deprecated This UI is slated to be completely replaced
 */
const FormattedComponent = ({
  formattedText,
  columnIndex,
}: {
  columnIndex: number
  formattedText: string | number | boolean
}) => {
  switch (columnIndex as ColumnIndex) {
    case ColumnIndex.TotalUsage:
      return <UsageRateDecimal value={String(formattedText)} />
    case ColumnIndex.TotalCost:
      return (
        <CurrencyWithPlaceholder // TODO: Replace with CurrencyWithPreciseFraction once it can return $_.__ in place of ""
          format={format}
          replaceZeroWithPlaceholder
          useAccountingFormatWhenNegative
          value={(String(formattedText) || null) ?? NaN}
        />
      )
    case ColumnIndex.MeterType:
    case ColumnIndex.MeterName:
    case ColumnIndex.MeterId:
      return <span>{formattedText}</span>
    default:
      throw new Error(`Unhandled columnIndex: ${ColumnIndex[columnIndex]}`)
  }
}

/**
 * @deprecated This UI is slated to be completely replaced
 */
export const CellRenderer =
  ({
    gridData,
  }: {
    gridData: [string, string, number, string, string, boolean?][]
  }) =>
  // Disable eslint rule because this component will be deleted soon
  // migrate directories to strict mode
  // eslint-disable-next-line react/display-name, react/function-component-definition, react/prop-types
  ({ columnIndex, key, rowIndex, style }) => {
    // Mass lint disable
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
    const currentCell = gridData?.[rowIndex]?.[columnIndex]
    if (currentCell === undefined) {
      console.error(
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `CellRenderer: undefined cell at row ${rowIndex} column ${columnIndex}, for data ${gridData}`
      )
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return <div key={key} />
    }
    const isIncomplete =
      // Mass lint disable
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
      gridData?.[rowIndex]?.[ColumnIndex.MeterName]?.includes("incomplete")

    const formattedText =
      isIncomplete && columnIndex === ColumnIndex.MeterName
        ? // Mass lint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
          gridData?.[rowIndex]?.[ColumnIndex.MeterName]?.replace(
            "(incomplete)",
            ""
          )
        : currentCell

    return (
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <div key={key} className={styles.rowContent} style={style}>
        <span
          className={classNames("paragraph--small paragraph--small__regular", {
            [styles.site]: columnIndex === 0,
            [styles.visibleRowContent]: columnIndex > 2,
          })}
        >
          {/* Mass lint disable */}
          {/* migrate directories to strict mode */}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition */}
          {gridData?.[rowIndex]?.[ColumnIndex.SubMeter] &&
            columnIndex === 0 && <SubdirectoryArrowRightIcon />}
          {columnIndex === 2 && <span># </span>}
          <FormattedComponent
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            columnIndex={columnIndex}
            formattedText={formattedText}
          />
          <If condition={isIncomplete && columnIndex > ColumnIndex.MeterId}>
            <i className={styles.incompletenessLabel}>(incomplete)</i>
          </If>
        </span>
      </div>
    )
  }

import type { ReactNode } from "react"

import type {
  HorizontalAlignmentType,
  VerticalAlignmentType,
} from "recharts/types/component/DefaultLegendContent"
import type { LayoutType } from "recharts/types/util/types"

import type { ToolTipLabel } from "../../../components/graph/monthly-stacked-area-graph/monthly-stacked-area-graph.types"

export const LEFT_AREA_CHART_ADDITIONAL_SPACING = 12

export interface StackedAreaGraphProps {
  children?: ReactNode
  colors?: string[]
  customTooltip?: React.JSX.Element
  data: StackedAreaGraphDataPoint[]
  isTotalHidden?: boolean
  keys: string[]
  keysToNotGraph?: string[]
  legendAlign?: HorizontalAlignmentType | undefined
  legendLayout?: LayoutType | undefined
  legendVerticalAlign?: VerticalAlignmentType | undefined
  xAxes?: React.ReactNode[]
  yAxisLabel: string
}

export interface StackedAreaGraphDataPoint {
  [key: string]: number | string | Record<string, ToolTipLabel> | undefined
  tooltipLabelsMap: Record<string, ToolTipLabel>
  xaxis?: string
}

import React, { useMemo } from "react"
import { Link as RRLink } from "react-router-dom"

import { renderRowNumberPaginated } from "@/utils/render-row-number-paginated"
import { useUrl } from "@/utils/url"

import { Link } from "@mui/material"
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"

import { baseSiteColumnDef } from "../../../models"
import type { BaseSiteRecord } from "../../../services/use-get-site-explorer"

type CommonSiteGridColumnName = "rowNumber" | "siteName"

export const useCommonSiteGridColumns = <
  TSiteRow extends BaseSiteRecord,
>(): Record<CommonSiteGridColumnName, GridColDef<TSiteRow>> => {
  const { buildSiteUrl } = useUrl()

  const columns = useMemo(() => {
    const columnDefMap: Record<
      CommonSiteGridColumnName,
      GridColDef<TSiteRow>
    > = {
      rowNumber: {
        ...baseSiteColumnDef,
        align: "center",
        disableColumnMenu: true,
        field: "rowNumber",
        headerAlign: "center",
        headerName: "#",
        hideable: false,
        minWidth: 44,
        renderCell: (params: GridRenderCellParams<TSiteRow>) =>
          renderRowNumberPaginated({
            pageIndex: params.row.pageIndex,
            page: params.row.page,
            pageSize: params.row.pageSize,
          }),
        resizable: false,
        sortable: false,
        width: 44,
      },
      siteName: {
        ...baseSiteColumnDef,
        field: "site_name",
        headerName: "Site Name",
        hideable: false,
        renderCell: (params: GridRenderCellParams<TSiteRow>) => {
          return (
            <Link
              component={RRLink}
              noWrap
              to={`${buildSiteUrl(params.row.site_id.toString())}`}
            >
              {params.value}
            </Link>
          )
        },
        width: 240,
      },
    }

    return columnDefMap
  }, [buildSiteUrl])

  return columns
}

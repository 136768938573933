import moment from "moment"
import { Attr, BelongsTo, HasMany, Model } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import type EtagHour from "./etagHour"
import Site from "./site"

@Model()
export default class ServiceHour extends ApplicationRecord {
  public static jsonapiType = "service_hours"

  @HasMany("etag_hours") public etagHours: EtagHour[]

  @BelongsTo("sites") public site: Site

  @Attr() public carbonDioxideEmissions: number

  @Attr() public carbonEquivalentEmissions: number

  @Attr() public nitrogenOxidesEmissions: number

  @Attr() public nitrousOxideEmissions: number

  @Attr() public sulfurDioxideEmissions: number

  @Attr() public methaneEmissions: number

  @Attr() public mercuryEmissions: number

  @Attr() public lossAdjustedMeteredKwh: number

  @Attr() public taggedKwh: number

  @Attr() public curtailedKwh: number

  @Attr() public prescheduleKwh: number

  @Attr() public imbalanceKwh: number

  @Attr() public imbalancePriceCents: number

  @Attr() public marketPriceCents: number

  @Attr() public meteredKwh?: number

  @Attr() public hour: moment.Moment

  @Attr() public energyProviderName: string

  get displayKwh() {
    if (this.taggedKwh && this.taggedKwh > 0) {
      return this.taggedKwh
    }

    return this.prescheduleKwh
  }

  static carbonFreeStats({
    yearStart,
    dayStart,
    site,
  }: {
    dayStart: string
    site: Site
    yearStart: string
  }): Promise<CollectionProxy<ServiceHour>> {
    return this.stats({
      carbon_equivalent_emissions: "sum",
    })
      .where({
        hour: {
          gte: yearStart,
          lt: dayStart,
        },
        site_id: site.id,
      })
      .per(0)
      .all()
  }
}

import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import type { IRange } from "@/models/range"
import moment from "moment"
import type { Moment } from "moment"

import { buildUrlSearchParamsString } from "../url-utils"

interface UseSearchParamsYearProps {
  /** The valid range */
  validRange: IRange<Moment> | undefined
}

interface UseSearchParamsYearValue {
  /** The end of the year */
  yearEnd: Moment
  /** The start of the year */
  yearStart: Moment
}

/**
 * Returns the year from the search params,
 * ensures that the year is within the valid range,
 * and sets a default year if the search param is invalid or missing.
 * @example
 * const { yearStart, yearEnd } = useSearchParamsYear({
 *   validRange: {
 *     start: moment("2022-01-01"),
 *     end: moment("2024-12-31"),
 *   },
 * })
 */
export const useSearchParamsYear = ({
  validRange,
}: UseSearchParamsYearProps): UseSearchParamsYearValue => {
  const [searchParams, setSearchParams] = useSearchParams()

  const year: string = searchParams.get("year")
  const yearEnd: Moment = useMemo(() => moment(year).endOf("year"), [year])
  const yearStart: Moment = useMemo(() => moment(year).startOf("year"), [year])

  useEffect(() => {
    if (
      validRange?.start.isValid() &&
      validRange.end.isValid() &&
      !yearStart?.isBetween(validRange.start, validRange.end, "year", "[]")
    ) {
      const newYear: Moment = moment.min(
        moment().subtract(1, "year"),
        validRange.end
      )

      const newSearchParamsString = buildUrlSearchParamsString(
        {
          year: newYear,
        },
        searchParams,
        {
          dateFormat: "YYYY",
        }
      )

      setSearchParams(newSearchParamsString, { replace: true })
    }
  }, [searchParams, setSearchParams, validRange, yearStart])

  return { yearEnd, yearStart }
}

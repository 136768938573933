import { useMemo } from "react"

import type { GraphitiRecord, PaginatedData } from "@/nzds/data-grid"
import type { Schemas } from "@/services/types"
import type { InfiniteData } from "@tanstack/react-query"

import type {
  BaseSiteGridRecord,
  BaseSiteRecord,
} from "../../../services/use-get-site-explorer"

interface UseComputedSiteRowsOptions<TData> {
  data: InfiniteData<TData> | undefined
  sitesWithPlans: Record<string, Schemas["PlanResponse"]> | undefined
}

export const useComputedSiteRows = <
  TSiteRecord extends BaseSiteRecord,
  TSiteGridRecord extends BaseSiteGridRecord,
>({
  data,
  sitesWithPlans,
}: UseComputedSiteRowsOptions<
  PaginatedData<GraphitiRecord<TSiteRecord>>
>): TSiteGridRecord[] => {
  const computedRows = useMemo(() => {
    const pagesMap: Record<string, TSiteGridRecord[]> = {}

    if (data && data.pages.length > 0) {
      for (const page of data.pages) {
        const rows: TSiteGridRecord[] = []
        page.items.forEach((item, index) => {
          rows.push({
            ...item.attributes,
            id: item.id,
            page: page.page,
            pageIndex: index,
            pageSize: page.size,
            planId: sitesWithPlans?.[item.attributes.site_id]?.id,
          } as unknown as TSiteGridRecord)
        })
        if (page.page) {
          pagesMap[page.page] = rows
        } else {
          pagesMap[1] = rows
        }
      }

      // return the rows in order
      const orderedRows: TSiteGridRecord[] = []

      for (const key of Object.keys(pagesMap).sort((a, b) =>
        a.localeCompare(b)
      )) {
        orderedRows.push(...pagesMap[key])
      }

      return orderedRows
    }

    return []
  }, [data, sitesWithPlans])

  return computedRows
}

import React from "react"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"

import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material"

import { pxToRem } from "../../../../utils/unit"
import type { ReportCard } from "../../models/report"
import { ReportingLink } from "../reporting-link"

interface ReportTwoColumnLayoutProps {
  actions: JSX.Element
  details: JSX.Element
  detailsTitle: string
  filters: JSX.Element
  notifications?: JSX.Element
  report: ReportCard
}

const styles = {
  column1Section: {
    padding: 2,
  },
}

const ReportTwoColumnLayout = ({
  actions,
  detailsTitle,
  details,
  filters,
  notifications,
  report,
}: ReportTwoColumnLayoutProps): JSX.Element => (
  <>
    <PageHeader title={report.title} />
    <PageHeaderActionBar hasTabs={false}>
      <ReportingLink />
    </PageHeaderActionBar>
    <Page fullHeight>
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            flex: 1,
          }}
        >
          <Grid
            item
            sx={{
              minHeight: pxToRem(240),
              position: "relative",
              width: pxToRem(472),
            }}
          >
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: pxToRem(16),
                bottom: 0,
                left: pxToRem(16),
                right: 0,
              }}
            >
              <Box
                data-e2e="resource-summary-left-grid-container"
                sx={{
                  ...styles.column1Section,
                  flex: 1,
                  overflow: "auto",
                }}
              >
                <Typography mb={2}>{report.description}</Typography>
                {notifications && <Box mb={2}>{notifications}</Box>}
                <Divider sx={{ marginBottom: 2 }} />
                {filters}
              </Box>
              <Divider />
              <Box sx={styles.column1Section} textAlign="right">
                {actions}
              </Box>
            </Paper>
          </Grid>
          <Grid
            item
            sx={{
              minWidth: pxToRem(240),
              display: "flex",
              flexDirection: "column",
            }}
            xs
          >
            <Typography mb={1} variant="h2">
              {detailsTitle}
            </Typography>
            <Box sx={{ flex: 1, position: "relative" }}>
              {/* This is necessary for the MUI Grid to take up the available height */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                {details}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Page>
  </>
)

export default ReportTwoColumnLayout

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { RouteObject } from "react-router-dom"

import { RecommendationsPath, RootPath } from "@/models/route"

export const recommendationsRoutes: RouteObject[] = [
  {
    path: RootPath.Recommendations,
    lazy: async () => {
      const { RecommendationsRoot } = await import("./pages")
      return { Component: RecommendationsRoot }
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { RecommendationsIndexRouteGuard } = await import("./pages")
          return { Component: RecommendationsIndexRouteGuard }
        },
      },
      {
        path: RecommendationsPath.Plan,
        lazy: async () => {
          const { RecommendationsPlanRoute } = await import("./pages")
          return { Component: RecommendationsPlanRoute }
        },
      },
      {
        path: RecommendationsPath.Site,
        lazy: async () => {
          const { SiteRecommendationsRoute } = await import("./pages")
          return { Component: SiteRecommendationsRoute }
        },
      },
      {
        path: RecommendationsPath.SiteGoals,
        lazy: async () => {
          const { SiteGoalsRoute } = await import("./pages")
          return { Component: SiteGoalsRoute }
        },
      },
      {
        path: RecommendationsPath.SitePlan,
        lazy: async () => {
          const { SitePlanDetailsRoute } = await import("./pages")
          return { Component: SitePlanDetailsRoute }
        },
      },
      {
        path: RecommendationsPath.SitePlanEdit,
        lazy: async () => {
          const { SitePlanDetailsEditRoute } = await import("./pages")
          return { Component: SitePlanDetailsEditRoute }
        },
      },
    ],
  },
]

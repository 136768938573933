import React from "react"
import type { FC } from "react"

import { SvgIcon } from "@mui/material"

export const RightPanelCloseIcon: FC = () => (
  <SvgIcon data-testid="sidePanelCloseIcon" viewBox="0 0 20 20">
    <svg
      height="20"
      viewBox="0 -960 960 960"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M297-380q0 9.917 9.5 13.458Q316-363 323-370l89-89q9-9 9-21t-9-21l-89-89q-7-7-16.5-3.458Q297-589.917 
      297-580v200ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 
      17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm453-60h147v-600H633v600Zm-60 0v-600H180v600h393Zm60 0h147-147Z"
      />
    </svg>
  </SvgIcon>
)

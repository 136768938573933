// @ts-strict-ignore
import type { FC } from "react"
import React, { useCallback, useMemo } from "react"

import { Page, PageCard, PageHeaderActionBar } from "@/components"
import { NzDataGrid } from "@/components/nzDataGrid"
import { useOrganizationContext } from "@/contexts"
import {
  DEFAULT_PAGE_SIZE,
  DataGridLegend,
  DataGridToolbar,
  useEditRow,
  useGridFilterModelChange,
  usePageScrollChange,
  usePaginatedScroll,
  useSaveGridModel,
} from "@/nzds/data-grid"
import { MonthRangePicker } from "@/nzds/inputs/month-range-picker"
import { HStack } from "@/nzds/layout"
import { useAvailableReportDates, useGetPlans } from "@/services"
import { useGetBuildingTypes, useUpdateSite } from "@/services/site"
import { formatToYYYYMMDD } from "@/utils"
import {
  useDateRangeChange,
  useSearchParamsDateRange,
  useSearchParamsPage,
} from "@/utils/url"
import { useConfirm } from "material-ui-confirm"

import { Box } from "@mui/material"
import { useGridApiRef } from "@mui/x-data-grid-pro"

import { EditSiteConfirmContent } from "../../components/edit-site-confirm-content/edit-site-confirm-content"
import type {
  SiteExplorerGridRecord,
  SiteExplorerPaginatedData,
  SiteExplorerRecord,
} from "../../services/use-get-site-explorer"
import { useGetSiteExplorer } from "../../services/use-get-site-explorer"
import { useComputedSiteRows, useSitesWithPlans } from "../utils"
import {
  defaultColumnVisibilityModel,
  defaultPinnedColumns,
  defaultSortModel,
  useOverviewGridColumns,
} from "./utils/use-overview-grid-columns/use-overview-grid-columns"

export const SitesOverviewRoute: FC = () => {
  const gridApiRef = useGridApiRef()

  const handleDateRangeChange = useDateRangeChange()

  const handleOnFilterModelChange = useGridFilterModelChange()

  const { recommendationsPlansData, recommendationsPlansIsLoading } =
    useGetPlans()

  const sitesWithPlans = useSitesWithPlans(recommendationsPlansData)

  const { organization } = useOrganizationContext()
  const { availableReportDatesData } = useAvailableReportDates(organization?.id)

  const { buildingTypesData, buildingTypesIsFetching } = useGetBuildingTypes()

  const { page } = useSearchParamsPage()

  const { startDate, endDate } = useSearchParamsDateRange({
    validRange: availableReportDatesData,
  })

  const {
    handleRowEditCancelClick,
    handleRowEditClick,
    handleRowEditSaveClick,
    handleRowEditStop,
    rowModesModel,
  } = useEditRow()

  const defaultColumns = useOverviewGridColumns({
    buildingTypes: buildingTypesData,
    handleRowEditCancelClick,
    handleRowEditClick,
    handleRowEditSaveClick,
    rowModesModel,
  })

  const {
    gridModel: { columnVisibilityModel, columns, pinnedColumns, sortModel },
    handleColumnOrderChange,
    handleColumnVisibilityModelChange,
    handlePinnedColumnsChange,
    handleSortModelChange,
  } = useSaveGridModel<SiteExplorerGridRecord>({
    gridName: "sitesOverview",
    gridModel: {
      columns: defaultColumns,
      columnVisibilityModel: defaultColumnVisibilityModel,
      pinnedColumns: defaultPinnedColumns,
      sortModel: defaultSortModel,
    },
  })

  const { siteUpdateMutateAsync, siteUpdateIsLoading } = useUpdateSite()
  const confirm = useConfirm()

  const isDateRangeValid = useMemo(() => {
    const isRangeValid = !!startDate.isValid() && !!endDate.isValid()
    const isStartDateStartOfMonth = startDate
      .clone()
      .startOf("day")
      .isSame(startDate.clone().startOf("month"))
    const isEndDateEndOfMonth = endDate
      .clone()
      .endOf("day")
      .isSame(endDate.clone().endOf("month"))

    return isRangeValid && isStartDateStartOfMonth && isEndDateEndOfMonth
  }, [endDate, startDate])

  const isSiteExplorerQueryEnabled =
    Boolean(organization?.id) &&
    Boolean(sortModel[0].field) &&
    Boolean(sortModel[0].sort) &&
    isDateRangeValid

  const {
    siteExplorerData,
    siteExplorerIsFetching,
    siteExplorerFetchNextPage,
    siteExplorerFetchPreviousPage,
    siteExplorerHasNextPage,
    siteExplorerHasPreviousPage,
    siteExplorerIsFetchingNextPage,
    siteExplorerIsFetchingPreviousPage,
  } = useGetSiteExplorer({
    parameters: {
      query: {
        "filter[organization_id]": organization?.id,
        "filter[start_date][gte]": formatToYYYYMMDD(
          startDate.clone().startOf("month")
        ),
        "filter[end_date][lte]": formatToYYYYMMDD(
          endDate.clone().endOf("month")
        ),
        "page[size]": DEFAULT_PAGE_SIZE,
        sort: `${sortModel[0].sort === "asc" ? "" : "-"}${sortModel[0].field}`,
        "stats[page]": "count",
      },
    },
    initialPage: page,
    queryOptions: {
      enabled: isSiteExplorerQueryEnabled,
    },
  })

  const rows = useComputedSiteRows<SiteExplorerRecord, SiteExplorerGridRecord>({
    data: siteExplorerData,
    sitesWithPlans,
  })

  const handleScrollPageChange = usePageScrollChange()

  usePaginatedScroll<SiteExplorerPaginatedData>({
    isEnabled: isSiteExplorerQueryEnabled,
    apiRef: gridApiRef,
    data: siteExplorerData,
    fetchNextPage: siteExplorerFetchNextPage,
    fetchPreviousPage: siteExplorerFetchPreviousPage,
    hasNextPage: siteExplorerHasNextPage,
    hasPreviousPage: siteExplorerHasPreviousPage,
    isFetchingNextPage: siteExplorerIsFetchingNextPage,
    isFetchingPreviousPage: siteExplorerIsFetchingPreviousPage,
    onScrollPageChange: handleScrollPageChange,
    currentPage: page,
  })

  const [columnsButtonEl, setColumnsButtonEl] =
    React.useState<HTMLButtonElement | null>(null)

  const handleProcessRowUpdate = useCallback(
    async (
      newValue: SiteExplorerGridRecord,
      value: SiteExplorerGridRecord
    ): Promise<SiteExplorerGridRecord> => {
      if (
        newValue.building_type === value.building_type &&
        newValue.sq_ft === value.sq_ft
      ) {
        return value
      }

      try {
        await confirm({
          title: "Confirm Updates",
          content: <EditSiteConfirmContent siteName={newValue.site_name} />,
          confirmationButtonProps: {
            color: "primary",
            variant: "contained",
          },
          confirmationText: "Confirm",
          cancellationButtonProps: { color: "primary", variant: "text" },
          cancellationText: "Cancel",
          dialogActionsProps: {
            disableSpacing: true,
          },
          dialogProps: {
            maxWidth: "xs",
          },
        })

        await siteUpdateMutateAsync({
          newAttributes: {
            buildingType: newValue.building_type,
            sqFt: newValue.sq_ft,
          },
          serviceEntityKey: newValue.id,
        })

        return newValue
      } catch {
        return value
      }
    },
    [confirm, siteUpdateMutateAsync]
  )

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.error(error)
  }, [])

  return (
    <>
      <PageHeaderActionBar>
        <HStack alignItems="center" gap={2} id="datagrid-toolbar" />
      </PageHeaderActionBar>
      <Page fullHeight>
        <PageCard
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box mb={3}>
            <DataGridLegend
              itemCount={siteExplorerData?.pages[0]?.total}
              itemLabel="Sites"
            />
          </Box>
          <Box flex={1} position="relative">
            <Box
              sx={{
                bottom: 0,
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <NzDataGrid
                apiRef={gridApiRef}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                disableColumnFilter
                isLoading={
                  buildingTypesIsFetching ||
                  recommendationsPlansIsLoading ||
                  siteExplorerIsFetching ||
                  siteUpdateIsLoading
                }
                onColumnOrderChange={handleColumnOrderChange}
                onColumnVisibilityModelChange={
                  handleColumnVisibilityModelChange
                }
                onFilterModelChange={handleOnFilterModelChange}
                onPinnedColumnsChange={handlePinnedColumnsChange}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onRowEditStop={handleRowEditStop}
                onSortModelChange={handleSortModelChange}
                pinnedColumns={pinnedColumns}
                processRowUpdate={handleProcessRowUpdate}
                rowModesModel={rowModesModel}
                rows={rows}
                slotProps={{
                  toolbar: {
                    columnsButtonEl,
                    setColumnsButtonEl,
                    startActions: (
                      <MonthRangePicker
                        availableMaxMinMonths={availableReportDatesData}
                        onChange={handleDateRangeChange}
                        value={{
                          start: startDate,
                          end: endDate,
                        }}
                      />
                    ),
                  },
                  panel: {
                    anchorEl: columnsButtonEl,
                  },
                }}
                slots={{
                  toolbar: DataGridToolbar,
                }}
                sortingMode="server"
                sortModel={sortModel}
              />
            </Box>
          </Box>
        </PageCard>
      </Page>
    </>
  )
}

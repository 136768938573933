import React from "react"
import type { FC } from "react"

import reportInProgress from "../../assets/images/report-in-progress.svg"
import { DataAlertInProgressText } from "../../models/report"
import DataAlertBase from "../dataAlertBase/dataAlertBase"

const DataAlertInProgress: FC = () => (
  <DataAlertBase
    altText={DataAlertInProgressText.AltText}
    body={DataAlertInProgressText.Body}
    img={reportInProgress}
    title={DataAlertInProgressText.Title}
  />
)

export default DataAlertInProgress

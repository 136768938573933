import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { buildUrlSearchParamsString } from "../url-utils"

const defaultPage = 1

const isPageValid = (page: number): boolean => {
  return Number.isFinite(page) && page > 0
}

interface UseSearchParamsPageValue {
  /** The page */
  page: number
}

/**
 * Returns the page from the search params
 * and sets a default page if the search params are invalid or missing.
 * @example
 * const { page } = useSearchParamsPage()
 */
export const useSearchParamsPage = (): UseSearchParamsPageValue => {
  const [searchParams, setSearchParams] = useSearchParams()

  const pageParam: number = Number.parseInt(searchParams.get("page"))

  useEffect(() => {
    if (!isPageValid(pageParam)) {
      const newSearchParamsString = buildUrlSearchParamsString(
        {
          page: defaultPage,
        },
        searchParams
      )

      setSearchParams(newSearchParamsString, { replace: true })
    }
  }, [pageParam, searchParams, setSearchParams])

  return { page: isPageValid(pageParam) ? pageParam : defaultPage }
}

import { useCallback } from "react"
import { generatePath } from "react-router-dom"

import type { Organization } from "@/models/organization"
import { RecommendationsPath, RootPath } from "@/models/route"
import { useActiveOrganizationId } from "@/services/organization"

const getAuthenticatedRoutePath = (
  organizationId: Organization["id"],
  path: string
): string => {
  return `/${organizationId}/${path}`
}

interface UseUrlValue {
  /**
   * The root assistant path taking into account the active organization
   */
  assistantPath: string

  buildRecommendationsPlanDetailsPath: (id: number, site_id: number) => string

  /**
   * Builds a reporting url taking into account the active organization
   *
   * @param report The report
   * @returns A reporting url
   *
   * @example
   * const url = buildReportingUrl("resource-summary")
   */
  buildReportingUrl: (report: string) => string

  /**
   * Builds a site url taking into account the active organization
   *
   * @param siteId The site id or url param
   * @param tab The active tab
   * @returns A site url
   *
   * @example
   * const url = buildSitesUrl(":siteId")
   * @example
   * const url = buildSitesUrl("1")
   * @example
   * const url = buildSitesUrl("1", "overview")
   */
  buildSiteUrl: (siteId: string, tab?: string) => string

  /**
   * Builds a sites url taking into account the active organization
   *
   * @param tab The active tab
   * @returns A sites url
   *
   * @example
   * const url = buildSitesUrl()
   * @example
   * const url = buildSitesUrl("overview")
   */
  buildSitesUrl: (tab?: string) => string

  /**
   * The root dashboard path taking into account the active organization
   */
  dashboardPath: string

  /**
   * The root data path taking into account the active organization
   */
  dataPath: string

  /**
   * The root financial path taking into account the active organization
   */
  financialPath: string

  /**
   * The root recommendations path taking into account the active organization
   */
  recommendationsPath: string

  /**
   * The root reporting path taking into account the active organization
   */
  reportingPath: string

  /**
   * The root sites path taking into account the active organization
   */
  sitesPath: string
}

/**
 * Provides url paths and utility functions for the application
 *
 * @returns The paths and functions
 * @example
 * const {
 *   assistantPath,
 *   buildSiteUrl,
 *   buildSitesUrl,
 *   dashboardPath,
 * } = useUrl()
 */
export const useUrl = (): UseUrlValue => {
  const { activeOrganizationId } = useActiveOrganizationId()

  const assistantPath = getAuthenticatedRoutePath(
    activeOrganizationId,
    RootPath.Assistant
  )

  const dashboardPath = getAuthenticatedRoutePath(
    activeOrganizationId,
    RootPath.Dashboard
  )

  const dataPath = getAuthenticatedRoutePath(
    activeOrganizationId,
    RootPath.Data
  )

  const financialPath = getAuthenticatedRoutePath(
    activeOrganizationId,
    RootPath.Financial
  )

  const recommendationsPath = getAuthenticatedRoutePath(
    activeOrganizationId,
    RootPath.Recommendations
  )

  const reportingPath = getAuthenticatedRoutePath(
    activeOrganizationId,
    RootPath.Report
  )

  const sitesPath = getAuthenticatedRoutePath(
    activeOrganizationId,
    RootPath.Site
  )

  const buildRecommendationsPlanDetailsPath = useCallback(
    (id: number, site_id: number) =>
      generatePath(
        `${recommendationsPath}/${RecommendationsPath.SitePlanEdit}`,
        {
          planId: `${id}`,
          siteId: `${site_id}`,
        }
      ),
    [recommendationsPath]
  )

  const buildReportingUrl = useCallback(
    (report: string) => {
      return `${reportingPath}/${report}`
    },
    [reportingPath]
  )

  const buildSiteUrl = useCallback(
    (siteId: string, tab?: string) => {
      if (tab) {
        return `${sitesPath}/${siteId}/${tab}`
      }

      return `${sitesPath}/${siteId}`
    },
    [sitesPath]
  )

  const buildSitesUrl = useCallback(
    (tab?: string) => {
      if (tab) {
        return `${sitesPath}/${tab}`
      }

      return sitesPath
    },
    [sitesPath]
  )

  return {
    assistantPath,
    buildRecommendationsPlanDetailsPath,
    buildReportingUrl,
    buildSiteUrl,
    buildSitesUrl,
    dashboardPath,
    dataPath,
    financialPath,
    recommendationsPath,
    reportingPath,
    sitesPath,
  }
}

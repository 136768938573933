import { useEffect, useRef } from "react"

import type {
  FetchNextPageOptions,
  FetchPreviousPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query"

import type { GridEventListener, useGridApiRef } from "@mui/x-data-grid-pro"
import { gridPaginatedVisibleSortedGridRowEntriesSelector } from "@mui/x-data-grid-pro"

interface BaseRecord {
  id: number | string
}

export interface PaginatedRecord extends BaseRecord {
  page: number
  pageIndex: number
  pageSize: number
}

export interface PageStats {
  number: number
  pages: number
  size: number
  total: number
}

interface MetaWithPageStats {
  stats: {
    page: {
      count: PageStats
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GraphitiRecord<TRecord = Record<string, any>> {
  attributes: TRecord
  id: string
  type: string
}

interface PaginatedGraphitiCollectionResponse<TRecord extends PaginatedRecord> {
  data: GraphitiRecord<TRecord>[]
  meta: MetaWithPageStats
}

export const graphitiCollectionResponseToPaginatedData = <
  TRecord extends PaginatedRecord,
>(
  response: PaginatedGraphitiCollectionResponse<TRecord>
): PaginatedData<GraphitiRecord<TRecord>> => {
  return {
    items: response.data,
    page: response.meta.stats.page.count.number,
    pages: response.meta.stats.page.count.pages,
    size: response.meta.stats.page.count.size,
    total: response.meta.stats.page.count.total,
  }
}

export interface PaginatedData<TRecord extends BaseRecord = BaseRecord> {
  items: TRecord[]
  /** page number of this page */
  page: number
  /** total number of pages */
  pages: number
  /** page size */
  size: number
  /** total number of items */
  total: number
}

interface UsePaginatedScrollOptions<TData> {
  apiRef: ReturnType<typeof useGridApiRef>
  /**
   * The current page number, usually taken from a searchParam in the url and sent as a number
   * ie `Number(searchParams.get("page"))`
   */
  currentPage?: number
  data?: InfiniteData<TData>
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<TData>>
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions
  ) => Promise<InfiniteQueryObserverResult<TData>>
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  /** is the query enabled? similar to react query enabled option */
  isEnabled: boolean
  isFetchingNextPage?: boolean
  isFetchingPreviousPage?: boolean
  /**
   * Callback to be called when the page changes due to scrolling
   * typically used to update the url searchParams
   */
  onScrollPageChange?: (page: number) => void
}

export function usePaginatedScroll<TData extends PaginatedData>({
  apiRef,
  data,
  fetchNextPage,
  fetchPreviousPage,
  hasNextPage,
  hasPreviousPage,
  isFetchingNextPage,
  isFetchingPreviousPage,
  onScrollPageChange,
  currentPage,
  isEnabled,
}: UsePaginatedScrollOptions<TData>) {
  const hasFetchedPreviousPageIfNeeded = useRef(false)

  const initialScrollId = useRef<number | string | undefined>(undefined)

  /**
   * fetch the previous page if the initial page is not the first page
   */
  useEffect(() => {
    if (
      isEnabled &&
      !hasFetchedPreviousPageIfNeeded.current &&
      (data?.pages.length ?? 0) >= 1 // some data has been fetched
    ) {
      // grab the id to scroll to the initial page after this page is loaded
      const idToScrollTo = data?.pages[0]?.items[0]?.id

      if (currentPage && currentPage > 1) {
        void fetchPreviousPage({ pageParam: currentPage - 1 }).then(() => {
          initialScrollId.current = idToScrollTo
          hasFetchedPreviousPageIfNeeded.current = true
        })
      } else {
        hasFetchedPreviousPageIfNeeded.current = true
      }
    }
  }, [
    currentPage,
    data?.pages,
    data?.pages.length,
    fetchPreviousPage,
    isEnabled,
  ])

  const firstRowIdOfPageAfterPreviousPage = useRef<number | string | undefined>(
    undefined
  )

  /**
   * - scroll to the initial page after the page before it is loaded
   * - scroll to the page you were on when scrolling up to load more pages,
   *   aka the opposite of scrolling down to load more pages
   */
  useEffect(() => {
    const handleVirtualScollSizeChange: GridEventListener<
      "virtualScrollerContentSizeChange"
    > = () => {
      // scroll to the initial page after the page before it is loaded
      if (data?.pages.length === 2 && initialScrollId.current) {
        const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef)
        const rowIndex = allRows.findIndex(
          (row) => row.id === initialScrollId.current
        )

        if (rowIndex > 0) {
          apiRef.current.scrollToIndexes({ rowIndex })
          initialScrollId.current = undefined
        }
      }

      // if the user scrolls up to load more pages, scroll to the page they were on
      if (
        firstRowIdOfPageAfterPreviousPage.current &&
        data?.pages[0].items[0].id &&
        data.pages[0].items[0].id !== firstRowIdOfPageAfterPreviousPage.current
      ) {
        const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef)
        const rowIndex = allRows.findIndex(
          (row) => row.id === firstRowIdOfPageAfterPreviousPage.current
        )

        if (rowIndex > 0) {
          apiRef.current.scrollToIndexes({ rowIndex })
          firstRowIdOfPageAfterPreviousPage.current = undefined
        }
      }
    }

    return apiRef.current.subscribeEvent(
      "virtualScrollerContentSizeChange",
      handleVirtualScollSizeChange
    )
  }, [data?.pages, data?.pages.length, apiRef])

  /**
   * fetch the next page when the user scrolls to the end of the current page
   */
  useEffect(() => {
    const handleRowScrollEnd: GridEventListener<"rowsScrollEnd"> = () => {
      if (hasNextPage) {
        void fetchNextPage()
      }
    }

    return apiRef.current.subscribeEvent("rowsScrollEnd", handleRowScrollEnd)
  }, [fetchNextPage, apiRef, hasNextPage])

  const prevGridScrollTop = useRef(0)

  /**
   * detects when the user scrolls up to the top of the grid and fetches the previous page if needed
   * also keeps track of what page the user is on as they scroll
   */
  useEffect(() => {
    const handleScrollPositionChange: GridEventListener<
      "scrollPositionChange"
    > = (params) => {
      if (params.top === prevGridScrollTop.current) {
        return
      }

      if (
        !isFetchingPreviousPage &&
        !isFetchingNextPage &&
        hasFetchedPreviousPageIfNeeded.current && // if true the previous page has been fetched if needed
        !initialScrollId.current
      ) {
        const lastRowId = apiRef.current.getRowIdFromRowIndex(
          params.renderContext?.lastRowIndex ?? 0
        )
        for (const page of data?.pages ?? []) {
          for (const item of page.items) {
            if (item.id === lastRowId) {
              if (page.page !== (currentPage ?? 1)) {
                if (onScrollPageChange) {
                  onScrollPageChange(page.page)
                }
              }
              break
            }
          }
        }
      }

      const isScrollingUp = params.top < prevGridScrollTop.current

      if (
        data?.pages &&
        isScrollingUp &&
        params.top < 80 &&
        hasPreviousPage &&
        !isFetchingPreviousPage &&
        !isFetchingNextPage &&
        hasFetchedPreviousPageIfNeeded.current && // if true the previous page has been fetched if needed
        !initialScrollId.current &&
        !firstRowIdOfPageAfterPreviousPage.current
      ) {
        // store the id of the first row of the page that is currently
        // the first page, to scroll to it after the previous page is loaded
        firstRowIdOfPageAfterPreviousPage.current = data.pages[0].items[0].id
        void fetchPreviousPage()
      }

      prevGridScrollTop.current = params.top
    }

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent(
      "scrollPositionChange",
      handleScrollPositionChange
    )
  }, [
    data,
    fetchPreviousPage,
    apiRef,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    onScrollPageChange,
    currentPage,
  ])
}

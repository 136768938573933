import React from "react"
import type { FC } from "react"

import { NzDataGrid } from "@/components/nzDataGrid"
import { Page } from "@/components/page"
import { PageCard } from "@/components/page-card"
import { useOrganizationContext } from "@/contexts"
import {
  DEFAULT_PAGE_SIZE,
  usePageScrollChange,
  usePaginatedScroll,
  useSaveGridModel,
} from "@/nzds/data-grid"
import type { GraphitiRecord, PaginatedData } from "@/nzds/data-grid"
import { useGetPlans } from "@/services"
import { useSearchParamsPage } from "@/utils/url"

import { Box } from "@mui/material"
import { useGridApiRef } from "@mui/x-data-grid-pro"

import { useSitePlans } from "../../services/use-site-plans"
import type {
  SitePlanGridRecord,
  SitePlanRecord,
} from "../../services/use-site-plans"
import { useComputedSiteRows } from "../utils/use-computed-site-rows"
import { useSitesWithPlans } from "../utils/use-sites-with-plans"
import {
  defaultColumnVisibilityModel,
  defaultPinnedColumns,
  defaultSortModel,
  useDecarbonizeGridColumns,
} from "./utils/use-decarbonize-grid-columns"

export const SitesDecarbonizeRoute: FC = () => {
  const gridApiRef = useGridApiRef()
  const { organization } = useOrganizationContext()

  const { page } = useSearchParamsPage()

  const { recommendationsPlansData, recommendationsPlansIsLoading } =
    useGetPlans()

  const sitesWithPlans = useSitesWithPlans(recommendationsPlansData)

  const defaultColumns = useDecarbonizeGridColumns()

  const {
    gridModel: { columnVisibilityModel, columns, pinnedColumns, sortModel },
    handleColumnOrderChange,
    handleColumnVisibilityModelChange,
    handlePinnedColumnsChange,
    handleSortModelChange,
  } = useSaveGridModel<SitePlanGridRecord>({
    gridName: "sitesDecarbonize",
    gridModel: {
      columns: defaultColumns,
      columnVisibilityModel: defaultColumnVisibilityModel,
      pinnedColumns: defaultPinnedColumns,
      sortModel: defaultSortModel,
    },
  })

  const isDecarbonizeQueryEnabled =
    Boolean(organization?.id) &&
    Boolean(sortModel[0].field) &&
    Boolean(sortModel[0].sort)

  const {
    sitePlansData,
    sitePlansIsFetching,
    sitePlansFetchNextPage,
    sitePlansFetchPreviousPage,
    sitePlansHasNextPage,
    sitePlansHasPreviousPage,
    sitePlansIsFetchingNextPage,
    sitePlansIsFetchingPreviousPage,
  } = useSitePlans({
    parameters: {
      query: {
        "filter[organization_id]": organization?.id,
        "page[size]": DEFAULT_PAGE_SIZE,
        sort: `${sortModel[0].sort === "asc" ? "" : "-"}${sortModel[0].field}`,
        "stats[page]": "count",
      },
    },
    initialPage: page,
    queryOptions: {
      enabled: isDecarbonizeQueryEnabled,
    },
  })

  const rows = useComputedSiteRows<SitePlanRecord, SitePlanGridRecord>({
    data: sitePlansData,
    sitesWithPlans,
  })

  const handleScrollPageChange = usePageScrollChange()

  usePaginatedScroll<PaginatedData<GraphitiRecord<SitePlanRecord>>>({
    isEnabled: isDecarbonizeQueryEnabled,
    apiRef: gridApiRef,
    data: sitePlansData,
    fetchNextPage: sitePlansFetchNextPage,
    fetchPreviousPage: sitePlansFetchPreviousPage,
    hasNextPage: sitePlansHasNextPage,
    hasPreviousPage: sitePlansHasPreviousPage,
    isFetchingNextPage: sitePlansIsFetchingNextPage,
    isFetchingPreviousPage: sitePlansIsFetchingPreviousPage,
    onScrollPageChange: handleScrollPageChange,
    currentPage: page,
  })

  return (
    <Page fullHeight>
      <PageCard
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            bottom: 0,
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <NzDataGrid
            apiRef={gridApiRef}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            disableColumnFilter
            isLoading={recommendationsPlansIsLoading || sitePlansIsFetching}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onSortModelChange={handleSortModelChange}
            pinnedColumns={pinnedColumns}
            rows={rows}
            sortingMode="server"
            sortModel={sortModel}
          />
        </Box>
      </PageCard>
    </Page>
  )
}

import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { useOrganizationContext } from "@/contexts"
import { FeatureFlags, useFeature } from "@/services/feature"
import { buildSearchParamsString } from "@/utils"
import { useLocalStorage } from "@uidotdev/usehooks"

import type {
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"

import { useGridColumnVisibilityModel } from "./use-grid-column-visibility-model/use-grid-column-visibility-model"
import { useGridColumns } from "./use-grid-columns/use-grid-columns"
import { useGridPinnedColumns } from "./use-grid-pinned-columns/use-grid-pinned-columns"
import { useGridSortModel } from "./use-grid-sort-model/use-grid-sort-model"
import type {
  GridModel,
  UseSaveGridModelOptions,
  UseSaveGridModelValue,
} from "./use-save-grid-model.types"

const baseStorageKey = `${process.env.REACT_APP_ENV}gridModel`

const orderColumns = <RowT extends GridValidRowModel>(
  columns: GridColDef<RowT>[] | undefined,
  pinnedColumns: GridPinnedColumns | undefined
): GridColDef<RowT>[] => {
  const pinnedColumnFields: string[] = [
    ...(pinnedColumns?.left ?? []),
    ...(pinnedColumns?.right ?? []),
  ]

  const unpinnedColumnFields: string[] =
    columns
      ?.filter(
        (col) =>
          !pinnedColumnFields.find((pinnedField) => pinnedField === col.field)
      )
      .map((col) => col.field) ?? []

  const orderedColumnFields: string[] = [
    ...(pinnedColumns?.left ?? []),
    ...unpinnedColumnFields,
    ...(pinnedColumns?.right ?? []),
  ]

  const orderedColumns = orderedColumnFields
    .map((field) => columns?.find((col) => col.field === field))
    .filter((col) => col !== undefined)

  return orderedColumns
}

export const useSaveGridModel = <RowT extends GridValidRowModel>({
  gridName,
  gridModel,
}: UseSaveGridModelOptions<RowT>): UseSaveGridModelValue<RowT> => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  // Order default columns taking into account pinned columns
  const orderedDefaultColumns: GridColDef<RowT>[] = useMemo(() => {
    return orderColumns(gridModel.columns, gridModel.pinnedColumns)
  }, [gridModel])

  const defaultGridModel: GridModel<RowT> = {
    ...gridModel,
    columns: orderedDefaultColumns,
  }

  const [savedGridModel, saveGridModel] = useLocalStorage<GridModel<RowT>>(
    `${baseStorageKey}:${gridName}`,
    defaultGridModel
  )

  // Provides future compatibility for the addition of properties
  // The base grid model fills in any new properties that are not saved (e.g. pinnedColumns)
  const mergedGridModel: GridModel<RowT> = {
    ...defaultGridModel,
    ...savedGridModel,
    // Ensure saved columns are properly ordered
    // Otherwise reordering via the UI will not work
    // Necessary for self healing when:
    // 1) Columns in local storage are in an invalid order after a column is added
    columns: orderColumns(savedGridModel.columns, savedGridModel.pinnedColumns),
  }

  const columns = useGridColumns({
    savedColumns: mergedGridModel.columns,
    validColumns: defaultGridModel.columns,
  })

  const columnVisibilityModel = useGridColumnVisibilityModel({
    savedColumnVisibilityModel: mergedGridModel.columnVisibilityModel,
    validColumns: defaultGridModel.columns,
  })

  const pinnedColumns = useGridPinnedColumns({
    savedPinnedColumns: mergedGridModel.pinnedColumns,
    validColumns: defaultGridModel.columns,
  })

  const sortModel = useGridSortModel({
    defaultSortModel: defaultGridModel.sortModel,
    savedSortModel: mergedGridModel.sortModel,
    validColumns: defaultGridModel.columns,
  })

  const finalGridModel: GridModel<RowT> = useMemo(
    () => ({
      columns,
      columnVisibilityModel,
      pinnedColumns,
      sortModel,
    }),
    [columns, columnVisibilityModel, pinnedColumns, sortModel]
  )

  const handleColumnOrderChange = useCallback(
    ({ oldIndex, targetIndex }: GridColumnOrderChangeParams): void => {
      const reorderedColumns = [...finalGridModel.columns]
      const columnToMove = reorderedColumns[oldIndex]

      reorderedColumns.splice(oldIndex, 1)
      reorderedColumns.splice(targetIndex, 0, columnToMove)

      saveGridModel({
        ...finalGridModel,
        columns: reorderedColumns,
      })
    },
    [finalGridModel, saveGridModel]
  )

  const handleColumnVisibilityModelChange = useCallback(
    (newModel: GridColumnVisibilityModel): void => {
      saveGridModel({
        ...finalGridModel,
        columnVisibilityModel: newModel,
      })
    },
    [finalGridModel, saveGridModel]
  )

  const handlePinnedColumnsChange = useCallback(
    (newPinnedColumns: GridPinnedColumns): void => {
      // Must order columns based on new pinned columns
      // because MUI DataGrid considers pinned columns in GridColumnOrderChangeParams
      const orderedColumns = orderColumns(
        finalGridModel.columns,
        newPinnedColumns
      )

      saveGridModel({
        ...finalGridModel,
        columns: orderedColumns,
        pinnedColumns: newPinnedColumns,
      })
    },
    [finalGridModel, saveGridModel]
  )

  // For server-side sorting only
  const handleSortModelChange = useCallback(
    (newSortModel: GridSortModel): void => {
      if (newSortModel.length) {
        const { field, sort } = newSortModel[0]
        let orderBy: string = field

        // This logic does not belong here, but it is a temporary solution
        // while flexible hierarchy is being implemented
        if (field === "groups") {
          orderBy = isUseFlexibleHierarchyEnabled
            ? "organizational_unit_name"
            : "department_name"
        }

        const params = buildSearchParamsString(
          {
            order: sort,
            orderBy,
            page: 1, // reset the page when sorting happens
          },
          searchParams
        )

        setSearchParams(params)
      }

      saveGridModel({
        ...finalGridModel,
        sortModel: newSortModel,
      })
    },
    [
      finalGridModel,
      isUseFlexibleHierarchyEnabled,
      saveGridModel,
      searchParams,
      setSearchParams,
    ]
  )

  return {
    gridModel: finalGridModel,
    handleColumnOrderChange,
    handleColumnVisibilityModelChange,
    handlePinnedColumnsChange,
    handleSortModelChange,
  }
}

import React, { useEffect, useMemo, useState } from "react"

import type { Moment } from "moment"
import moment from "moment"

import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"
import Select from "@mui/material/Select"
import type { SelectChangeEvent } from "@mui/material/Select"

import type { IRange } from "../../../models/range"
import { MONTHS } from "../../../utils/constants"
import { pxToRem } from "../../../utils/unit"

const styles = {
  monthSelectContainer: {
    width: pxToRem(128),
  },
  yearSelectContainer: {
    marginRight: 2,
    width: pxToRem(80),
  },
}

const YEAR_DEFAULT_TEXT = "Year"
const FIRST_MONTH_DEFAULT_TEXT = "Start Month"
const SECOND_MONTH_DEFAULT_TEXT = "End Month"
const placeholderValue = -1

const hasMonths = (maxMinMonths: IRange<Moment> | null): boolean =>
  maxMinMonths &&
  moment.isMoment(maxMinMonths.start) &&
  moment.isMoment(maxMinMonths.end)

const getYearOptions = (
  availableMaxMinMonths: IRange<Moment> | null
): string[] => {
  if (
    hasMonths(availableMaxMinMonths) &&
    availableMaxMinMonths.start?.isValid() &&
    availableMaxMinMonths.end?.isValid()
  ) {
    const yearsDiff =
      Number(availableMaxMinMonths.end.format("YYYY")) -
      Number(availableMaxMinMonths.start.format("YYYY"))
    return Array.from(Array(yearsDiff + 1), (notUsed: null, x: number) =>
      availableMaxMinMonths.start.clone().add(x, "years").format("YYYY")
    )
  }
  return []
}

const monthIsBeforeAvailableMonth = (
  availableMaxMinMonths: IRange<Moment>,
  newSelectedYear: number,
  newMonth: number
): boolean =>
  newSelectedYear === availableMaxMinMonths.start.get("year") &&
  newMonth < availableMaxMinMonths.start.get("month")

const monthIsAfterAvailableMonth = (
  availableMaxMinMonths: IRange<Moment>,
  newSelectedYear: number,
  newMonth: number
): boolean =>
  newSelectedYear === availableMaxMinMonths.end.get("year") &&
  availableMaxMinMonths.end.get("month") < newMonth

interface MonthRangeSelectorProps {
  availableMaxMinMonths?: IRange<Moment> | null
  disabled?: boolean
  onChange: (value: IRange<Moment>, context: undefined) => void
  value: IRange<Moment>
}

/**
 * @deprecated Use MonthRangePicker component from nzds
 */
export const MonthRangeSelector = ({
  availableMaxMinMonths,
  value,
  onChange,
  disabled,
}: MonthRangeSelectorProps) => {
  const [selectedYear, setSelectedYear] = useState<number>(
    value.start?.isValid() ? value.start.get("year") : placeholderValue
  )
  const [selectedStartMonth, setSelectedStartMonth] = useState<number>(
    value.start?.isValid() ? value.start.get("month") : placeholderValue
  )
  const [selectedEndMonth, setSelectedEndMonth] = useState<number>(
    value.end?.isValid() ? value.end.get("month") : placeholderValue
  )

  const yearOptions = useMemo(
    () => getYearOptions(availableMaxMinMonths),
    [availableMaxMinMonths]
  )

  const isDisabled = useMemo(
    () =>
      disabled ||
      !availableMaxMinMonths.start?.isValid() ||
      !availableMaxMinMonths.end?.isValid(),
    [availableMaxMinMonths, disabled]
  )

  useEffect(() => {
    if (value.start?.isValid()) {
      setSelectedYear(moment(value.start).get("year"))
      setSelectedStartMonth(moment(value.start).get("month"))
    }
    if (value.end?.isValid()) {
      setSelectedEndMonth(moment(value.end).get("month"))
    }
  }, [value])

  const trySetStartEndMonth = (newSelectedYear: number): void => {
    let newStartMonth = selectedStartMonth

    if (
      monthIsBeforeAvailableMonth(
        availableMaxMinMonths,
        newSelectedYear,
        newStartMonth
      ) ||
      monthIsAfterAvailableMonth(
        availableMaxMinMonths,
        newSelectedYear,
        newStartMonth
      )
    ) {
      newStartMonth = placeholderValue
      setSelectedStartMonth(newStartMonth)
    }

    let newEndMonth = selectedEndMonth

    if (
      monthIsAfterAvailableMonth(
        availableMaxMinMonths,
        newSelectedYear,
        newEndMonth
      ) ||
      monthIsBeforeAvailableMonth(
        availableMaxMinMonths,
        newSelectedYear,
        newEndMonth
      )
    ) {
      newEndMonth = placeholderValue
      setSelectedEndMonth(newEndMonth)
    }

    const startDate = moment({
      year: newSelectedYear,
      month: newStartMonth,
      day: 1,
    })
    const endDate = moment({
      year: newSelectedYear,
      month: newEndMonth,
      day: 1,
    }).endOf("month")

    if (startDate.isValid() && endDate.isValid()) {
      onChange({ start: startDate, end: endDate }, undefined)
    }
  }

  const onYearChange = (e: SelectChangeEvent<number>): void => {
    const newSelectedYear = Number(e.target.value)
    setSelectedYear(newSelectedYear)
    trySetStartEndMonth(newSelectedYear)
  }

  const onStartMonthChange = (e: SelectChangeEvent<number>): void => {
    const startDate = moment({
      year: selectedYear,
      month: Number(e.target.value),
      day: 1,
    })
    const endDate = moment({
      year: selectedYear,
      month: selectedEndMonth,
      day: 1,
    }).endOf("month")
    setSelectedStartMonth(Number(e.target.value))

    if (startDate.isValid() && endDate.isValid()) {
      onChange({ start: startDate, end: endDate }, undefined)
    }
  }

  const onEndMonthChange = (e: SelectChangeEvent<number>): void => {
    const startDate = moment({
      year: selectedYear,
      month: selectedStartMonth,
      day: 1,
    })
    const endDate = moment({
      year: selectedYear,
      month: Number(e.target.value),
      day: 1,
    }).endOf("month")
    setSelectedEndMonth(Number(e.target.value))

    if (startDate.isValid() && endDate.isValid()) {
      onChange({ start: startDate, end: endDate }, undefined)
    }
  }

  const isStartMonthUnavailable: boolean =
    selectedYear !== placeholderValue && selectedStartMonth === placeholderValue

  const isEndMonthUnavailable: boolean =
    selectedYear !== placeholderValue && selectedEndMonth === placeholderValue

  return (
    <FormGroup row sx={{ alignItems: "baseline" }}>
      <FormControl disabled={isDisabled} size="small">
        <Select
          inputProps={{
            "data-testid": "year-select",
            "data-e2e": "year-select",
          }}
          native
          onChange={onYearChange}
          sx={styles.yearSelectContainer}
          value={selectedYear}
        >
          <option key={YEAR_DEFAULT_TEXT} disabled value={placeholderValue}>
            {YEAR_DEFAULT_TEXT}
          </option>
          {yearOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormGroup sx={{ ...styles.monthSelectContainer, marginRight: 2 }}>
        <FormControl disabled={isDisabled} size="small">
          <Select
            inputProps={{
              "data-testid": "first-month-select",
              "data-e2e": "first-month-select",
            }}
            native
            onChange={onStartMonthChange}
            value={selectedStartMonth}
          >
            <option
              key={FIRST_MONTH_DEFAULT_TEXT}
              disabled
              value={placeholderValue}
            >
              {FIRST_MONTH_DEFAULT_TEXT}
            </option>
            {[...Array(12).keys()].map((option) => (
              <option
                key={option}
                disabled={
                  !hasMonths(availableMaxMinMonths) ||
                  (Number(selectedYear) ===
                    availableMaxMinMonths.start.get("year") &&
                    option < availableMaxMinMonths.start.get("month")) ||
                  (selectedEndMonth !== placeholderValue &&
                    Number(selectedEndMonth) < option) ||
                  yearOptions?.length === 0
                }
                value={option}
              >
                {MONTHS[option]}
              </option>
            ))}
          </Select>
          {isStartMonthUnavailable && (
            <FormHelperText data-testid="first-month-error-text">
              Previous selection unavailable
            </FormHelperText>
          )}
        </FormControl>
      </FormGroup>
      <Box component="span" mr={2}>
        —
      </Box>
      <FormGroup sx={styles.monthSelectContainer}>
        <FormControl disabled={isDisabled} size="small">
          <Select
            inputProps={{
              "data-testid": "second-month-select",
              "data-e2e": "second-month-select",
            }}
            native
            onChange={onEndMonthChange}
            value={selectedEndMonth}
          >
            <option
              key={SECOND_MONTH_DEFAULT_TEXT}
              disabled
              value={placeholderValue}
            >
              {SECOND_MONTH_DEFAULT_TEXT}
            </option>
            {[...Array(12).keys()].map((option) => (
              <option
                key={option}
                disabled={
                  !hasMonths(availableMaxMinMonths) ||
                  (Number(selectedYear) ===
                    availableMaxMinMonths.end.get("year") &&
                    availableMaxMinMonths.end.get("month") < option) ||
                  selectedStartMonth === placeholderValue ||
                  Number(selectedStartMonth) > option ||
                  yearOptions?.length === 0
                }
                value={option}
              >
                {MONTHS[option]}
              </option>
            ))}
          </Select>
          {isEndMonthUnavailable && (
            <FormHelperText data-testid="second-month-error-text">
              Previous selection unavailable
            </FormHelperText>
          )}
        </FormControl>
      </FormGroup>
    </FormGroup>
  )
}

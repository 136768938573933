import { THERMS_PER_MMBTU } from "./constants"

/**
 * Converts a mmbtu number to therms
 *
 * @param mmbtu A number in mmbtu
 * @returns A number in therms
 */
export const convertMmbtuToTherms = (mmbtu: number): number =>
  mmbtu * THERMS_PER_MMBTU

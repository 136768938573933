import type { MutableRefObject } from "react"
import { useEffect, useState } from "react"

export const useResizeObserver = (
  ref: MutableRefObject<undefined>
): Record<string, DOMRectReadOnly> | null => {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null)

  useEffect(() => {
    const target = ref?.current
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect)
      })
    })

    if (target) {
      resizeObserver.observe(target)
    }

    return () => {
      if (target) {
        resizeObserver.unobserve(target)
      }
    }
  }, [ref])

  if (!ref) {
    return null
  }

  return { dimensions }
}

import moment from "moment"

import type Product from "../components/graph/product"
import { ElectricityType } from "../models/energyAllocation"
import type Price from "../models/price"
import type { SitePremiseFinancialSummary } from "../models/sitePremiseFinancialSummary"
import {
  BTU_PER_GJ,
  CUBIC_METERS_PER_US_LIQUID_GALLON,
  KILO,
  KWH_PER_MWH,
  LBS_PER_KG,
  LBS_PER_METRIC_TON,
  SQUARE_FOOT_PER_SQUARE_METER,
} from "./constants"

/**
 * @param oldValue
 * @param newValue
 */
export const percentageChange = (oldValue: number, newValue: number) => {
  if (oldValue === 0) {
    return undefined
  }
  const changeAmount = newValue - oldValue
  return (changeAmount / oldValue) * 100
}

export const dayAheadPrice = (prices: Price[]): Price | null => {
  const currentHour = moment().startOf("hour")

  const priceForCurrentHour = prices.filter(({ hour }) =>
    hour.isSame(currentHour)
  )[0]

  return priceForCurrentHour || prices[prices.length - 1] || null
}

export const kwhToMwh = (kwh: number) => kwh / KWH_PER_MWH

export const firstStrLowerCaseContainsSecondStrLowerCase = (
  str1: string | null | undefined,
  str2: string | null | undefined
): boolean => {
  if (!str1 || !str2) {
    return false
  }
  return str1.toLowerCase().includes(str2.toLowerCase())
}

export const calculateTotalCost = (
  premiseFinancialSummary: SitePremiseFinancialSummary | undefined
): number | undefined =>
  premiseFinancialSummary?.energyAllocatorList?.reduce(
    (sum, energyAllocator) => sum + Number.parseFloat(energyAllocator.total),
    0
  ) ?? 0

export const calculateTotalVolume = (
  premiseFinancialSummary: SitePremiseFinancialSummary | undefined
): number | undefined =>
  premiseFinancialSummary?.energyAllocatorList?.reduce(
    (sum, energyAllocator) =>
      sum + Number.parseFloat(energyAllocator.meteredKwh),
    0
  ) ?? 0

export const calculateMeteredKwhByProduct = (product: Product): number => {
  // If direct access, don't include "Long Imbalance" (negative value)
  // That energy wasn't consumed, but delivered back to the grid
  // Consumed = Market + ShortImbalance
  if (product.electricityType === ElectricityType.DirectAccess) {
    return Math.max(0, product.kwh)
  }
  // If bundled, include "Received" value (negative value)
  // which will subtract generated energy going back to the grid
  // Consumed = Delivered - Received

  return product.kwh
}

// The metered kwh is the sum of all energy consumed (not including energy delivered back to the grid)
export const calculateMeteredKwhByProducts = (products: Product[]): number =>
  products.reduce(
    (meteredKwh: number, currentProduct: Product) =>
      meteredKwh + calculateMeteredKwhByProduct(currentProduct),
    0
  )

export const unitsPerSquareFootToUnitsPerSquareMeter = (
  originalValue: number | string
): number => {
  let numericValue = originalValue
  if (typeof numericValue === "string") {
    numericValue = parseFloat(numericValue)
  }
  return numericValue * SQUARE_FOOT_PER_SQUARE_METER
}

export const squareFootToSquareMeter = (squareFoot: number): number =>
  squareFoot / SQUARE_FOOT_PER_SQUARE_METER

export const poundsToMetricTon = (value: number): number =>
  value / LBS_PER_METRIC_TON

export const poundsToKilogram = (value: number): number => value / LBS_PER_KG

export const gallonsToCubicMeter = (value: number): number =>
  value / CUBIC_METERS_PER_US_LIQUID_GALLON

export const isStringValueANumber = (value: string) =>
  typeof Number(value) === "number" && Number.isFinite(Number(value))

/**
 * Converts energy in gigajoules (GJ) to British Thermal Units (BTU).
 *
 * @param value - The energy value in gigajoules (GJ).
 * @returns The equivalent energy in British Thermal Units (BTU).
 */
export const gigajoulesToBritishThermalUnit = (value: number): number =>
  value * BTU_PER_GJ

/**
 * Converts energy in gigajoules (GJ) to kilo British Thermal Units (kBtu).
 *
 * @param value - The energy value in gigajoules (GJ).
 * @returns The equivalent energy in kilo British Thermal Units (kBtu).
 */
export const gigajoulesToKiloBritishThermalUnit = (value: number): number =>
  gigajoulesToBritishThermalUnit(value) / KILO

import { useOrganizationContext } from "@/contexts"
import { ApiQueryName } from "@/models/api"
import {
  DEFAULT_PAGE_SIZE,
  graphitiCollectionResponseToPaginatedData,
} from "@/nzds/data-grid"
import type { GraphitiRecord, PageStats, PaginatedData } from "@/nzds/data-grid"
import type { Params } from "@/services/fetch-client/client"
import type { Operations, Schemas } from "@/services/types"
import { getPlans } from "@/services/useGetPlans/get-plans"
import { useQueryKey, useRenameQueryResult } from "@/services/utils"
import type { UseInfiniteQueryOptions } from "@tanstack/react-query"
import { useInfiniteQuery } from "@tanstack/react-query"

import type {
  BaseSiteGridRecord,
  BaseSiteRecord,
} from "../use-get-site-explorer/site-explorer.types"

export type SitePlanRecord = Schemas["PlanResponse"] & BaseSiteRecord

export type SitePlanGridRecord = SitePlanRecord & BaseSiteGridRecord

interface SitePlansResponse {
  data: GraphitiRecord<SitePlanRecord>[]
  meta: {
    stats: {
      page: {
        count: PageStats
      }
    }
  }
}

interface UseSitePlansOptions {
  initialPage?: number
  parameters: Required<Params>
  queryOptions?: Omit<
    UseInfiniteQueryOptions<PaginatedData<GraphitiRecord<SitePlanRecord>>>,
    "queryKey" | "queryFn"
  >
}

interface GetPlansPaginatedParams {
  query: Operations["get_bs_list_api_v1_plans_get"]["parameters"]["query"] & {
    page: { number: number }
  }
}

const getPlansPaginated = async (
  params: GetPlansPaginatedParams
): Promise<PaginatedData<GraphitiRecord<SitePlanRecord>>> => {
  return getPlans(params).then((response) => {
    // Convert insights-api response to mock graphiti response
    const responseWithMeta: SitePlansResponse = {
      data:
        response?.map((item) => {
          return {
            attributes: {
              ...item,
              page: 1,
              pageIndex: 1,
              pageSize: 50,
            },
            id: item.id.toString(),
            type: "site_plans",
          }
        }) ?? [],
      meta: {
        stats: {
          page: {
            count: {
              number: 1,
              pages: 1,
              size: DEFAULT_PAGE_SIZE,
              total: 1,
            },
          },
        },
      },
    }

    const paginatedData =
      graphitiCollectionResponseToPaginatedData<SitePlanRecord>(
        responseWithMeta
      )

    return paginatedData
  })
}

/**
 * Returns a list of site plans
 *
 * @example
 * const {
 *   sitePlansData,
 *   sitePlansError,
 *   sitePlansIsError
 *   sitePlansIsLoading,
 * } = useGetSitePlans({
 *   parameters: {
 *     query: {
 *       org_id: 123
 *     }
 *   },
 *   queryOptions // react query options
 * })
 */
export const useSitePlans = ({
  initialPage = 1,
  parameters,
  queryOptions = {},
}: UseSitePlansOptions) => {
  const { organization } = useOrganizationContext()

  const queryFn = async ({ pageParam = initialPage }) => {
    const newParameters: GetPlansPaginatedParams = {
      query: {
        ...parameters.query,
        organization_id: Number(organization?.id),
        page: {
          number: pageParam,
        },
      },
    }

    return getPlansPaginated(newParameters)
  }

  const queryKey = useQueryKey(
    ApiQueryName.SitePlans,
    "getMany",
    null,
    parameters
  )

  const queryParams = {
    ...queryOptions,
    enabled: queryOptions.enabled && Boolean(organization?.id),
    queryFn,
    queryKey,
  }

  const queryResult = useInfiniteQuery<
    PaginatedData<GraphitiRecord<SitePlanRecord>>
  >({
    ...queryParams,
    getNextPageParam: (lastPage) => {
      if (lastPage.page === lastPage.pages) {
        return undefined
      }
      return lastPage.page + 1
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.page === 1) {
        return undefined
      }
      return firstPage.page - 1
    },
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SitePlans)
}

import { graphitiCollectionResponseToPaginatedData } from "@/nzds/data-grid"
import { fetchClient } from "@/services/client"
import type { Params } from "@/services/fetch-client/client"

import type {
  SiteExplorerPaginatedData,
  SiteExplorerRecord,
  SiteExplorerResourceResponse,
} from "./site-explorer.types"

export const getSiteExplorer = async (
  params: Params
): Promise<SiteExplorerPaginatedData> => {
  const { data, error } = await fetchClient.GET<SiteExplorerResourceResponse>(
    "/api/v1/site_explorer",
    {
      params,
    }
  )

  if (data && "data" in data) {
    const paginatedData =
      graphitiCollectionResponseToPaginatedData<SiteExplorerRecord>(
        data as SiteExplorerResourceResponse
      )

    const finalResponse: SiteExplorerPaginatedData = {
      ...paginatedData,
      average: data.meta.stats.average?.count ?? null,
      compliant: data.meta.stats.compliant?.count ?? null,
      efficient: data.meta.stats.efficient?.count ?? null,
      inefficient: data.meta.stats.inefficient?.count ?? null,
      non_compliant: data.meta.stats.non_compliant?.count ?? null,
      total_penalties: data.meta.stats.total_penalties?.sum ?? null,
      very_efficient: data.meta.stats.very_efficient?.count ?? null,
      very_inefficient: data.meta.stats.very_inefficient?.count ?? null,
    }

    return finalResponse
  }

  throw error as Error
}

import { useEffect } from "react"

import { useNotification } from "@/contexts"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import Address from "../../models/address"
import { ApiQueryName } from "../../models/api"
import Currency from "../../models/currency"
import Site from "../../models/site"
import type { QueryMutation } from "../types"
import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useUpdateSite = () => {
  const queryClient = useQueryClient()
  const mutationKey = useQueryKey(ApiQueryName.SiteUpdate, "updateOne")
  const { update } = useSpraypaintModel(Site)
  const { invalidateQueries } = useInvalidateQueries()
  const { setNotification } = useNotification()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      serviceEntityKey,
      newAttributes,
    }: Parameters<QueryMutation<"updateOne", ModelAttributes<Site>>>[0]) => {
      return await update(serviceEntityKey, newAttributes)
    },
    onSuccess: (_, { serviceEntityKey }) => {
      invalidateQueries([
        createQueryKey(ApiQueryName.Site, "getOne", serviceEntityKey),
        createQueryKey(ApiQueryName.Sites, "getMany"),
      ])

      queryClient
        .invalidateQueries([ApiQueryName.SiteExplorer])
        .catch((error: unknown) => {
          if (error instanceof Error) {
            console.error(error)
          }
        })
    },
  })

  // Show an error notification if update is unsuccessful
  useEffect(() => {
    if (mutationResult.data === false || mutationResult.isError) {
      setNotification({
        message: "Failed to update site. Please try again.",
        type: "error",
      })
    }
  }, [mutationResult.data, mutationResult.isError, setNotification])

  return useRenameQueryResult(mutationResult, ApiQueryName.SiteUpdate)
}

/**
 * temporarily used for updating site on the rec and planning screen for setting goals
 * and updating metadata about a site.
 *
 */
export const useUpdateSiteModel = () => {
  const mutationKey = useQueryKey(ApiQueryName.Site, "updateOne")
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({ site }: { site: Site }) => {
      await site.save({
        with: ["address", "physicalDetails"],
      })
    },
    onSuccess: (_, { site }) => {
      invalidateQueries([
        createQueryKey(ApiQueryName.Site, "getOne", site.id),
        createQueryKey(ApiQueryName.Sites, "getMany"),
      ])
    },
  })

  return mutationResult
}

/**
 * @deprecated Remove this hook once we're able to sidepost multiple resources with our
 * ActiveRecord wrapper's update() function.
 */
export const useUpdateSiteWithAddress = () => {
  const { invalidateQueries } = useInvalidateQueries()
  const mutationKey = useQueryKey(ApiQueryName.Site, "updateOne")

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      serviceEntityKey,
      newAttributes,
    }: Parameters<QueryMutation<"updateOne", ModelAttributes<Site>>>[0]) => {
      const { address, currency, ...rest } = newAttributes

      // Even though the site already exists, we have to fetch the instance with Spraypaint
      // or it will try creating the site instead of updating on save().
      const { data: site } = await Site.where({ id: serviceEntityKey })
        .includes(["address", "currency"])
        .first()

      if (site.address) {
        site.address.attributes = address
      } else {
        site.address = new Address(address)
      }

      if (site.currency) {
        site.currency.attributes = currency
      } else {
        site.currency = (
          await Currency.where({ id: currency?.id }).first()
        ).data
      }

      site.attributes = rest

      await site.save({
        with: ["address", "location.id", "sink.id", "currency.id", "nodes"],
      })
      return site.id
    },
    onSuccess: (_, { serviceEntityKey }) => {
      invalidateQueries([
        createQueryKey(ApiQueryName.Site, "getOne", serviceEntityKey),
        createQueryKey(ApiQueryName.Sites, "getMany"),
      ])
    },
  })
  return useMutationReturnHandle(mutationKey, mutationResult)
}

import type { ReactNode } from "react"
import React, { useMemo } from "react"

import { HStack } from "@/nzds/layout"

import InfoRoundedIcon from "@mui/icons-material/InfoRounded"
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded"
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded"
import { Box, Skeleton, Tooltip, Typography, useTheme } from "@mui/material"

import type { UnitName } from "../../../models/unit"
import { pxToRem } from "../../../utils/unit"
import { DecimalWithPlaceholder } from "../../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { If } from "../../if/if"
import { Unit } from "../../unit/unit"

interface IOrganizationDashboardMetricProps {
  dataE2e: string
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decimalComponent: any
  isDateRangeOverAYear: boolean
  isLoadingPreviousValue?: boolean
  isValidYoY: boolean
  previousValue: number | undefined
  title: ReactNode
  unit?: UnitName
  unitText?: string
  value: number | undefined
}

const styles = {
  bottomContent: {
    mr: pxToRem(4),
  },
  subBottomContent: {
    color: "inherit",
  },
  subBottomContentText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}

const getPercentDifference = (value: number, previousValue: number) =>
  ((value - previousValue) / Math.abs(previousValue)) * 100

const OrganizationDashboardMetric = ({
  title,
  value,
  unit,
  unitText,
  isValidYoY,
  previousValue,
  decimalComponent,
  isDateRangeOverAYear,
  isLoadingPreviousValue,
  dataE2e,
}: IOrganizationDashboardMetricProps): JSX.Element => {
  const percentDifference: number = useMemo(
    () =>
      isValidYoY && value && previousValue
        ? getPercentDifference(value, previousValue)
        : NaN,
    [isValidYoY, value, previousValue]
  )

  const isPositive: boolean = percentDifference && percentDifference < 0
  const isNegative: boolean = percentDifference && percentDifference > 0
  const isNeutral = !percentDifference
  const yearOverYearText: string = isPositive
    ? "Lower, Year-Over-Year"
    : "Higher, Year-Over-Year"
  const tooltipText: string = isDateRangeOverAYear
    ? "Select 12 months or less to view a Year-Over-Year comparison"
    : "Not enough available data for Year-Over-Year comparison"

  const theme = useTheme()

  return (
    <If condition={title}>
      <Typography
        component="span"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        variant="body2"
      >
        {title}
      </Typography>
      <Box>
        <Typography data-e2e={dataE2e} variant="hero">
          {/* Mass eslint disable */}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
          {decimalComponent({ value })}
        </Typography>
        {unit && (
          <Typography component="span" variant="body2">
            {" "}
            <Unit unit={unit} />
          </Typography>
        )}
        {/* TODO: Remove when the unit component supports this use case */}
        {unitText && (
          <Typography component="span" variant="body2">
            {" "}
            {unitText}
          </Typography>
        )}
      </Box>

      <If condition={isLoadingPreviousValue}>
        <Typography component="div" variant="body2">
          <Skeleton variant="rectangular" />
        </Typography>
      </If>

      <If condition={!isLoadingPreviousValue}>
        <HStack
          alignItems="center"
          sx={{
            ...(isPositive ? { color: theme.palette.spectrum.green[400] } : {}),
            ...(isNegative ? { color: theme.palette.spectrum.red[400] } : {}),
            ...(isNeutral ? { color: theme.palette.grey[300] } : {}),
          }}
        >
          <If condition={isPositive && isValidYoY}>
            <ThumbUpRoundedIcon
              data-e2e="dashboard-metric-yoy-direction"
              fontSize="small"
              sx={styles.bottomContent}
            />
          </If>
          <If condition={isNegative && isValidYoY}>
            <ThumbDownRoundedIcon
              data-e2e="dashboard-metric-yoy-direction"
              fontSize="small"
              sx={styles.bottomContent}
            />
          </If>
          <If condition={!isValidYoY || !Number.isFinite(percentDifference)}>
            <Tooltip
              arrow
              data-e2e="dashboard-metric-yoy-direction"
              data-testid="tooltip"
              placement="bottom-start"
              title={tooltipText}
            >
              <InfoRoundedIcon fontSize="small" sx={styles.bottomContent} />
            </Tooltip>
          </If>
          <Typography
            component="span"
            sx={{ ...styles.bottomContent, ...styles.subBottomContent }}
            variant="body2"
          >
            <DecimalWithPlaceholder
              format={{ precision: 2 }}
              replaceZeroWithPlaceholder
              value={Math.abs(percentDifference)}
            />
            %
          </Typography>
          <If condition={!isNeutral}>
            <Typography
              component="span"
              sx={{
                ...styles.bottomContent,
                ...styles.subBottomContent,
                ...styles.subBottomContentText,
              }}
              variant="body2"
            >
              {yearOverYearText}
            </Typography>
          </If>
        </HStack>
      </If>
    </If>
  )
}

export default OrganizationDashboardMetric

import React from "react"
import type { FC } from "react"

import reportComplete from "../../assets/images/report-complete.svg"
import { DataAlertCompleteText } from "../../models/report"
import DataAlertBase from "../dataAlertBase/dataAlertBase"

const DataAlertComplete: FC = () => (
  <DataAlertBase
    altText={DataAlertCompleteText.AltText}
    body={DataAlertCompleteText.Body}
    img={reportComplete}
    title={DataAlertCompleteText.Title}
  />
)

export default DataAlertComplete

export enum RootPath {
  About = "/about",
  Admin = "/admin",
  Assistant = "assistant",
  Dashboard = "dashboard",
  Data = "data",
  // This route is used by rails
  DeveloperAdmin = "/developer_admin",
  Financial = "financial",
  ForgotPassword = "/forgot-password",
  NotFound = "/not-found",
  Organization = "organizations",
  Recommendations = "recommendations",
  Rep = "/rep",
  Report = "reporting",
  ResetPassword = "/reset-password",
  Root = "/",
  Settings = "/settings",
  SignUp = "/sign-up",
  Site = "sites",
  TamAdmin = "/tam-admin",
  ValidateAccount = "/validate-account",
}

export enum AdminPath {
  Rep = "rep",
}

export enum AssistantPath {
  Thread = ":threadId",
}

export enum DataPath {
  QA = "qa",
  Upload = "upload",
}

export enum DeveloperAdminPath {
  Bill = "/bills",
  BillingGroup = "/billing_groups",
  Department = "/departments",
  Meter = "/meters",
  MeterHour = "/meter_hours",
  Organization = "/organizations",
  OrganizationEmission = "/organization_emissions",
  Site = "/sites",
  SiteEmission = "/site_emissions",
  User = "/users",
}

export enum SitePath {
  ActivityEmissions = "activity-emissions",
  CarbonFootprint = "/carbon-footprint",
  Compliance = "compliance",
  DashboardRoot = "/",
  Decarbonize = "decarbonize",
  Electricity = "electricity",
  Emissions = "emissions",
  NaturalGas = "natural-gas",
  Overview = "overview",
  Performance = "performance",
  Site = ":siteId",
  SiteDetails = "details",
}

export enum TamAdminPath {
  BillingGroupDetail = "/onboarding/:orgId/sites/:siteId/billing-group/:billingGroupId",
  MeterDetail = "/onboarding/:orgId/sites/:siteId/billing-group/:billingGroupId/meter/:meterId",
  Onboarding = "/onboarding",
  OrgView = "/onboarding/:orgId",
  Organize = "/onboarding/:orgId/organize",
  SiteDetail = "/onboarding/:orgId/sites/:siteId",
  SiteList = "/onboarding/:orgId/sites",
}

export enum OrgViewPath {
  BillingGroup = "/billing-group",
  Department = "/department",
  Meter = "/meter",
  Site = "/site",
}

export enum RecommendationsPath {
  JumpStart = "site-explorer",
  JumpStartResult = "site-explorer/result",
  Plan = "plan",
  Site = "sites/:siteId",
  SiteGoals = "sites/:siteId/goals",
  SitePlan = "sites/:siteId/plans/:planId",
  SitePlanEdit = "sites/:siteId/plans/:planId/edit",
}

export enum ReportPath {
  CustomReports = "custom-reports",
  EmissionsSources = "emissions-sources",
  Gresb = "gresb",
  Gri = "gri",
  MonthlySiteData = "monthly-site",
  ResourceSummary = "resource-summary",
  ScopeThree = "scope-three",
  Workbook = ":workbookId",
}

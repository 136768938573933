import type { RouteObject } from "react-router-dom"

import { AssistantPath, RootPath } from "@/models/route"

export const assistantRoutes: RouteObject[] = [
  {
    path: RootPath.Assistant,
    lazy: async () => {
      const { AssistantRoot } = await import("./pages")
      return { Component: AssistantRoot }
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { AssistantIndex } = await import("./pages")
          return { Component: AssistantIndex }
        },
      },
      {
        path: AssistantPath.Thread,
        lazy: async () => {
          const { AssistantIndex } = await import("./pages")
          return { Component: AssistantIndex }
        },
      },
    ],
  },
]

// @ts-strict-ignore
import type { FC } from "react"
import React, { useCallback } from "react"
import { useSearchParams } from "react-router-dom"

import { Page, PageCard, PageHeaderActionBar } from "@/components"
import { NzDataGrid } from "@/components/nzDataGrid"
import { PerformanceGridLegend } from "@/components/performance"
import { useOrganizationContext } from "@/contexts"
import {
  DEFAULT_PAGE_SIZE,
  DataGridToolbar,
  useEditRow,
  useGridFilterModelChange,
  usePageScrollChange,
  usePaginatedScroll,
  useSaveGridModel,
} from "@/nzds/data-grid"
import { YearPicker } from "@/nzds/inputs/year-picker"
import { HStack } from "@/nzds/layout"
import { useAvailableReportDates, useGetPlans } from "@/services"
import { useGetBuildingTypes, useUpdateSite } from "@/services/site"
import { buildSearchParamsString, formatToYYYYMMDD } from "@/utils"
import { useSearchParamsPage, useSearchParamsYear } from "@/utils/url"
import { useConfirm } from "material-ui-confirm"
import type { Moment } from "moment"

import { Box, MenuItem } from "@mui/material"
import { useGridApiRef } from "@mui/x-data-grid-pro"

import { EditSiteConfirmContent } from "../../components/edit-site-confirm-content/edit-site-confirm-content"
import type {
  SiteExplorerGridRecord,
  SiteExplorerPaginatedData,
  SiteExplorerRecord,
} from "../../services/use-get-site-explorer"
import { useGetSiteExplorer } from "../../services/use-get-site-explorer"
import { useComputedSiteRows, useSitesWithPlans } from "../utils"
import {
  defaultColumnVisibilityModel,
  defaultPinnedColumns,
  defaultSortModel,
  usePerformanceGridColumns,
} from "./utils/use-performance-grid-columns/use-performance-grid-columns"

export const SitesPerformanceRoute: FC = () => {
  const gridApiRef = useGridApiRef()
  const [searchParams, setSearchParams] = useSearchParams()
  const { organization } = useOrganizationContext()
  const { availableReportDatesData } = useAvailableReportDates(organization?.id)

  const { recommendationsPlansData, recommendationsPlansIsLoading } =
    useGetPlans()

  const sitesWithPlans = useSitesWithPlans(recommendationsPlansData)

  const handleOnFilterModelChange = useGridFilterModelChange()

  const { buildingTypesData, buildingTypesIsFetching } = useGetBuildingTypes()
  const {
    handleRowEditCancelClick,
    handleRowEditClick,
    handleRowEditSaveClick,
    handleRowEditStop,
    rowModesModel,
  } = useEditRow()
  const defaultColumns = usePerformanceGridColumns({
    buildingTypes: buildingTypesData,
    handleRowEditCancelClick,
    handleRowEditClick,
    handleRowEditSaveClick,
    rowModesModel,
  })

  const { page } = useSearchParamsPage()
  const { yearEnd, yearStart } = useSearchParamsYear({
    validRange: availableReportDatesData,
  })

  const {
    gridModel: { columnVisibilityModel, columns, pinnedColumns, sortModel },
    handleColumnOrderChange,
    handleColumnVisibilityModelChange,
    handlePinnedColumnsChange,
    handleSortModelChange,
  } = useSaveGridModel<SiteExplorerGridRecord>({
    gridName: "sitesPerformance",
    gridModel: {
      columns: defaultColumns,
      columnVisibilityModel: defaultColumnVisibilityModel,
      pinnedColumns: defaultPinnedColumns,
      sortModel: defaultSortModel,
    },
  })

  const { siteUpdateMutateAsync, siteUpdateIsLoading } = useUpdateSite()
  const confirm = useConfirm()

  const isSiteExplorerQueryEnabled =
    Boolean(organization?.id) &&
    Boolean(sortModel[0].field) &&
    Boolean(sortModel[0].sort) &&
    yearStart.isValid()

  const {
    siteExplorerData,
    siteExplorerIsFetching,
    siteExplorerFetchNextPage,
    siteExplorerFetchPreviousPage,
    siteExplorerHasNextPage,
    siteExplorerHasPreviousPage,
    siteExplorerIsFetchingNextPage,
    siteExplorerIsFetchingPreviousPage,
  } = useGetSiteExplorer({
    parameters: {
      query: {
        "filter[organization_id]": organization?.id,
        "filter[start_date][gte]": formatToYYYYMMDD(yearStart),
        "filter[end_date][lte]": formatToYYYYMMDD(yearEnd),
        include_benchmarks: true,
        "page[size]": DEFAULT_PAGE_SIZE,
        sort: `${sortModel[0].sort === "asc" ? "" : "-"}${sortModel[0].field}`,
        "stats[average]": "count",
        "stats[efficient]": "count",
        "stats[inefficient]": "count",
        "stats[page]": "count",
        "stats[very_efficient]": "count",
        "stats[very_inefficient]": "count",
      },
    },
    initialPage: page,
    queryOptions: {
      enabled: isSiteExplorerQueryEnabled,
    },
  })

  const rows = useComputedSiteRows<SiteExplorerRecord, SiteExplorerGridRecord>({
    data: siteExplorerData,
    sitesWithPlans,
  })

  const handleScrollPageChange = usePageScrollChange()

  usePaginatedScroll<SiteExplorerPaginatedData>({
    isEnabled: isSiteExplorerQueryEnabled,
    apiRef: gridApiRef,
    data: siteExplorerData,
    fetchNextPage: siteExplorerFetchNextPage,
    fetchPreviousPage: siteExplorerFetchPreviousPage,
    hasNextPage: siteExplorerHasNextPage,
    hasPreviousPage: siteExplorerHasPreviousPage,
    isFetchingNextPage: siteExplorerIsFetchingNextPage,
    isFetchingPreviousPage: siteExplorerIsFetchingPreviousPage,
    onScrollPageChange: handleScrollPageChange,
    currentPage: page,
  })

  const handleDateChange = useCallback(
    (value: Moment): void => {
      const newSearchParamsString = buildSearchParamsString(
        {
          year: value.format("YYYY"),
        },
        searchParams
      )
      setSearchParams(newSearchParamsString)
    },
    [searchParams, setSearchParams]
  )

  const [columnsButtonEl, setColumnsButtonEl] =
    React.useState<HTMLButtonElement | null>(null)

  const handleProcessRowUpdate = useCallback(
    async (
      newValue: SiteExplorerGridRecord,
      value: SiteExplorerGridRecord
    ): Promise<SiteExplorerGridRecord> => {
      if (
        newValue.building_type === value.building_type &&
        newValue.sq_ft === value.sq_ft
      ) {
        return value
      }

      try {
        await confirm({
          title: "Confirm Updates",
          content: <EditSiteConfirmContent siteName={newValue.site_name} />,
          confirmationButtonProps: {
            color: "primary",
            variant: "contained",
          },
          confirmationText: "Confirm",
          cancellationButtonProps: { color: "primary", variant: "text" },
          cancellationText: "Cancel",
          dialogActionsProps: {
            disableSpacing: true,
          },
          dialogProps: {
            maxWidth: "xs",
          },
        })

        await siteUpdateMutateAsync({
          newAttributes: {
            buildingType: newValue.building_type,
            sqFt: newValue.sq_ft,
          },
          serviceEntityKey: newValue.id,
        })

        return newValue
      } catch {
        return value
      }
    },
    [confirm, siteUpdateMutateAsync]
  )

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.error(error)
  }, [])

  return (
    <>
      <PageHeaderActionBar>
        <HStack alignItems="center" gap={2} id="datagrid-toolbar" />
      </PageHeaderActionBar>
      <Page fullHeight>
        <PageCard
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box mb={3}>
            <PerformanceGridLegend
              atBenchmarkCount={siteExplorerData?.pages[0]?.average}
              efficientCount={siteExplorerData?.pages[0]?.efficient}
              extremelyInefficientCount={
                siteExplorerData?.pages[0]?.very_inefficient
              }
              highlyEfficientCount={siteExplorerData?.pages[0]?.very_efficient}
              inefficientCount={siteExplorerData?.pages[0]?.inefficient}
              siteCount={siteExplorerData?.pages[0]?.total}
            />
          </Box>
          <Box flex={1} position="relative">
            <Box
              sx={{
                bottom: 0,
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <NzDataGrid
                apiRef={gridApiRef}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                disableColumnFilter
                isLoading={
                  buildingTypesIsFetching ||
                  recommendationsPlansIsLoading ||
                  siteExplorerIsFetching ||
                  siteUpdateIsLoading
                }
                onColumnOrderChange={handleColumnOrderChange}
                onColumnVisibilityModelChange={
                  handleColumnVisibilityModelChange
                }
                onFilterModelChange={handleOnFilterModelChange}
                onPinnedColumnsChange={handlePinnedColumnsChange}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onRowEditStop={handleRowEditStop}
                onSortModelChange={handleSortModelChange}
                pinnedColumns={pinnedColumns}
                processRowUpdate={handleProcessRowUpdate}
                rowModesModel={rowModesModel}
                rows={rows}
                slotProps={{
                  toolbar: {
                    columnsButtonEl,
                    moreMenuItems: (
                      <div>
                        {
                          // Cannot wrap in fragment (e.g. <></>) due to MUI error
                          // https://github.com/mui/material-ui/issues/16181
                        }
                        <MenuItem
                          component="a"
                          href={`/api/v1/site_explorer/export_csv?organization_id=${
                            organization?.id
                          }&start_date=${formatToYYYYMMDD(
                            yearStart
                          )}&end_date=${formatToYYYYMMDD(yearEnd)}`}
                        >
                          Export CSV - Sites
                        </MenuItem>
                        <MenuItem
                          component="a"
                          href={`/api/v1/site_explorer/export_csv?export_all=true&start_date=${formatToYYYYMMDD(
                            yearStart
                          )}&end_date=${formatToYYYYMMDD(yearEnd)}`}
                        >
                          Export CSV - Sites across Organization
                        </MenuItem>
                      </div>
                    ),
                    setColumnsButtonEl,
                    startActions: (
                      <YearPicker
                        availableDateRange={availableReportDatesData}
                        onChange={handleDateChange}
                        value={yearStart}
                      />
                    ),
                  },
                  panel: {
                    anchorEl: columnsButtonEl,
                  },
                }}
                slots={{
                  toolbar: DataGridToolbar,
                }}
                sortingMode="server"
                sortModel={sortModel}
              />
            </Box>
          </Box>
        </PageCard>
      </Page>
    </>
  )
}

import React, { useCallback, useMemo } from "react"

import { useOrganizationContext } from "@/contexts"
import { useAvailableReportDates } from "@/services"
import { FeatureFlags, useFeature } from "@/services/feature"
import type { Moment } from "moment"
import moment from "moment"

import { WarningAmberRounded } from "@mui/icons-material"
import { Alert, Box, FormControl, FormLabel } from "@mui/material"

import { MonthRangeSelector } from "../../../../components/date/monthRangeSelector/monthRangeSelector"
import useFilters from "../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../models/filter/dateFilter"
import type { IRange } from "../../../../models/range"
import type {
  GresbEnergyReportTableRow,
  GresbGhgReportTableRow,
  GresbWaterReportTableRow,
  IGresbFilters,
} from "../../models/gresb"
import type { Report } from "../../models/report"
import { reportCard } from "../../models/report"
import { useGresbReport } from "../../services/useGresbReport/useGresbReport"
import DataAlert from "../dataAlert/dataAlert"
import DataSummary from "../dataSummary/dataSummary"
import DownloadCsv from "../downloadCsv/downloadCsv"
import DownloadPreviewCsv from "../downloadPreviewCsv/downloadPreviewCsv"
import ReportLayout from "../reportLayout/reportLayout"

/**
 * A component used to build a GRESB report
 *
 * @example
 * return (
 *   <Gresb />
 * )
 */
const Gresb = () => {
  const { filters, setFilters } = useFilters<IGresbFilters>({
    start: DateFilter,
    end: DateFilter,
  })

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const { availableReportDatesData } = useAvailableReportDates(organization?.id)

  const isDataUnderReview: boolean = isFeatureEnabled(
    FeatureFlags.REPORT_GRESB_UNDER_REVIEW,
    organization
  )

  const isDataReady: boolean = isFeatureEnabled(
    FeatureFlags.REPORT_GRESB_READY_TO_REPORT,
    organization
  )

  const { gresbReport, isGresbReportLoading } = useGresbReport(
    organization?.id,
    {
      start: filters.start.value,
      end: filters.end.value,
    },
    isDataReady
  )

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const reports: Report<
    | GresbEnergyReportTableRow
    | GresbGhgReportTableRow
    | GresbWaterReportTableRow
  >[] = useMemo(
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => (gresbReport ? Object.values(gresbReport) : []),
    [gresbReport]
  )

  const onDateChange = useCallback(
    (value: IRange<Moment>): void => {
      setFilters({
        start: new DateFilter(moment(value.start)),
        end: new DateFilter(moment(value.end)),
      })
    },
    [setFilters]
  )

  return (
    <ReportLayout
      actions={
        <span>
          {!isDataReady && (
            <DownloadPreviewCsv
              isDisabled={!gresbReport || isGresbReportLoading}
              isLoading={isGresbReportLoading}
              report={reports}
            />
          )}
          {isDataReady && (
            <DownloadCsv
              isDisabled={!gresbReport || isGresbReportLoading}
              isLoading={isGresbReportLoading}
              report={reports}
            />
          )}
        </span>
      }
      details={
        <DataSummary>
          <Box component="p" sx={{ mt: 0, mb: 2 }}>
            Your data download includes 3 individual CSV files for energy,
            emissions, and water sections of a GRESB report
          </Box>
          <Box component="p" sx={{ m: 0 }}>
            Your emissions CSV will include two data points for scope 2
            emissions:
          </Box>
          <Box component="ol" sx={{ mt: 0, mb: 2, pl: 2.5 }}>
            <li>Location based emissions (eGRID)</li>
            <li>NZero advanced grid study</li>
          </Box>
          <Alert
            icon={<WarningAmberRounded sx={{ color: "grey.800" }} />}
            severity="warning"
          >
            This download does not include market-based emissions (optional for
            GRESB). Contact us to explore adding market-based emissions.
          </Alert>
        </DataSummary>
      }
      filters={
        <FormControl>
          <FormLabel sx={{ mb: 0.5 }}>Date Range</FormLabel>
          <MonthRangeSelector
            availableMaxMinMonths={availableReportDatesData}
            onChange={onDateChange}
            value={{
              start: filters.start.value,
              end: filters.end.value,
            }}
          />
        </FormControl>
      }
      notifications={
        <DataAlert
          isDataReady={isDataReady}
          isDataUnderReview={isDataUnderReview}
        />
      }
      report={reportCard.Gresb}
    />
  )
}

export default Gresb

import type { ChangeEvent } from "react"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Page, PageCard, PageHeader } from "@/components"
import { If } from "@/components/if/if"
import { useOrganizationContext } from "@/contexts"
import type { CurrencyCode } from "@/models/currencyCode"
import { Translation } from "@/models/i18n"
import type { LanguageRegion } from "@/models/i18n"
import { FeatureFlags, useFeature } from "@/services/feature"

import { FormControl, FormLabel, Stack, TextField } from "@mui/material"

import { useUserSettings } from "../../contexts/userSettings.provider"

/**
 * A component that renders the Settings content
 *
 * @example
 * return (
 *   <Settings />
 * )
 */

export const Settings = () => {
  const { t, i18n } = useTranslation()
  const userSettings = useUserSettings()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  /* For now, fetch the supported currencies when the Settings view is visited. In the future,
     we may need to access the supported currencies from other views. At that point, we should
     either lift this fetch up the component hierarchy, or create a settings resolver to determine
     when to fetch. */
  useEffect(() => {
    if (!userSettings.hasLoadedSupportedCurrencies) {
      userSettings.fetchSupportedCurrencies()
    }
  }, [
    userSettings.fetchSupportedCurrencies,
    userSettings.hasLoadedSupportedCurrencies,
  ])

  const supportedLocales = (i18n?.options?.supportedLngs ||
    []) as LanguageRegion[]

  const onCurrencyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      userSettings.setCurrency(event.target.value as CurrencyCode)
    },
    [userSettings.setCurrency]
  )

  const onLanguageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      userSettings.setLanguage(event.target.value as LanguageRegion)
    },
    [userSettings.setLanguage]
  )
  return (
    <>
      <PageHeader title={t(Translation.Settings.SettingsTitle)} />
      <Page>
        <PageCard>
          <Stack alignItems="flex-start" gap={2}>
            <FormControl>
              <FormLabel>{t(Translation.Settings.CurrencyLabel)}</FormLabel>
              <TextField
                inputProps={{
                  "data-testid": "currency-select",
                }}
                margin="dense"
                onChange={onCurrencyChange}
                select
                SelectProps={{
                  native: true,
                }}
                value={userSettings.currency}
              >
                {userSettings.currency &&
                  userSettings.supportedCurrencies.map((currency) => (
                    <option
                      key={currency}
                      data-testid={`currency-select-option-${currency}`}
                      value={currency}
                    >
                      {t(Translation.Common.Currency[currency])} ({currency})
                    </option>
                  ))}
              </TextField>
            </FormControl>
            <If
              condition={isFeatureEnabled(
                FeatureFlags.SETTINGS_LANGUAGE_SELECTOR,
                organization
              )}
            >
              <FormControl>
                <FormLabel>{t(Translation.Settings.LanguageLabel)}</FormLabel>
                <TextField
                  inputProps={{
                    "data-testid": "language-select",
                  }}
                  margin="dense"
                  onChange={onLanguageChange}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  value={i18n.language}
                >
                  {supportedLocales
                    .filter((locale) => new Intl.Locale(locale).region)
                    .map((locale) => {
                      const intlLocale = new Intl.Locale(locale)
                      return (
                        <option
                          key={locale}
                          data-testid={`language-select-option-${locale}`}
                          value={locale}
                        >
                          {t(Translation.Common.Language[intlLocale.language])}{" "}
                          ({intlLocale.region})
                        </option>
                      )
                    })}
                </TextField>
              </FormControl>
            </If>
          </Stack>
        </PageCard>
      </Page>
    </>
  )
}

export default Settings

import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import type { IRange } from "@/models/range"
import { clampDateRange, isValidCrossYearDateRange } from "@/utils/date"
import moment from "moment"
import type { Moment } from "moment"

import { buildUrlSearchParamsString } from "../url-utils"

interface UseSearchParamsDateRangeProps {
  /** The valid date range */
  validRange: IRange<Moment> | undefined
}

interface UseSearchParamsDateRangeValue {
  /** The end date */
  endDate: Moment
  /** The start date */
  startDate: Moment
}

/**
 * Returns the start and end date from the search params,
 * ensures that the date range is within the valid range,
 * and sets a default date range if the search params are invalid or missing.
 * @example
 * const { startDate, endDate } = useSearchParamsDateRange({
 *   validRange: {
 *     start: moment("2022-01-01"),
 *     end: moment("2024-12-31"),
 *   },
 * })
 */
export const useSearchParamsDateRange = ({
  validRange,
}: UseSearchParamsDateRangeProps): UseSearchParamsDateRangeValue => {
  const [searchParams, setSearchParams] = useSearchParams()

  const startParam: string | null = searchParams.get("start")
  const endParam: string | null = searchParams.get("end")

  const start: Moment = useMemo(() => moment(startParam), [startParam])
  const end: Moment = useMemo(() => moment(endParam), [endParam])

  useEffect(() => {
    if (
      validRange?.start.isValid() &&
      validRange.end.isValid() &&
      !isValidCrossYearDateRange(
        {
          start,
          end,
        },
        validRange
      )
    ) {
      const newDateRange = clampDateRange(
        {
          start: validRange.end.clone().startOf("year"),
          end: validRange.end.clone().endOf("month"),
        },
        {
          start: validRange.start.clone().startOf("month"),
          end: validRange.end.clone().endOf("month"),
        }
      )

      const newSearchParamsString = buildUrlSearchParamsString(
        {
          start: newDateRange.start,
          end: newDateRange.end,
        },
        searchParams,
        {
          dateFormat: "YYYY-MM-DD",
        }
      )

      setSearchParams(newSearchParamsString, { replace: true })
    }
  }, [end, searchParams, setSearchParams, start, validRange])

  return { endDate: end, startDate: start }
}

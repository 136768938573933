import type { Context, ReactNode } from "react"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"

import { useOrganizationContext } from "@/contexts"
import { FeatureFlags, useFeature } from "@/services/feature"

import type { CurrencyCode } from "../../../models/currencyCode"
import {
  LanguageCurrencyMap,
  defaultCurrencyCode,
} from "../../../models/currencyCode"
import type { LanguageRegion } from "../../../models/i18n"
import { getCurrency, getLanguage } from "../../../utils/localStorage"
import { getDataFromUserSettings } from "../util/settings/settings"
import type { IUserSettingsData } from "./userSettings.store"
import {
  changeCurrency,
  changeLocale,
  getUserSettingsSelector,
  loadSupportedCurrencies,
  setInitialUserCurrency,
  setInitialUserLocale,
  setUserSettings,
} from "./userSettings.store"

export interface IUserSettings extends IUserSettingsData {
  fetchSupportedCurrencies: () => void
  setCurrency: (currencyCode: CurrencyCode) => void
  setLanguage: (languageRegion: LanguageRegion) => void
}

export interface IUserSettingsProviderProps {
  children?: ReactNode
  userSettings?: IUserSettings
}

// Mass lint disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const UserSettingsContext: Context<IUserSettings> = createContext(undefined)

/**
 * A component that provides the user settings
 *
 * @param props - The component properties
 * @param props.children - The child nodes to render
 * @param props.setCurrency - Sets the active currency
 * @param props.setLanguage - Sets the active language
 * @param props.userSettings - The settings
 * @example
 * return (
 *   <UserSettingsProvider>
 *     <Home />
 *   </UserSettingsProvider>
 * )
 */
export const UserSettingsProvider = (props: IUserSettingsProviderProps) => {
  const dispatch = useDispatch()
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userSettings = useSelector(getUserSettingsSelector, shallowEqual)
  const { isFeatureEnabled } = useFeature()

  const { organization } = useOrganizationContext()

  // Custom setter that saves currency to localStorage and state
  const setCurrency = useCallback(
    props?.userSettings?.setCurrency ??
      ((currencyCode: CurrencyCode): void => {
        dispatch(changeCurrency(currencyCode))
      }),
    [dispatch, props?.userSettings?.setCurrency]
  )

  // Custom setter that saves language to localStorage and state
  const setLanguage = useCallback(
    props?.userSettings?.setLanguage ??
      ((locale: LanguageRegion): void => {
        dispatch(changeLocale(locale))
      }),
    [dispatch, props?.userSettings?.setLanguage]
  )

  // Custom callback that fetches the supported currencies
  const fetchSupportedCurrencies = useCallback(
    props?.userSettings?.fetchSupportedCurrencies ??
      ((): void => {
        dispatch(loadSupportedCurrencies())
      }),
    [dispatch, props?.userSettings?.fetchSupportedCurrencies]
  )

  // Set default currency to currency associated with org's locale if currency is not yet set
  // because a value was not found in localStorage
  useEffect(() => {
    const userCurrencySetting: CurrencyCode | null = getCurrency()

    if (organization && !userCurrencySetting) {
      setCurrency(
        LanguageCurrencyMap.get(organization.locale.name as LanguageRegion) ??
          defaultCurrencyCode
      )
    } else if (organization && userCurrencySetting) {
      dispatch(setInitialUserCurrency(userCurrencySetting))
    }
  }, [isFeatureEnabled, LanguageCurrencyMap, organization, setCurrency])

  // Set i18n default language to language associated with org's locale if language is not yet set
  // because a value was not found in localStorage
  useEffect(() => {
    if (
      organization &&
      isFeatureEnabled(FeatureFlags.SETTINGS_LANGUAGE_SELECTOR, organization)
    ) {
      dispatch(
        setInitialUserLocale(
          getLanguage() ?? (organization.locale.name as LanguageRegion)
        )
      )
    }
  }, [isFeatureEnabled, organization, getLanguage])

  // If user settings are provided, set the store data
  useEffect(() => {
    if (props?.userSettings) {
      dispatch(setUserSettings(getDataFromUserSettings(props.userSettings)))
    }
  }, [dispatch, props?.userSettings])

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value: IUserSettings = useMemo(
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => ({
      ...userSettings,
      setCurrency,
      setLanguage,
      fetchSupportedCurrencies,
    }),
    [fetchSupportedCurrencies, setCurrency, setLanguage, userSettings]
  )

  return (
    <UserSettingsContext.Provider value={value}>
      {props.children}
    </UserSettingsContext.Provider>
  )
}

// isDisabled is a temporary fix to prevent the use of the user settings context in all services.
// TODO: Convert useUserSettings to a react query service
export const useUserSettings = (isDisabled?: boolean): IUserSettings => {
  const context = useContext(UserSettingsContext)

  if (isDisabled) {
    return
  }

  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider")
  }

  return context
}

import {
  forEach,
  isNil,
  lowerFirst,
  map,
  mapKeys,
  mapValues,
  pickBy,
} from "lodash-es"
import type { Scope, SortScope } from "spraypaint"
import { MiddlewareStack, Model, SpraypaintBase } from "spraypaint"

import { getCurrency } from "../../utils/localStorage"
import { defaultCurrencyCode } from "../currencyCode"
import { HttpHeaderName } from "../http"
import type { Order } from "../sort"

type ModelId = string

type AllKeys = keyof ReturnType<Model> | keyof ApplicationRecord | "id"

type ExtendedKeys<M> = Exclude<keyof M, AllKeys | "id">

type Flatten<T> = T extends (infer V)[] ? V : T

type IsSpraypaintModel<T> = T extends ApplicationRecord ? T : never

type IsRelation<T> = unknown extends T // if unknown
  ? never
  : 0 extends 1 & T // if any
    ? never
    : T extends IsSpraypaintModel<T>
      ? T
      : never

export type ModelRelationshipKeys<M> = keyof {
  [K in keyof Omit<M, AllKeys> as IsRelation<Flatten<M[K]>> extends never
    ? never
    : K extends string
      ? K
      : never]: unknown
}

export type ModelAttributeKeys<M> = keyof {
  [K in Exclude<ExtendedKeys<M>, ModelRelationshipKeys<M>> as M[K] extends (
    ...args: unknown[]
  ) => unknown
    ? never
    : K extends string
      ? K
      : never]: unknown
}

export type ToPojo<M> = { id: ModelId } & {
  [K in ModelAttributeKeys<M>]: M[K]
} & {
  [K in ModelRelationshipKeys<M>]: M[K] extends (infer A)[]
    ? ToPojo<A>[]
    : ToPojo<M[K]>
}

// TODO: This is sloppy. Remove any's.
export type GetValues<M> = { id: ModelId } & {
  [K in ModelAttributeKeys<M>]: M[K]
} & {
  [K in ModelRelationshipKeys<M> as K extends string
    ? // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      M[K] extends ArrayLike<any>
      ? `${K}Ids`
      : `${K}Id`
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
      never]: M[K] extends ArrayLike<infer A> ? unknown[] : unknown
} & {
  [K in ModelRelationshipKeys<M>]: M[K] extends ArrayLike<infer A>
    ? ToPojo<A>[]
    : ToPojo<M[K]>
}

// https://jsonapi.org/format/#fetching-pagination
export type PageLinks = Record<
  "first" | "next" | "last" | "prev",
  string | null
>

interface ModelLookupOptions {
  extra: undefined | string[]
  include: undefined | string[]
  sort: unknown
  where: unknown
}

type ModelRelationshipType = "hasOne" | "belongsTo" | "hasMany"

interface ModelRelationshipInfo {
  RelationshipModel: typeof ApplicationRecord
  attributeName: string
  idName: string
  isArray: boolean
  relationshipType: ModelRelationshipType
}

// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPojoRelationships = (relationships): any =>
  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  Object.entries(relationships).reduce<any>(
    (pojoRelationships, [key, relationship]) => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newPojoRelationships = {
        ...pojoRelationships,
      }

      // Purposely return HasMany relationships as class instances
      // Spraypaint isn't creating class instances from HasMany relationships
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      newPojoRelationships[key] = Array.isArray(relationship)
        ? relationship
        : (relationship as ApplicationRecord).toPojo()

      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return newPojoRelationships
    },
    {}
  )

export interface IFetchApplicationRecordOptions {
  enabled?: boolean
  order?: Order | "asc" | "desc"
  orderBy?: string
  pageNumber?: number
  pageSize?: number
}

type ApplicationRecordInstance = InstanceType<typeof ApplicationRecord>

// TODO: Memoize static getFoo functions as static getters.

@Model()
export default class ApplicationRecord extends SpraypaintBase {
  static sync = false // this isn't actually getting us anything right now

  public static baseUrl = process.env.REACT_APP_API_BASE_URL ?? ""

  public static apiNamespace = "/api/v1"

  private lookupOptions?: ModelLookupOptions

  public static getAttributeNames() {
    return Object.keys(this.attributeList).filter(
      (attributeName) => !this.attributeList[attributeName].isRelationship
    )
  }

  public static getRelationshipNames() {
    return Object.keys(this.attributeList).filter(
      (attributeName) => this.attributeList[attributeName].isRelationship
    )
  }

  public static getRelationshipInfo(
    attributeName: string
  ): ModelRelationshipInfo {
    const relationshipValue = this.attributeList[attributeName]
    if (!relationshipValue) {
      throw new Error(`Invalid relationship name.`)
    }

    const relationshipType = lowerFirst(
      relationshipValue.constructor.name
    ) as ModelRelationshipType
    const isArray = relationshipType === "hasMany"
    const idName = isArray ? `${attributeName}Ids` : `${attributeName}Id`

    return Object.freeze({
      attributeName,
      idName,
      relationshipType,
      isArray,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      RelationshipModel:
        relationshipValue.type ??
        // Mass lint disable
        // Mass lint disable
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (this.typeRegistry.get((relationshipValue as any).jsonapiType) as any),
    })
  }

  public static getRelationshipsInfo() {
    return Object.fromEntries(
      this.getRelationshipNames().map((attributeName) => [
        attributeName,
        this.getRelationshipInfo(attributeName),
      ])
    )
  }

  private isRelationship(attributeName: string) {
    return (this.constructor as typeof ApplicationRecord).attributeList[
      attributeName
    ]?.isRelationship
  }

  private isIncludedRelationship(attributeName: string) {
    return (
      this.isRelationship(attributeName) &&
      !(this.lookupOptions?.include ?? []).every(
        (i) => i !== attributeName && isNil(i[attributeName])
      )
    )
  }

  private isAttribute(attributeName: string) {
    if (attributeName === "id") {
      return false
    }
    const attributeInfo = (this.constructor as typeof ApplicationRecord)
      .attributeList[attributeName]
    if (!attributeInfo) {
      return false
    }
    return !attributeInfo.isRelationship
  }

  private isExtraAttribute(attributeName: string) {
    return this.lookupOptions?.extra?.includes(attributeName) ?? false
  }

  /**
   * Retrieve and instantiate records from the API.
   */
  public static async fetchInstances(
    options: Partial<ModelLookupOptions> = {}
  ): Promise<ApplicationRecord[]> {
    const { include, extra, where, sort } = options

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let scope = this as unknown as Scope

    if (include) {
      scope = scope.includes(include)
    }
    if (extra) {
      scope = scope.selectExtra(extra)
    }
    if (where) {
      scope = scope.where(where)
    }
    if (sort) {
      scope = scope.order(sort as SortScope)
    }

    const { data: result } = await scope.all()

    return (
      result?.map((instance) => {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
        ;(instance as any).lookupOptions = options

        return instance as ApplicationRecord
      }) ?? []
    )
  }

  /**
   * Extract values from the model, injecting relationship IDs as [relationship]Id(s).
   */
  public getValues(): GetValues<this> {
    const self = this.constructor as typeof ApplicationRecord

    // Extract the attribute values.
    const attributeValues = pickBy(this.attributes, (_, attributeName) =>
      this.isAttribute(attributeName)
    )

    // Extract the relationships values.
    const relationshipInfoByAttributeName = self.getRelationshipsInfo()
    const relationshipValues = mapValues(
      relationshipInfoByAttributeName,
      ({ isArray, attributeName }: ModelRelationshipInfo) => {
        const relation = this.relationships[attributeName]
        if (!this.isIncludedRelationship(attributeName)) {
          return undefined
        }
        if (isNil(relation)) {
          return isArray ? [] : null
        }
        if (isArray) {
          return (relation as ApplicationRecordInstance[]).map((r) =>
            r.getValues()
          )
        }
        return (relation as ApplicationRecordInstance).getValues()
      }
    )

    // Extract relationship IDs as [relationship]Id(s).
    const relationshipInfoByIdName = mapKeys(
      relationshipInfoByAttributeName,
      ({ idName }) => idName
    )
    const relationshipIds = mapValues(
      relationshipInfoByIdName,
      ({ isArray, attributeName }: ModelRelationshipInfo) => {
        const relation = this.relationships[attributeName]
        if (!this.isIncludedRelationship(attributeName)) {
          return undefined
        }
        if (isNil(relation)) {
          return isArray ? [] : null
        }
        if (isArray) {
          // Mass eslint disable @typescript-eslint/no-explicit-any
          // Mass eslint disable @typescript-eslint/no-unsafe-return
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
          return (relation as any[]).map(({ id }) => id)
        }
        // Mass lint disable
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
        return (relation as any).id
      }
    )

    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return {
      ...attributeValues,
      ...relationshipValues,
      ...relationshipIds,
      id: this.id,
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any
  }

  /**
   * Apply values to the model. Relationships must be set via [relationship]Id(s) string.
   */
  public async saveValues(newValues): Promise<boolean> {
    const self = this.constructor as typeof ApplicationRecord

    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (newValues.id !== this.id) {
      throw new Error(
        `Instance "${self.jsonapiType}" cannot have its ID changed.`
      )
    }

    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (newValues.id && !this.lookupOptions) {
      throw new Error(
        `Model "${self.jsonapiType}" must be instantiated with fetchInstances().`
      )
    }

    const attributeNames = self.getAttributeNames()
    const relationshipNames = self.getRelationshipNames()
    const relationshipsInfo = self.getRelationshipsInfo()
    const relationshipIdNames = map(relationshipsInfo, "idName")

    // Validate new values.
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(newValues).forEach((attributeName) => {
      if (relationshipNames.includes(attributeName)) {
        throw new Error(
          `Do not set nested attribute "${attributeName}" on model "${self.jsonapiType}".`
        )
      }

      if (
        !this.isAttribute(attributeName) &&
        !this.isRelationship(attributeName) &&
        !relationshipIdNames.includes(attributeName) &&
        attributeName !== "id"
      ) {
        throw new Error(
          `Unrecognized attribute "${attributeName}" on model "${self.jsonapiType}".`
        )
      }
    })

    // Set new attributes.
    attributeNames.forEach((attributeName) => {
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const newValue = newValues[attributeName]
      if (newValue === undefined) {
        return
      }

      if (this.isExtraAttribute(attributeName)) {
        throw new Error(
          `Do not set extra attribute "${attributeName}" on model "${self.jsonapiType}".`
        )
      }
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      ;(this as any)[attributeName] = newValue
    })

    // Set new relationships.
    const withOption = []
    forEach(
      relationshipsInfo,
      ({ RelationshipModel, isArray, attributeName, idName }) => {
        // migration to strict mode batch disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const newValue = newValues[idName]

        // Leave undefined values alone.
        if (newValue === undefined) {
          return
        }

        // Spraypaint requires the previous relationship ID(s) in order to disassociate.
        if (
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          newValues.id &&
          !this.lookupOptions.include.includes(attributeName)
        ) {
          throw new Error(
            `Attribute "${attributeName}" must be included to set relationship.`
          )
        }

        // Make sure we are only setting relationship IDs and nothing else.
        withOption.push(`${attributeName}.id`)

        if (isArray) {
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const prevRelationships = this[attributeName] ?? []
          // migration to strict mode batch disable
          // Mass lint disable
          // Mass eslint disable
          // Mass eslint disable @typescript-eslint/no-unsafe-return
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
          const prevIds = prevRelationships.map(({ id }) => id)
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          prevRelationships.forEach((relationship) => {
            // Mass lint disable
            // Mass eslint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            if (prevIds.includes(relationship.id)) {
              // Mass lint disable
              // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-member-access
              relationship.isMarkedForDisassociation = true
            }
          })
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          newValue.forEach((id) => {
            // Mass lint disable
            // Mass eslint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            if (!prevIds.includes(id)) {
              // migration to strict mode batch disable
              // Mass lint disable
              // Mass eslint disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              this[attributeName].push(new RelationshipModel({ id }))
            }
          })
        } else {
          // Relationship is unchanged. Noop.
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (this[attributeName]?.id === newValue) {
            return
          }

          if (newValue === null) {
            // Single relationship must be disassociated.
            if (this[attributeName]) {
              // Mass lint disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              this[attributeName].isMarkedForDisassociation = true
            }
          } else {
            // No need to disassociate if overwriting.
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this[attributeName] = new RelationshipModel({ id: newValue })
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this[attributeName].isPersisted = true
          }
        }
      }
    )

    return await this.save({ with: withOption })
  }

  /**
   * Get a plain old JavaScript object representation of the class instance
   *
   * @returns A plain old JavaScript object representation of the class instance
   */
  toPojo(): ToPojo<this> {
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return {
      ...this.attributes,
      id: this.id,
      ...getPojoRelationships(this.relationships),
    }
  }
}

const middleware = new MiddlewareStack()

middleware.beforeFilters.push((_url, options): void => {
  const currency = getCurrency() ?? defaultCurrencyCode
  // eslint-disable-next-line no-param-reassign
  options.headers[HttpHeaderName.XDisplayCurrency] = currency
})

ApplicationRecord.middlewareStack = middleware

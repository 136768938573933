import React from "react"
import type { FC } from "react"

import { Box } from "@mui/material"

import ReportCompleteAlert from "../dataAlertComplete/dataAlertComplete"
import DataAlertInProgress from "../dataAlertInProgress/dataAlertInProgress"
import DataAlertPreview from "../dataAlertPreview/dataAlertPreview"

interface DataAlertProps {
  isDataReady: boolean
  isDataUnderReview: boolean
}

const DataAlert: FC<DataAlertProps> = ({ isDataReady, isDataUnderReview }) => {
  const isDataPreviewAlertVisible: boolean = !isDataUnderReview && !isDataReady
  const isDataUnderReviewAlertVisible: boolean =
    isDataUnderReview && !isDataReady
  const isDataReadyAlertVisible: boolean = isDataReady

  return (
    <Box mb={2}>
      {isDataPreviewAlertVisible && <DataAlertPreview />}
      {isDataUnderReviewAlertVisible && <DataAlertInProgress />}
      {isDataReadyAlertVisible && <ReportCompleteAlert />}
    </Box>
  )
}

export default DataAlert

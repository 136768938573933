import { ReportPath } from "@/models/route"
import type { ITableData, ITableRow } from "@/models/table"
import { FeatureFlags } from "@/services/feature"

import GresbLogo from "../assets/images/gresb-logo.png"
import GriLogo from "../assets/images/gri-logo.png"

export enum ReportGroup {
  Consultant,
  One,
  Two,
}

export interface ReportCard {
  altText?: string
  buttonLink: string
  buttonText: string
  dataE2e?: string
  dataReadyFeatureFlag?: FeatureFlags
  description: string
  featureFlag?: FeatureFlags
  group: ReportGroup
  isVisibleToLimitedAccessUser?: boolean
  logo?: string
  newFeatureFlag?: FeatureFlags
  title: string
  underReviewFeatureFlag?: FeatureFlags
}

interface ReportCardMap {
  Custom: ReportCard
  EmissionsSources: ReportCard
  Gresb: ReportCard
  Gri: ReportCard
  MonthlySiteData: ReportCard
  ResourceSummary: ReportCard
  Scope3: ReportCard
}

export const reportCard: ReportCardMap = {
  Custom: {
    buttonLink: ReportPath.CustomReports,
    buttonText: "View Custom Reports",
    dataE2e: "custom-reports-report-button",
    description:
      "View custom reports created for you by NZero's Data Services team",
    featureFlag: FeatureFlags.REPORT_CUSTOM_REPORTS,
    group: ReportGroup.One,
    isVisibleToLimitedAccessUser: false,
    title: "Custom Reports",
  },
  ResourceSummary: {
    buttonLink: ReportPath.ResourceSummary,
    buttonText: "Start Resource Summary",
    dataE2e: "resource-summary-report-button",
    description:
      "Select and sort all sites based on volume, usage, and emissions",
    group: ReportGroup.One,
    isVisibleToLimitedAccessUser: true,
    title: "Resource Summary",
  },
  EmissionsSources: {
    buttonLink: ReportPath.EmissionsSources,
    buttonText: "Start Sources Summary",
    dataE2e: "emissions-sources-report-button",
    description: "Download activity and emissions totals for your organization",
    featureFlag: FeatureFlags.REPORT_ACTIVITY_EMISSIONS,
    group: ReportGroup.One,
    isVisibleToLimitedAccessUser: false,
    title: "Emissions Sources",
  },
  MonthlySiteData: {
    buttonLink: ReportPath.MonthlySiteData,
    buttonText: "Start Site Data Download",
    dataE2e: "monthly-site-data-report-button",
    description: "Download monthly activity or emission values for all sites",
    featureFlag: FeatureFlags.REPORT_MONTHLY_SITE_DATA,
    group: ReportGroup.One,
    isVisibleToLimitedAccessUser: true,
    title: "Monthly Site Data",
  },
  Scope3: {
    buttonLink: ReportPath.ScopeThree,
    buttonText: "Start Scope 3 Report",
    dataE2e: "scope3-report-button",
    description: "Download granular scope 3 emissions within your organization",
    group: ReportGroup.One,
    isVisibleToLimitedAccessUser: false,
    title: "Scope 3",
  },
  Gri: {
    altText: "GRI Logo",
    buttonLink: ReportPath.Gri,
    buttonText: "Start GRI Data Export",
    dataE2e: "gri-data-export-button",
    dataReadyFeatureFlag: FeatureFlags.REPORT_GRI_READY_TO_REPORT,
    description: "Download NZero data relevant for reporting to GRI",
    featureFlag: FeatureFlags.REPORT_GRI,
    group: ReportGroup.Two,
    isVisibleToLimitedAccessUser: false,
    logo: GriLogo,
    title: "GRI Data Export",
    underReviewFeatureFlag: FeatureFlags.REPORT_GRI_UNDER_REVIEW,
  },
  Gresb: {
    altText: "GRESB Logo",
    buttonLink: ReportPath.Gresb,
    buttonText: "Start GRESB Data Export",
    dataE2e: "gresb-data-export-button",
    dataReadyFeatureFlag: FeatureFlags.REPORT_GRESB_READY_TO_REPORT,
    description: "Download NZero data relevant for reporting to GRESB",
    featureFlag: FeatureFlags.REPORT_GRESB,
    group: ReportGroup.Two,
    isVisibleToLimitedAccessUser: false,
    logo: GresbLogo,
    title: "GRESB Data Export",
    underReviewFeatureFlag: FeatureFlags.REPORT_GRESB_UNDER_REVIEW,
  },
}

export const reportCards: ReportCard[] = Object.values(
  reportCard
) as ReportCard[]

export interface Report<TRow extends ITableRow> {
  data: ITableData<TRow>
  name: string
}

export enum DataAlertInProgressText {
  AltText = "Report in progress stepper, preview available",
  Body = "Our Environmental Science & Sustainability team is reviewing your data. Feel free to download a preview but don’t use it for reporting just yet. We will let you know when your data is ready.",
  Title = "We’re preparing your data",
}

export enum DataAlertCompleteText {
  AltText = "Report complete stepper, ready to report",
  Body = "Our Environmental Science & Sustainability team has reviewed your data. This download can be used in your yearly reports.",
  Title = "Your data is ready for sharing",
}

export enum DataAlertPreviewText {
  Body = "Contact us if you would like to use this data for reporting purposes",
  Title = "Preview Only",
}

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useFormatDecimal } from "@/formatters/useFormatDecimal"
import { useFormatMoney } from "@/formatters/useFormatMoney"
import { useCurrencySymbol } from "@/hooks/useCurrencySymbol/useCurrencySymbol"
import { UnitName } from "@/models/unit"
import { DataGridColumnHeader } from "@/nzds/data-grid"
import { translateUnit } from "@/utils"

import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"

import { baseSiteNumberColumnDef } from "../../../../models"
import type { SitePlanGridRecord } from "../../../../services/use-site-plans"
import { useCommonSiteGridColumns } from "../../../utils/use-common-site-grid-columns"

export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {}

export const defaultPinnedColumns: GridPinnedColumns = {
  left: ["rowNumber", "site_name"],
  right: [],
}

export const defaultSortModel: GridSortModel = [
  {
    field: "site_name",
    sort: "asc",
  },
]

export const useDecarbonizeGridColumns =
  (): GridColDef<SitePlanGridRecord>[] => {
    const { i18n } = useTranslation()

    const { format: formatMoneyInteger } = useFormatMoney({
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })

    const { format: formatInteger } = useFormatDecimal({
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })

    const { format: formatDecimalTens } = useFormatDecimal({
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    })

    const currencySymbol = useCurrencySymbol()

    const commonSiteGridColumns = useCommonSiteGridColumns<SitePlanGridRecord>()

    const columns: GridColDef<SitePlanGridRecord>[] = useMemo(() => {
      return [
        {
          ...commonSiteGridColumns.rowNumber,
        },
        {
          ...commonSiteGridColumns.siteName,
        },
        {
          ...baseSiteNumberColumnDef,
          field: "percent_emission_savings",
          headerName: "Emission Reduction",
          hideable: false,
          renderHeader: () => (
            <DataGridColumnHeader
              headerName="Emission Reduction"
              unit={translateUnit(
                i18n.language,
                UnitName.MetricTonsOfCarbonDioxideEquivalent
              )}
            />
          ),
          valueFormatter: (params: GridValueFormatterParams<string>) =>
            formatInteger(params.value),
          width: 220,
        },
        {
          ...baseSiteNumberColumnDef,
          field: "capital_cost",
          headerName: "Cost",
          hideable: false,
          renderHeader: () => (
            <DataGridColumnHeader headerName="Cost" unit={currencySymbol} />
          ),
          valueFormatter: (params: GridValueFormatterParams<number>) =>
            formatMoneyInteger(params.value),
          width: 180,
        },
        {
          ...baseSiteNumberColumnDef,
          field: "annual_cost_savings",
          headerName: "Cost Savings",
          hideable: false,
          renderHeader: () => (
            <DataGridColumnHeader
              headerName="Cost Savings"
              unit={currencySymbol}
            />
          ),
          valueFormatter: (params: GridValueFormatterParams<number>) =>
            formatMoneyInteger(params.value),
          width: 180,
        },
        {
          ...baseSiteNumberColumnDef,
          field: "payback_period",
          headerName: "Payback Period",
          hideable: false,
          renderHeader: () => (
            <DataGridColumnHeader
              headerName="Payback Period"
              unit={currencySymbol}
            />
          ),
          valueFormatter: (params: GridValueFormatterParams<number>) =>
            formatDecimalTens(params.value),
          width: 200,
        },
      ]
    }, [
      commonSiteGridColumns.rowNumber,
      commonSiteGridColumns.siteName,
      i18n.language,
      formatInteger,
      currencySymbol,
      formatMoneyInteger,
      formatDecimalTens,
    ])

    return columns
  }

import React, { useState } from "react"

import { FeatureFlags, useFeature } from "@/services/feature"
import type { Moment } from "moment"

import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material"
import { Box, Collapse, Typography, useTheme } from "@mui/material"

import type Organization from "../../models/organization"
import type { ISitePreview } from "../../models/site"
import type { UnitName } from "../../models/unit"
import type {
  IDepartmentSummary,
  OrganizationChildrenSummary,
} from "../../modules/dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"
import { dustyGray, lightGray } from "../../utils/colors"
import { GroupFinancialMetrics } from "./groupFinancialMetrics"
import { SitesTable } from "./sitesTable/sitesTable"

/**
 * @deprecated This UI is slated to be replaced and this component will be deleted
 */

export const CollapsibleGroup = ({
  groupId,
  groupName,
  dateRange,
  hasBundled,
  organization,
  sitePreviews,
  whDisplayUnit,
  groupsSummaryData,
}: {
  dateRange: Moment[]
  groupId: string
  groupName: string
  groupsSummaryData: IDepartmentSummary[] | OrganizationChildrenSummary[]
  hasBundled: boolean
  organization: Organization
  sitePreviews: ISitePreview[]
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const { isFeatureEnabled } = useFeature()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  const name = groupName ?? "Other"
  const numSitesInDepartment: number | undefined = sitePreviews?.filter(
    (sitePreview) => sitePreview.departmentName === groupName
  ).length

  const numSitesInTopOrgUnit = sitePreviews?.filter(
    (sitePreview) => sitePreview.topOrgUnitName === groupName
  ).length

  const numSitesInGroup = isUseFlexibleHierarchyEnabled
    ? numSitesInTopOrgUnit
    : numSitesInDepartment

  // This is a hack in order to filter based on the data we receive
  let groupsData
  if (isUseFlexibleHierarchyEnabled) {
    groupsData = groupsSummaryData as OrganizationChildrenSummary[]
  } else {
    groupsData = groupsSummaryData as IDepartmentSummary[]
  }

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const groupSummaryData = isUseFlexibleHierarchyEnabled
    ? // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      groupsData.filter(
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (site) => site.organizationalUnitId === Number.parseInt(groupId, 10)
      )[0]
    : // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      groupsData.filter(
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (site) => site.departmentId === Number.parseInt(groupId, 10)
      )[0]

  return (
    groupSummaryData && (
      <Box sx={{ mt: 3 }}>
        <Box
          data-department={groupName}
          data-e2e="data-department"
          onClick={() => {
            setOpen(!open)
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            px: 1,
            pb: 1.5,
            borderBottom: `1px solid ${dustyGray.toString()}`,
            mb: 2,
          }}
        >
          {open ? (
            <ExpandLessRounded style={{ color: lightGray.toString() }} />
          ) : (
            <ExpandMoreRounded style={{ color: lightGray.toString() }} />
          )}
          <Typography sx={{ color: theme.palette.primary.dark }} variant="h2">
            {`${name} (${numSitesInGroup})`}
          </Typography>
        </Box>

        <GroupFinancialMetrics
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          groupSummaryData={groupSummaryData}
          hasBundled={hasBundled}
          whDisplayUnit={whDisplayUnit}
        />

        <Collapse in={open}>
          <SitesTable
            dateRange={dateRange}
            groupId={groupId}
            groupName={groupName}
            isOpen={open}
            organization={organization}
            whDisplayUnit={whDisplayUnit}
          />
        </Collapse>
      </Box>
    )
  )
}

import moment from "moment"

import type Price from "../models/price"

export const pricesForToday = (prices: Price[]): Price[] => {
  const startOfToday = moment().startOf("day")
  const todaysPrices = prices.filter((price) =>
    moment(price.hour).isAfter(startOfToday)
  )
  return todaysPrices.sort(
    (a, b) => moment(a.hour).unix() - moment(b.hour).unix()
  )
}

export const fetchSequentially = async (fnPromiseArr) => {
  const promiseResultList = []
  for (const fnPromise of fnPromiseArr) {
    promiseResultList.push(await fnPromise)
  }
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return promiseResultList
}

import React, { useCallback, useEffect } from "react"

import { datadogRum } from "@datadog/browser-rum"

import DownloadIcon from "@mui/icons-material/Download"
import { LoadingButton } from "@mui/lab"
import type { SxProps } from "@mui/material"

import { downloadCsvNew } from "../../../../utils/download"
import AnalyticsActionName from "../../models/analytics"
import type { Report } from "../../models/report"

interface IDownloadCsvProps {
  /**
   * Handler for button click when generating the file on demand
   */
  asyncOnClick?: () => void
  /**
   * Indicates if the report should be generated on demand
   */
  generateReportOnDemand?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  /**
   * Optional handler when the file is ready to be downloaded on mount
   */
  onClick?: () => void
  /**
   * The given report that will be downloaded
   */
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report: Report<any> | Report<any>[] | null
  size?: "small" | "medium"
  sx?: SxProps
  title?: string
  variant?: "contained" | "text"
}

/**
 * A component that will convert the report the user is seeing into csv file and download it.
 * If the report is ready to be downloaded on component mount, it gets passed in as a prop and downloaded automatically via onClick handler.
 * If the report is generated on click, the asyncOnClick handler is called first, the report is generated, and then downloaded when ready.
 * ```tsx
 * import DownloadCsv from "../downloadCsv/downloadCsv"
 *
 *  <DownloadCsv
 *           onClick={handleClose}
 *           report={report}
 *           title="Download Preview CSV"
 *   />
 * ```
 */
const DownloadCsv = ({
  isDisabled,
  isLoading,
  onClick = () => {
    // This is left empty because the onClick is optional
  },
  asyncOnClick = async () => {
    // This is left empty because the asyncOnClick is optional
  },
  generateReportOnDemand,
  report,
  title = "Download CSV",
  variant,
  size,
  sx,
}: IDownloadCsvProps) => {
  const handleClick = useCallback((): void => {
    if (Array.isArray(report)) {
      report.forEach((rep) => {
        downloadCsvNew(rep.data, rep.name)
      })
    } else {
      downloadCsvNew(report.data, report.name)
    }

    // Must trigger a custom action because Download CSV button doesn't trigger page activity
    // which is necessary for DataDog's automatic interaction tracking
    datadogRum.addAction(AnalyticsActionName.DownloadCsv)

    onClick()
  }, [onClick, report])

  // If we are generating file on demand (asyncOnClick is set) and report is ready, fire handleClick.
  // This is to handle the case where the user clicks the download button before the report is ready.
  // We don't want to fire handleClick here if report is ready on mount but we're not generating on demand.
  useEffect(() => {
    if (generateReportOnDemand && report) {
      handleClick()
    }
  }, [generateReportOnDemand, handleClick, report])

  const handleAsyncClick = () => {
    asyncOnClick()
  }

  return (
    <LoadingButton
      data-e2e="download-csv-button"
      disabled={isDisabled}
      endIcon={<DownloadIcon />}
      loading={isLoading}
      onClick={generateReportOnDemand ? handleAsyncClick : handleClick}
      size={size}
      sx={sx}
      title={title}
      variant={variant}
    >
      {title}
    </LoadingButton>
  )
}

export default DownloadCsv

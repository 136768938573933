import type { MouseEvent } from "react"
import React, { useCallback, useState } from "react"

import { useAuthentication } from "@/services/authentication"

import { PersonRounded } from "@mui/icons-material"
import { Button, Menu, MenuItem, Typography } from "@mui/material"

import { If } from "../../../../components/if/if"
import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { useLogoutContext } from "../../../../utils/logout-context"
import { pxToRem } from "../../../../utils/unit"

const TamAdminUserMenu = (): JSX.Element => {
  const { logout } = useLogoutContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl
  const handleClick = useCallback((e: MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget)
  }, [])
  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])
  const { authenticatedUser } = useAuthentication()
  const userName = authenticatedUser?.name

  return (
    <If condition={userName}>
      <Button
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="current user"
        color="neutral"
        data-testid="userButton"
        onClick={handleClick}
        size="small"
        startIcon={<PersonRounded />}
      >
        {userName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        onClose={handleClose}
        open={isOpen}
        sx={{ minWidth: pxToRem(172) }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          data-testid="logout"
          onClick={() => {
            logout().catch((error: unknown) => {
              if (error instanceof Error) {
                console.error(error)
              }
            })
          }}
          sx={{ px: 6, py: 1 }}
        >
          <Typography noWrap variant={TYPOGRAPHY_VARIANT.caption}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </If>
  )
}

export default TamAdminUserMenu

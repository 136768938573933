import { ApiQueryName } from "@/models/api"
import type { Organization } from "@/models/organization"
import type { Site } from "@/models/site"
import { httpClient } from "@/utils"
import { useQuery } from "@tanstack/react-query"

import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import { getSafeSigmaEmbedUserEmail } from "./sigma-utils"
import {
  allCustomersTeamName,
  allSitesTeamName,
  consultantTeamNames,
} from "./sigma.types"
import type { SigmaTeam, SigmaWorkbookEmbed } from "./sigma.types"
import { useSigmaTeams } from "./use-sigma-teams"

interface FetchSigmaWorkbookSecureEmbedUrlConfig {
  email: string
  embedPath: SigmaWorkbookEmbed["embedUrl"]
  externalUserTeams: SigmaTeam["name"][]
  isConsultantUser: boolean
  organizationId: Organization["id"]
  siteId?: Site["id"]
}

interface UseSigmaWorkbookSecureEmbedUrlConfig
  extends Omit<FetchSigmaWorkbookSecureEmbedUrlConfig, "externalUserTeams"> {
  externalUserTeam: string
}

interface SigmaWorkbookSecureEmbedUrlData {
  secureEmbedUrl: string
}

interface SigmaWorkbookSecureEmbedUrlResponse {
  data: SigmaWorkbookSecureEmbedUrlData
  status: string
  title: string
}

const fetchSigmaWorkbookSecureEmbedUrl = async (
  config: FetchSigmaWorkbookSecureEmbedUrlConfig
): Promise<SigmaWorkbookSecureEmbedUrlData> =>
  httpClient
    .get<SigmaWorkbookSecureEmbedUrlResponse>(
      `/api/v1/sigma_secure_embed_url`,
      {
        params: {
          email: config.email,
          embed_path: config.embedPath,
          external_user_team: config.externalUserTeams,
          hide_folder_navigation: true,
          organization_id: config.organizationId,
          site_id: config.siteId,
        },
      }
    )
    .then((response) => response.data.data)

/**
 * Gets the secure embed url for the requested Sigma embed
 *
 * @returns The secure embed url
 *
 * @example
 * const {
 *   sigmaWorkbookSecureEmbedUrlData,
 * } = useSigmaWorkbookSecureEmbedUrl({
 *   email: "user@nzero.com",
 *   embedPath: "https://app.sigmacomputing.com/embed/1-234asdf",
 *   externalUserTeam: "City of Reno",
 *   isConsultantUser: false,
 *   organizationId: "8",
 * })
 */
export const useSigmaWorkbookSecureEmbedUrl = (
  config: UseSigmaWorkbookSecureEmbedUrlConfig
) => {
  const { sigmaTeamsData, sigmaTeamsIsFetched } = useSigmaTeams({
    name: config.externalUserTeam,
  })

  const fetchConfig: FetchSigmaWorkbookSecureEmbedUrlConfig = {
    ...config,
    email: getSafeSigmaEmbedUserEmail(config.email),
    externalUserTeams: [
      // Include consultant-only teams
      ...(config.isConsultantUser ? consultantTeamNames : []),
      // Include "All Customers" team for all users
      allCustomersTeamName,
      // Include "All Sites" team for all users
      allSitesTeamName,
      // Only include team (e.g. "City of Reno") if it exists in Sigma
      ...(sigmaTeamsData?.entries?.[0] ? [config.externalUserTeam] : []),
    ],
  }

  const enabled =
    sigmaTeamsIsFetched &&
    Boolean(fetchConfig.embedPath) &&
    Boolean(fetchConfig.externalUserTeams.length)

  const queryKey = createQueryKey(
    ApiQueryName.SigmaWorkbookSecureEmbedUrl,
    "getOne",
    null,
    config
  )

  const queryResult = useQuery<SigmaWorkbookSecureEmbedUrlData>({
    // We never want to cache the url because it can only be used once
    cacheTime: 0,
    enabled,
    queryKey: [queryKey],
    queryFn: () => fetchSigmaWorkbookSecureEmbedUrl(fetchConfig),
    // We don't want to fetch a new url while on the page
    staleTime: Infinity,
  })

  return useRenameQueryResult(
    queryResult,
    ApiQueryName.SigmaWorkbookSecureEmbedUrl
  )
}

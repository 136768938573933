// @ts-strict-ignore
import type { Moment } from "moment"

import type { FinancialSummaryEnergyAllocator } from "../../../../models/financialSummaryEnergyAllocator"
import MeterHour from "../../../../models/meterHour"
import type { IRange } from "../../../../models/range"

interface FetchSiteProRatedUsageMeterValue {
  id: number
  meterCode: string
  meterType: string
  name: string
  subMeters: FetchSiteProRatedUsageMeterValue[]
  totalCost: string
  totalUsage: string
}

export interface FetchSiteProRatedUsageMeterProps {
  // TODO: Refactor to allow a defined type for childMeterNamesAndIds
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childMeterNamesAndIds?: any[]
  energyAllocator: FinancialSummaryEnergyAllocator
  meterCode: string
  meterId: number
  meterName: string
  meterType: string
  parentMeterVolume?: number
  searchEndDate: Moment
  searchStartDate: Moment
}

const fetchTotalUsage = (
  meterId,
  { timeRange: { start, end } }: { timeRange: IRange<Moment> }
) =>
  MeterHour.where({
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    meterId,
    hour: { gte: start.format(), lt: end.clone().endOf("day").format() },
    power_flow_type: { eq: "Delivered" },
  })
    .per(0)
    .stats({
      metered_kwh: "sum",
    })
    .all()
    .then(({ meta }) => ({
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      meteredKwh: Number.parseFloat(meta.stats.metered_kwh.sum),
    }))

export const fetchSiteProRatedUsageMeters = ({
  searchStartDate,
  searchEndDate,
  meterCode,
  meterName,
  meterType,
  meterId,
  energyAllocator,
  parentMeterVolume,
  childMeterNamesAndIds = [],
}: FetchSiteProRatedUsageMeterProps): Promise<FetchSiteProRatedUsageMeterValue> => {
  const emptyMeters: FetchSiteProRatedUsageMeterValue = {
    id: meterId,
    totalCost: "",
    totalUsage: "",
    meterCode,
    name: meterName,
    meterType,
    subMeters: [],
  }
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (energyAllocator === undefined) {
    return Promise.resolve(emptyMeters)
  }
  return fetchTotalUsage(meterId, {
    timeRange: { start: searchStartDate, end: searchEndDate },
  })
    .then(async ({ meteredKwh }) => {
      const childProRatedUsageData: FetchSiteProRatedUsageMeterValue[] =
        await Promise.all(
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          childMeterNamesAndIds?.map(
            async ({
              id,
              meter_name: childMeterName,
              meter_code: childMeterCode,
              meter_type: childMeterType,
            }) => ({
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              id,
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              name: childMeterName,
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              meterCode: childMeterCode,
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              meterType: childMeterType,
              ...(await fetchSiteProRatedUsageMeters({
                searchStartDate,
                searchEndDate,
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                meterCode: childMeterCode,
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                meterName: childMeterName,
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                meterType: childMeterType,
                meterId: Number(id),
                energyAllocator,
                parentMeterVolume: meteredKwh,
              }).catch((error: unknown) => {
                if (error instanceof Error) {
                  console.error(error)
                }
                return emptyMeters
              })),
            })
          )
        )
      const costRelativeVolume =
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/no-unnecessary-condition
        parentMeterVolume || Number(energyAllocator?.meteredKwh)
      const relativeConsumption = meteredKwh / costRelativeVolume
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const cost = Number(energyAllocator?.total)
      return {
        meterCode,
        name: meterName,
        meterType,
        subMeters: childProRatedUsageData,
        id: meterId,
        totalCost: String(
          Number.isFinite(cost * relativeConsumption)
            ? cost * relativeConsumption
            : ""
        ),
        totalUsage: String(Number.isFinite(meteredKwh) ? meteredKwh : ""),
      }
    })
    .catch((error: unknown) => {
      if (error instanceof Error) {
        console.error(error)
      }
      return emptyMeters
    })
}

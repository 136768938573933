import React from "react"
import { useTranslation } from "react-i18next"

import { area, curveLinear, line, select } from "d3"
import type { Moment } from "moment"
import moment from "moment"

import Price from "../models/price"
import { UnitName } from "../models/unit"
import { dayAheadPrice } from "../utils"
import { getHourEnding } from "../utils/date"
import { transformPricePerWhIntoUnit } from "../utils/formatters"
import { getKeyByValue } from "../utils/object"
import { DateDisplay } from "./date/dateDisplay/dateDisplay"
import { If } from "./if/if"
import { PriceDisplay } from "./priceDisplay"
import { Unit } from "./unit/unit"

const DrawDayAheadCostGraphic = ({ prices }: { prices: Price[] }) => {
  if (prices.length === 0) {
    return
  }

  const HEIGHT = 50
  const WIDTH = 110
  const BUFFER = 5
  const widthModifier = WIDTH / prices.length

  const maxPrice: number = prices
    .map((price) => price.lmp)
    .reduce((acc, next) => Math.max(acc, next), 0)

  const heightModifier = HEIGHT / maxPrice

  const pricingTrendData: [number, number][] = prices.map((price, idx) => [
    idx * widthModifier,
    price.lmp * heightModifier,
  ])

  const svg = select(".day-ahead-pricing .graphic")
    .attr("height", HEIGHT + BUFFER)
    .attr("width", WIDTH)

  svg.selectAll("*").remove()

  const pricingTrendLine = line().curve(curveLinear)(pricingTrendData)

  // Draw the line
  svg.append("path").attr("class", "line").attr("d", pricingTrendLine)

  // Draw the circle indicating most recent price
  const startOfThisHour = moment().startOf("hour")
  const indexForThisHour = prices.findIndex((price) =>
    startOfThisHour.isSame(price.hour)
  )
  if (indexForThisHour > -1) {
    const thisHourPricePoint = pricingTrendData[indexForThisHour]

    // Draw the area
    svg
      .append("path")
      .attr("class", "area")
      .attr(
        "d",
        area().y0(HEIGHT)(pricingTrendData.slice(0, indexForThisHour + 1))
      )

    svg
      .append("circle")
      .attr("class", "circle")
      .attr("cx", thisHourPricePoint[0])
      .attr("cy", thisHourPricePoint[1])
      .attr("r", "4")
  }
}

export const DayAheadPricing = ({
  prices,
  whDisplayUnit,
}: {
  prices: Price[]
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const { t } = useTranslation()

  const titleWithDate = (dateTime: Moment) => (
    <div className="title-with-note">
      <h4 className="title-with-note__title">Day Ahead</h4>
      <If condition={dateTime}>
        <p className="title-with-note__note">
          <DateDisplay value={dateTime} />
        </p>
      </If>
    </div>
  )

  React.useEffect(() => {
    DrawDayAheadCostGraphic({ prices })
  }, [prices])

  const price: Price | null = dayAheadPrice(prices)
  const date: Moment | null = price ? moment(price.hour) : null
  const priceDate: Moment | null = date ? date.clone().add(1, "day") : null

  return (
    <div className="day-ahead-pricing">
      {titleWithDate(priceDate)}
      <PriceDisplay
        amount={transformPricePerWhIntoUnit(
          Number.parseFloat((price || new Price({ lmpMajor: "" })).lmpMajor),
          UnitName.MegawattHour,
          whDisplayUnit
        )}
      >
        <span>
          Day Ahead LMP (
          <Unit
            unit={
              UnitName[
                `CurrencyPer${getKeyByValue<string>(
                  UnitName,
                  whDisplayUnit
                )}` as keyof typeof UnitName
              ]
            }
          />
          )
        </span>
        <span className="price-display__label__right">
          HE: {priceDate && getHourEnding(date, t)}
        </span>
      </PriceDisplay>
    </div>
  )
}

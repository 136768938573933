// @ts-strict-ignore
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { useOrganizationContext } from "@/contexts"
import { FeatureFlags, useFeature } from "@/services/feature"
import { groupBy } from "lodash-es"

import type { GraphData } from "../../../../../../components/graph/graphData"
import { GraphFlyout } from "../../../../../../components/graphFlyout"
import { GraphLegend } from "../../../../../../components/graphLegend"
import { LoadingSpinner } from "../../../../../../components/loadingSpinner"
import type { Config } from "../../../../../../components/tooltip"
import { Tooltip, emptyConfig } from "../../../../../../components/tooltip"
import { Unit } from "../../../../../../components/unit/unit"
import useMeasure from "../../../../../../hooks/useMeasure/useMeasure"
import type EnergyAllocation from "../../../../../../models/energyAllocation"
import type ForecastDay from "../../../../../../models/forecastDay"
import type ForecastHour from "../../../../../../models/forecastHour"
import type { LanguageRegion } from "../../../../../../models/i18n"
import { UnitName } from "../../../../../../models/unit"
import type { EnergyUnit, TIME_RANGES } from "../../../../../../utils/constants"
import {
  GRAPH_COST_OR_CARBON_INTENSITY,
  GRAPH_DETAIL_MODAL_ORIENTATION,
} from "../../../../../../utils/constants"
import { EnergyProfileGraph } from "./energyProfileGraph/energyProfileGraph"
import {
  getGraphData,
  getMaxAvgCarbonIntensityDomain,
  getMaxAvgCostDomain,
  getMaxVolumeDomain,
  getWeather,
  selectDataGroupByTimeKey,
} from "./energyProfileUtils"

interface EnergyProfileProps {
  axisUnit: EnergyUnit
  energyAllocations: EnergyAllocation[]
  forecastDays: ForecastDay[]
  forecastHours: ForecastHour[]
  isLoading: boolean
  onHourSelect: (selectedBar: GraphData) => void
  timeRange: TIME_RANGES
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}

export const EnergyProfile = ({
  axisUnit,
  forecastDays,
  forecastHours,
  isLoading,
  onHourSelect,
  timeRange,
  wattUnit,
  energyAllocations,
}: EnergyProfileProps) => {
  const { ref: containerRef, width: pageWidth } = useMeasure()
  const [hoverXPos, setHoverXPos] = useState<number | null>(null)
  const [hoverYPos, setHoverYPos] = useState<number | null>(null)
  const [activeHour, setActiveHour] = useState<number | null>(null)
  const [graphDetailOrientation, setGraphDetailOrientation] =
    useState<GRAPH_DETAIL_MODAL_ORIENTATION>(
      GRAPH_DETAIL_MODAL_ORIENTATION.RIGHT
    )
  const [showMeterData, setShowMeterData] = useState(true)

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isEpgCostCarbonLineEnabled = isFeatureEnabled(
    FeatureFlags.EPG_COST_CARBON_LINE,
    organization
  )

  // create a state variable of enum of [COST, CARBON_INTENSITY, NONE]
  const [showCostOrCarbonIntensity, setShowCostOrCarbonIntensity] =
    useState<GRAPH_COST_OR_CARBON_INTENSITY>(
      isEpgCostCarbonLineEnabled
        ? GRAPH_COST_OR_CARBON_INTENSITY.NONE
        : GRAPH_COST_OR_CARBON_INTENSITY.COST
    )

  const tooltipConfig: Config = useMemo(() => emptyConfig, [])
  const { i18n: i18nService } = useTranslation()

  const graphData = useMemo(
    () =>
      getGraphData({
        timeRange,
        energyAllocations,
        i18nService,
      }),
    [timeRange, energyAllocations, i18nService]
  )

  const graphDataGroups: GraphData[][] = useMemo(
    () =>
      Object.values(
        groupBy(graphData, selectDataGroupByTimeKey(timeRange))
      ).sort((a, b) => a[0].hour.unix() - b[0].hour.unix()),
    [graphData, timeRange]
  )

  const maxVolumeDomain = getMaxVolumeDomain(graphData)

  const maxAvgCostDomain = getMaxAvgCostDomain(graphData)
  const maxAvgCarbonIntensityDomain = getMaxAvgCarbonIntensityDomain(graphData)

  const handleShowCostOrCarbonIntensityChange = (
    value: GRAPH_COST_OR_CARBON_INTENSITY
  ) => {
    setShowCostOrCarbonIntensity(value)
  }

  const handleHover = ({
    xPos,
    yPos,
    orientation,
    timestamp,
  }: {
    orientation: GRAPH_DETAIL_MODAL_ORIENTATION
    timestamp: number
    xPos: number | null
    yPos: number
  }) => {
    setHoverXPos(xPos)
    setHoverYPos(yPos)
    setActiveHour(timestamp)
    setGraphDetailOrientation(orientation)
  }

  const headers = [
    <Unit key="usageHeader" unit={wattUnit} />,
    <Unit key="costHeader" unit={UnitName.CurrencyPerKilowattHour} />,
    <>Cost</>,
  ]

  return (
    <div>
      <div ref={containerRef} className="dashboard-data__visualization">
        {isLoading && (
          <LoadingSpinner className="dashboard-data__loading">
            Loading graph...
          </LoadingSpinner>
        )}
        <EnergyProfileGraph
          axisUnit={axisUnit}
          energyAllocations={energyAllocations}
          graphDataGroups={graphDataGroups}
          isFlyoutActive={!!hoverXPos}
          isLoading={isLoading}
          maxAvgCarbonIntensityDomain={maxAvgCarbonIntensityDomain}
          maxAvgCostDomain={maxAvgCostDomain}
          maxVolumeDomain={maxVolumeDomain}
          onHourSelect={onHourSelect}
          onHover={handleHover}
          pageWidth={pageWidth}
          showCostOrCarbonIntensity={showCostOrCarbonIntensity}
          showMeterData={showMeterData}
          timeRange={timeRange}
          wattUnit={wattUnit}
        />
        {tooltipConfig.content.length > 0 && <Tooltip {...tooltipConfig} />}
        {graphData
          .filter((graphDatum) => graphDatum.hour.unix() === activeHour)
          .map((graphDatum, idx) => (
            <GraphFlyout
              // migrate directories to strict mode
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              graphDatum={graphDatum}
              headers={headers}
              orientation={graphDetailOrientation}
              wattUnit={wattUnit}
              weather={getWeather({
                activeHour,
                forecastDays,
                forecastHours,
                timeRange,
                languageRegion: i18nService.language as LanguageRegion,
              })}
              x={hoverXPos}
              y={hoverYPos}
            />
          ))}
        <GraphLegend
          graphIsLoading={isLoading}
          showCostOrCarbonIntensityLine={showCostOrCarbonIntensity}
          showMeterData={showMeterData}
          toggleShowCostOrCarbonIntensityLine={
            handleShowCostOrCarbonIntensityChange
          }
          toggleShowMeterData={() => {
            setShowMeterData(!showMeterData)
          }}
          wattUnit={wattUnit}
        />
      </div>
    </div>
  )
}

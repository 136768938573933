import React from "react"
import type { FC, ReactNode } from "react"

import { useOrganizationContext } from "@/contexts"
import type { FeatureFlags } from "@/services/feature"
import { useFeature } from "@/services/feature"

import Page404 from "../../nav/page404/page404"

interface FeatureGuardProps {
  children: ReactNode
  /**
   * If the feature is enabled the children will be rendered unless invert is true
   */
  feature: FeatureFlags
  /**
   * If true the children will be rendered when the feature is off and a 404 will be rendered when the feature is on
   */
  invert?: boolean
}

/**
 * @description A guard that displays or hides the feature
 */
export const FeatureGuard: FC<FeatureGuardProps> = ({
  children,
  feature,
  invert,
}) => {
  const { organization } = useOrganizationContext()
  const { isFeatureEnabled } = useFeature()

  if (!organization) {
    return <></>
  }

  const isEnabled = isFeatureEnabled(feature, organization)

  const isAllowed = invert ? !isEnabled : isEnabled

  if (isAllowed) {
    return children
  }

  return <Page404 />
}

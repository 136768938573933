import { axisBottom, scaleBand, select } from "d3"
import { concat, identity, map, property, times } from "lodash-es"
import type moment from "moment"

import { transparent } from "../../utils/colors"
import { TIME_RANGES } from "../../utils/constants"
import { calculateFillTarget } from "../../utils/graph"

export const constructXAxis = ({
  graphData,
  groupSize,
  minWidth,
  maxWidth,
}) => {
  const xDomain = concat(
    // Mass lint disable
    // Mass eslint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    graphData.map((datum) => datum.hour.unix()),
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    times(calculateFillTarget(groupSize) - graphData.length, (ident) =>
      identity(ident)
    )
  )

  return { x: scaleBand().range([minWidth, maxWidth]).domain(xDomain) }
}

export const renderXAxis = ({
  height,
  graphKey,
  minWidth,
  maxWidth,
  groupSize,
  timeRange,
  graphData,
}) => {
  /*
   * A note on tick values:
   * d3 scaling functions don't like redundant domain values as they are
   * used as dictionary keys internally.
   * Duplicate hours happen after DST ends, so we must support redundant hours.
   * To deal with this, we manually implement tick labels.
   */

  const { x } = constructXAxis({
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    graphData,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    minWidth,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    maxWidth,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    groupSize,
  })

  const xAxis = axisBottom(x).tickSize(0) // zero padding
  const barLabels = map(graphData, property("barLabel"))
  const barColors = (timestamp: number) => {
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const bar = graphData.find(
      (datum: { hour: moment.Moment }) => datum.hour.unix() === timestamp
    )
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    return bar?.barLabelColor ? bar.barLabelColor : transparent
  }

  const svg = select(".dashboard-data__graph")
  svg.selectAll(`g.xAxis${graphKey}`).remove()

  svg
    .append("g")
    .attr("transform", `translate(0, ${height})`)
    .attr("class", `xAxis${graphKey}`)
    .call(xAxis)
    .call((g) =>
      g
        .selectAll(".tick text")
        .each((_textContent, i, $elements) => {
          const textElement = $elements[i] as SVGTextElement

          textElement.textContent = barLabels[i] as string

          return textElement
        })
        .attr("dy", timeRange === TIME_RANGES.DAY ? "9" : "15")
        .attr("dx", timeRange === TIME_RANGES.DAY ? "-10" : "-15")
        .attr("class", (timestamp: string) => `he-${timestamp}`)
        .attr("fill", barColors)
        .style("font-size", "12px")
        .style("font-weight", "500")
        .style(
          "transform",
          timeRange === TIME_RANGES.DAY ? "rotate(-45deg)" : "rotate(-45deg)"
        )
    )

  return { x }
}

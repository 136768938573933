import React from "react"

import { Paper, Skeleton, Stack, Typography } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../models/typography"
import { lightGray } from "../../../utils/colors"
import { pxToRem } from "../../../utils/unit"
import { If } from "../../if/if"

interface OrganizationDashboardItemProps {
  content: JSX.Element
  contentSx?: Record<string, unknown>
  footer?: JSX.Element
  isLoading: boolean
  minHeight: number | string
  title?: string
  tooltip?: JSX.Element
}

const OrganizationDashboardItem = ({
  isLoading,
  minHeight,
  content,
  contentSx,
  title,
  tooltip,
  footer,
}: OrganizationDashboardItemProps): JSX.Element => (
  <Paper sx={{ minHeight, overflow: "hidden " }}>
    <If condition={isLoading}>
      <Skeleton height={minHeight} variant="rectangular" />
    </If>
    <If condition={!isLoading}>
      <If condition={title}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            borderBottom: `1px solid ${lightGray.toString()}`,
            p: `${pxToRem(8)} ${pxToRem(24)}`,
          }}
        >
          <Typography sx={{ m: 0 }} variant={TYPOGRAPHY_VARIANT.h2}>
            {title}
          </Typography>
          <If condition={tooltip}>{tooltip}</If>
        </Stack>
      </If>
      <Stack
        sx={{ flexGrow: 1, p: `${pxToRem(16)} ${pxToRem(24)}`, ...contentSx }}
      >
        {content}
      </Stack>
      <If condition={footer}>
        <Stack
          alignSelf="flex-end"
          justifyContent="end"
          sx={{ p: `0 ${pxToRem(24)} ${pxToRem(20)}` }}
        >
          {footer}
        </Stack>
      </If>
    </If>
  </Paper>
)

export default OrganizationDashboardItem

import moment from "moment"
import type { Moment } from "moment"
import qs from "qs"

const sortAlphabetically = (a: string, b: string) => {
  return a.localeCompare(b)
}

interface BuildUrlSearchParamsStringOptions {
  dateFormat: "YYYY" | "YYYY-MM-DD"
}

/**
 * Builds a URL search params string
 * @param newParams - The new params to add or update
 * @param currentParams - The current URL search params
 * @param options.dateFormat - The date format to use for any params that are dates
 * @returns The URL search params string
 * @example
 * buildUrlSearchParamsString(
 *  {
 *    year: moment(),
 *  },
 *  searchParams,
 *  {
 *    dateFormat: "YYYY",
 *  })
 */
export const buildUrlSearchParamsString = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newParams: Record<string, any>,
  currentParams: URLSearchParams,
  { dateFormat }: BuildUrlSearchParamsStringOptions = {
    dateFormat: "YYYY-MM-DD",
  }
) =>
  qs.stringify(
    {
      ...Object.fromEntries(currentParams.entries()),
      ...newParams,
    },
    {
      filter: (prefix, value): string => {
        if (value instanceof moment) {
          return (value as Moment).format(dateFormat)
        }

        return value as string
      },
      sort: sortAlphabetically,
    }
  )

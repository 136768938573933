import createClient from "openapi-fetch"

import createFetchClient from "./fetch-client/client"
import type { paths } from "./generated/schema"

// Must be a function instead of a static variable for msw v2 compatibility
// because it needs to be set up after msw overwrites fetch
// See https://github.com/openapi-ts/openapi-typescript/issues/1878
export const client = () =>
  createClient<paths>({
    baseUrl: process.env.REACT_APP_API_BASE_URL ?? "",
    bodySerializer: (body) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      JSON.stringify(body, (k, v) => (v === undefined ? null : v)),
  })

export const fetchClient = createFetchClient({
  baseUrl: process.env.REACT_APP_API_BASE_URL ?? "",
})

import { useCallback } from "react"

import { ApiQueryName } from "@/models/api"
import type { Organization } from "@/models/organization"
import { httpClient } from "@/utils/api"
import { useQuery } from "@tanstack/react-query"

import type { Organization as ServiceOrganization } from "../organization"
import type {
  Feature,
  FeatureActorType,
  FeatureFlags,
} from "./useFeature.types"

interface FetchFeaturesResponse {
  features: Feature[]
}

const fetchFeatures = async (): Promise<Feature[]> => {
  try {
    const response = await httpClient.get<FetchFeaturesResponse>(
      "/flipper/api/features"
    )

    return response.data.features
  } catch {
    console.error("Error getting features")
    return []
  }
}

interface UseFeatureValue {
  features: Feature[]

  /**
   * This function determines the availability of a feature based on the feature's name and the organization. Pass null for
   * the organization if the feature flag is not conditional.
   *
   * @param feature - The name of the feature to check
   * @param organization - The organization for which to check the feature availability, or null if the feature flag is not conditional per organization
   * @returns A boolean indicating if the feature is enabled
   *
   * @example
   * const isFeatureAvailable = isFeatureEnabled("featureName", organization)
   * const isFeatureAvailable = isFeatureEnabled("featureName", null)
   *
   */
  isFeatureEnabled: (
    feature: FeatureFlags,
    organization: Organization | ServiceOrganization | null
  ) => boolean

  /**
   * A boolean indicating if the features have been fetched
   */
  isFeaturesFetched: boolean

  /**
   * A boolean indicating if the features are loading
   */
  isFeaturesLoading: boolean
}

const isActorInFeature = (
  type: FeatureActorType,
  id: string | undefined,
  feature: Feature
): boolean => {
  const actors: string[] =
    feature?.gates?.find((gate) => gate.key === "actors")?.value ?? []

  return !!actors.find(
    (actor) => actor.toLowerCase() === `${type};${id}`.toLowerCase()
  )
}

const isFeatureEnabledForOrg = (
  feature: Feature,
  orgId: string | undefined
): boolean => isActorInFeature("Organization", orgId, feature)

/**
 * The useFeature service/hook returns a set of properties and functions to manage features
 *
 * @returns The properties and functions
 * @example
 * const {
 *   isFeatureEnabled,
 *   isFeaturesFetched,
 *   isFeaturesLoading
 * } = useFeature()
 */
export const useFeature = (): UseFeatureValue => {
  const {
    data: features,
    isFetched: isFeaturesFetched,
    isLoading: isFeaturesLoading,
  } = useQuery<Feature[]>({
    queryFn: fetchFeatures,
    queryKey: [ApiQueryName.Feature],
  })

  const isFeatureEnabled = useCallback(
    (
      featureName: FeatureFlags,
      organization: Organization | ServiceOrganization | null
    ): boolean => {
      if (isFeaturesFetched) {
        const feature: Feature | undefined = features?.find(
          (feat) => feat.key === (featureName as string)
        )

        if (feature?.state === "conditional") {
          // Currently conditional feature flags are only managed by org id
          // Should use a tool like Launch Darkly before building more complex conditional handling
          return isFeatureEnabledForOrg(feature, organization?.id)
        }

        return feature?.state === "on"
      }

      return false
    },
    [features, isFeaturesFetched]
  )

  return {
    isFeatureEnabled,
    isFeaturesFetched,
    isFeaturesLoading,
    features,
  }
}

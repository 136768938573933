import { useMemo } from "react"
import { useLocation } from "react-router-dom"

import { useOrganizationContext } from "@/contexts"
import { useOrganization } from "@/services"
import { FeatureFlags, useFeature } from "@/services/feature"

/**
 * Determines whether the flexible hierarchy feature flag is enabled for an organization.
 * @returns An object with the following properties:
 * - isLoading: A boolean indicating whether the organization is currently being loaded.
 * - isFlexibleHierarchyEnabled: Indicates whether flex hierarchy is enabled for the organization.
 * @remarks This hook should be removed when the feature flag is no longer needed.
 */
export const useIsFlexibleHierarchyEnabled = () => {
  const { organization: activeOrganization } = useOrganizationContext()
  // We get the currently selected organization from the URL because
  // we must know if the organization is using Flexible Hierarchy before
  // we construct the routes.
  const location = useLocation()
  // This approach is fragile, rework when it becomes a priority
  // /tam-admin/onboarding/:orgId
  const orgId = location.pathname.split("/")[3] ?? activeOrganization.id

  const { organization, isOrganizationLoading } = useOrganization(orgId)
  const { isFeatureEnabled } = useFeature()

  const isFlexibleHierarchyEnabled = useMemo(
    () => isFeatureEnabled(FeatureFlags.OAT_FLEXIBLE_HIERARCHY, organization),
    [isFeatureEnabled, organization]
  )

  return { isLoading: isOrganizationLoading, isFlexibleHierarchyEnabled }
}

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import type { Context, ReactNode } from "react"
import { useTranslation } from "react-i18next"

import type { Organization } from "@/models/organization"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useUserState } from "@/utils/use-user-state"

interface OrganizationContextValue {
  organization: Organization | null | undefined
  setOrganization: (organization: Organization | null) => void
}

interface OrganizationProviderProps extends Partial<OrganizationContextValue> {
  children: ReactNode
}

const OrganizationContext: Context<OrganizationContextValue> = createContext({
  organization: undefined,
  setOrganization: undefined,
})

/**
 * Provides the organization context
 *
 * @example
 * return (
 *   <OrganizationProvider>
 *     <Component />
 *   </OrganizationProvider>
 * )
 */
export const OrganizationProvider = (props: OrganizationProviderProps) => {
  const { i18n } = useTranslation()
  const { isFeatureEnabled } = useFeature()
  const { saveActiveOrganization } = useUserState()

  const [organization, setOrganization] = useState<
    Organization | null | undefined
  >(props.organization)

  // Public organization setter to handle additional behavior
  const setOrganizationPublic = useCallback(
    (org: Organization | null): void => {
      if (
        !isFeatureEnabled(FeatureFlags.SETTINGS_LANGUAGE_SELECTOR, organization)
      ) {
        if (org?.locale?.name) {
          i18n.changeLanguage(org.locale.name).catch((error: unknown) => {
            if (error instanceof Error) {
              console.error(error)
            }
          })
        }
      }

      setOrganization(org)
      saveActiveOrganization(org?.id)
    },
    [i18n, isFeatureEnabled, organization, saveActiveOrganization]
  )

  const value: OrganizationContextValue = useMemo(
    () => ({
      organization,
      setOrganization: setOrganizationPublic,
    }),
    [organization, setOrganizationPublic]
  )

  return (
    <OrganizationContext.Provider value={value}>
      {props.children}
    </OrganizationContext.Provider>
  )
}

/**
 * Provides the active organization getter and setter
 *
 * @example
 * const { organization, setOrganization } = useOrganizationContext()
 */
export const useOrganizationContext = () => useContext(OrganizationContext)

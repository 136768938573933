export enum ApiQueryName {
  ActivityEmissionsReport = "activityEmissionsReport",
  AssistantInit = "initAssistant",
  AssistantThreads = "assistantThreads",
  Authentication = "authentication",
  AuthorizedOrganizations = "authorizedOrganizations",
  AvailableReportDates = "availableReportDates",
  BaCodes = "baCodes",
  BillingGroup = "billingGroup",
  BillingGroupCounts = "billingGroupCounts",
  BillingGroups = "billingGroups",
  BuildingTypes = "buildingTypes",
  BundledEmissionsAndEnergyVolume = "bundledEmissionsAndEnergyVolume",
  ComstockPredict = "comstockPredict",
  Currencies = "currencies",
  Data = "data",
  Department = "department",
  DepartmentCounts = "departmentCounts",
  Departments = "departments",
  DirectEmissionsAndEnergyVolume = "directEmissionsAndEnergyVolume",
  Eia = "eia",
  ElectricityCost = "electricityCost",
  ElectricityMeter = "electricityMeter",
  EmissionProfile = "emissionProfile",
  EmissionProfiles = "emissionProfiles",
  EmissionsDateRange = "emissionsDateRange",
  EmissionsStats = "emissionsStats",
  EnergyAllocators = "energyAllocators",
  EnergyConsumption = "energyConsumption",
  EnergyStats = "energyStats",
  Feature = "feature",
  Features = "features",
  ForecastDays = "forecastDays",
  ForecastHours = "forecastHours",
  GresbReport = "gresbReport",
  GriReport = "griReport",
  Gtag = "gtag",
  IsSessionValid = "isSessionValid",
  Locales = "locales",
  Locations = "locations",
  Meter = "meter",
  MeterCounts = "meterCounts",
  MeterSourceTypes = "meterSourceTypes",
  Meters = "meters",
  MinMaxMeteredCostRange = "minMaxMeteredCostRange",
  MonthSummaries = "monthSummaries",
  MonthSummariesMinMaxDates = "monthSummariesMinMaxDates",
  MonthlyElectricityDateRange = "monthlyElectricityDateRange",
  MonthlySiteData = "monthlySiteData",
  NaturalGasAvailableMonths = "naturalGasAvailableMonths",
  NaturalGasDateRange = "naturalGasDateRange",
  Nodes = "nodes",
  NonMonthlyElectricityDateRange = "nonMonthlyElectricityDateRange",
  Organization = "organization",
  OrganizationCounts = "organizationCounts",
  OrganizationHierarchy = "organizationHierarchy",
  OrganizationSummaryStats = "organizationSummaryStats",
  OrganizationalUnit = "organizationalUnit",
  OrganizationalUnits = "organizationalUnits",
  Organizations = "organizations",
  Pendo = "pendo",
  PhysicalDetailTypes = "physicalDetailTypes",
  RecommendationSite = "recommendationSite",
  RecommendationsGoals = "recommendationsGoals",
  RecommendationsPlans = "recommendationsPlans",
  RecommendationsPotentialSites = "recommendationsPotentialSites",
  RecommendationsSite = "recommendationsSite",
  RecommendationsSiteByInterventions = "recommendationsSiteByInterventions",
  RepBuildingCounts = "repBuildingCounts",
  ScopeOneEmissions = "scopeOneEmissions",
  ScopeThreeEmissions = "scopeThreeEmissions",
  ScopeTwoEmissions = "scopeTwoEmissions",
  ServiceHourMinMaxDates = "serviceHourMinMaxDates",
  SigmaAccessToken = "sigmaAccessToken",
  SigmaRefreshAccessToken = "sigmaRefreshAccessToken",
  SigmaTeams = "sigmaTeams",
  SigmaWhoAmI = "sigmaWhoAmI",
  SigmaWorkbook = "sigmaWorkbook",
  SigmaWorkbookEmbeds = "sigmaWorkbookEmbeds",
  SigmaWorkbookSecureEmbedUrl = "sigmaWorkbookSecureEmbedUrl",
  SigmaWorkbooks = "sigmaWorkbooks",
  Sinks = "sinks",
  Site = "site",
  SiteCounts = "siteCounts",
  SiteCustomVariables = "siteCustomVariables",
  SiteDailyNaturalGasEmissions = "siteDailyNaturalGasEmissions",
  SiteEnergyAllocation = "siteEnergyAllocation",
  SiteExplorer = "siteExplorer",
  SiteExtraAttributes = "siteExtraAttributes",
  SiteFinancialSummaries = "siteFinancialSummaries",
  SiteLoadAggregationPoint = "siteLoadAggregationPoint",
  SiteLoadAggregationPointPrices = "siteLoadAggregationPointPrices",
  SitePlans = "sitePlans",
  SitePreviews = "sitePreviews",
  SiteProRatedUsageMeters = "siteProRatedUsageMeters",
  SiteTotals = "siteTotals",
  SiteTotalsByOrgUnit = "siteTotalsByOrgUnit",
  SiteUpdate = "siteUpdate",
  SiteYTDMeteredKwhs = "siteYTDMeteredKwhs",
  Sites = "sites",
  UploadEmissions = "uploadEmissions",
  UploadEntities = "uploadEntities",
  UploadUsage = "uploadUsage",
  UserCreate = "userCreate",
  UtilityCredentials = "utilityCredentials",
  UtilityCredentialsSignUp = "utilityCredentialsSignUp",
  YtdMeteredKwh = "ytdMeteredKwh",
}

export default {
  ApiQueryName,
}

import React from "react"

import Color from "color"
import type { ScaleBand, SeriesPoint } from "d3"
import {
  selectAll,
  stack,
  stackOffsetNone,
  stackOrderNone,
  transpose,
} from "d3"
import { clamp, filter, isEqual, last, sortBy } from "lodash-es"

import { useTheme } from "@mui/material"

import { colorDarken } from "../../utils/colors"
import {
  CENTS_PER_DOLLAR,
  GRAPH_COST_OR_CARBON_INTENSITY,
  GRAPH_DETAIL_MODAL_ORIENTATION,
  KWH_PER_MWH,
  TIME_RANGES,
} from "../../utils/constants"
import { AvgCarbonIntensityLine } from "./avgCarbonIntensityLine"
import { AvgCostLine } from "./avgCostLine"
import { CurtailmentIcon } from "./curtailmentIcon"
import type { GraphData } from "./graphData"
import { GroupLabel } from "./groupLabel"
import { MeterLine } from "./meterLine"
import Product, { ProductType } from "./product"
import { renderXAxis } from "./xAxis"

export type EnergyChartBarData = IEnergyChartBarData &
  SeriesPoint<Record<string, number>>

interface IEnergyChartBarData {
  0: number
  1: number
  data: ProductData
  offset?: number
  offsetY?: number
  product?: Product
}

export interface ProductData {
  [key: string]: number | moment.Moment | Product[] | Product
  hour: moment.Moment
  offset?: number
  product?: Product
  products: Product[]
}

const getMarginX = (timeRange: TIME_RANGES, baseWidth: number): number => {
  switch (timeRange) {
    case TIME_RANGES.DAY:
      return 0
    case TIME_RANGES.YEAR:
      return baseWidth / (30 * 12)
    default:
      return baseWidth / (30 * 12)
  }
}

const generateBarValues = (maxDomainValue, graphData) => {
  const barValues = []

  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  graphData.forEach(
    ({
      hour,
      products,
      lineValue,
    }: {
      hour: moment.Moment
      lineValue: number
      products: Product[]
    }) => {
      const barValuesForHour = { hour, products, lineValue }
      const curtailedProduct = products.find(
        (product) => product.id === "curtailment"
      ) || {
        kwh: 0,
      }
      const minYThreshold = maxDomainValue * 0.05
      const lastProduct: Product = last(products)
      const curtailedOffset = Math.max(minYThreshold - curtailedProduct.kwh, 0)
      products.forEach((product) => {
        if (product.kwh === 0) {
          barValuesForHour[product.id] = 0
        } else if (product.id === "curtailment") {
          barValuesForHour[product.id] = Math.max(product.kwh, minYThreshold)
        } else if (
          "confirmId" in curtailedProduct &&
          curtailedProduct.confirmId &&
          product.confirmId === curtailedProduct.confirmId
        ) {
          barValuesForHour[product.id] = Math.abs(product.kwh - curtailedOffset)
        } else if (curtailedProduct.kwh > 0 && lastProduct.id === product.id) {
          barValuesForHour[product.id] = Math.abs(product.kwh - curtailedOffset)
        } else {
          barValuesForHour[product.id] = Math.abs(product.kwh)
        }
      })

      barValues.push(barValuesForHour)
    }
  )

  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return barValues
}

const MODAL_FLIP_HEDGE = 650

export const BarGraph = ({
  baseWidth,
  graphDataGroup,
  outerPadding,
  middlePadding,
  graphWidth,
  groupSize,
  height,
  maxVolumeDomain,
  onHover,
  onClick,
  timeRange,
  primaryYLineScale,
  secondaryYLineScale,
  idx,
  showMeterData,
  showCostOrCarbonIntensity,
  isFlyoutActive,
}: {
  baseWidth
  graphDataGroup: GraphData[]
  graphWidth
  groupSize
  height
  idx
  isFlyoutActive
  maxVolumeDomain
  middlePadding
  onClick
  onHover
  outerPadding
  primaryYLineScale
  secondaryYLineScale
  showCostOrCarbonIntensity: GRAPH_COST_OR_CARBON_INTENSITY
  showMeterData
  timeRange: TIME_RANGES
}) => {
  const theme = useTheme()
  // migration to strict mode batch disable
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const [xAxis, setXAxis] = React.useState<any>(null)
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [hoverTimestamp, setHoverTimestamp] = React.useState(null)
  const graphKey = `day-${graphDataGroup[0].hour.unix().toString()}`
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const marginX: number = getMarginX(timeRange, baseWidth)
  const graphData: GraphData[] = graphDataGroup
  const products: Product[][] = graphData.map((datum) => datum.products)
  const stackNames = Product.sortedIds(products)
  const paddingX = 5
  const paddingY = 1
  const strokeWidth = 2

  const handleHover = (
    elem,
    d: EnergyChartBarData,
    x: ScaleBand<string>
  ): void => {
    const graphBoundingRect = document
      .querySelector("svg.dashboard-data__graph")
      .getBoundingClientRect()
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const productBoundingRect = elem.parentElement.getBoundingClientRect()
    const timestamp = d.data.hour.unix()

    // Right align by default
    let orientation = GRAPH_DETAIL_MODAL_ORIENTATION.RIGHT

    const yPos = 220
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    let xPos = productBoundingRect.left - graphBoundingRect.left + x.bandwidth()

    const shouldFlipModal =
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      productBoundingRect.left + x.bandwidth() + MODAL_FLIP_HEDGE >=
      window.innerWidth
    if (shouldFlipModal) {
      orientation = GRAPH_DETAIL_MODAL_ORIENTATION.LEFT
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      xPos = graphBoundingRect.right - productBoundingRect.right + x.bandwidth()
    }

    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    onHover({ orientation, xPos, yPos, timestamp })
    setHoverTimestamp(timestamp)
  }

  const handleHoverOut = (): void => {
    setHoverTimestamp(null)
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    onHover({ orientation: null, xPos: null, yPos: null, timestamp: null })
  }

  const handleClick = (productIndex: number): void => {
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    onClick(graphData[productIndex])
  }

  const transposedDataset = transpose(
    stack().keys(stackNames).order(stackOrderNone).offset(stackOffsetNone)(
      generateBarValues(maxVolumeDomain, graphData)
    )
  )

  const receivedAndDeliveredHour = (d: EnergyChartBarData): boolean =>
    Object.keys(d.data)
      .filter((dataKey) => !["hour", "products", "lineValue"].includes(dataKey)) // Keys just for products
      .filter((dataKey) => d.data[dataKey] !== 0).length > 1 // Rejecting any without values

  const shouldOverlapPreviousBar = (d: EnergyChartBarData): boolean =>
    d.product.productType === ProductType.ReceivedBundled &&
    receivedAndDeliveredHour(d)

  const avgCost = (productData): number => {
    // migration to strict mode batch disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const kwh = productData[0].data.lineValue
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const costCents = productData.reduce(
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      (sum, datum) => sum + datum.product.cost,
      0
    )
    return costCents / CENTS_PER_DOLLAR / (kwh / KWH_PER_MWH)
  }

  const avgCarbonIntensity = (productData): number => {
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unused-vars
    const kwh = productData[0].data.lineValue
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const totalCarbon = productData.reduce(
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      (sum, datum) => sum + datum.product.co2eLbs,
      0
    )

    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const totalKwh = productData.reduce(
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      (sum, datum) => sum + datum.product.kwh,
      0
    )

    return totalCarbon / totalKwh
  }

  const isProductTypeReceivedBundledOrLongImbalance = (
    type: ProductType
  ): boolean =>
    type === ProductType.ReceivedBundled || type === ProductType.LongImbalance

  // Bar group
  const isBarActive = (
    d: EnergyChartBarData,
    activeUnixTimestamp: number,
    hasActiveBar: boolean
  ): boolean => hasActiveBar && d.data.hour.unix() !== activeUnixTimestamp

  // Background bar
  const getBackgroundBarX = (
    barX: number,
    padding: number,
    margin: number
  ): number => barX - padding / 2 - margin / 2

  // Bar
  const getBarFill = (
    d: EnergyChartBarData,
    activeUnixTimestamp: number,
    hasActiveBar: boolean
  ): string => {
    if (isBarActive(d, activeUnixTimestamp, hasActiveBar)) {
      return colorDarken(d.product.strokeColor()).toString()
    }

    return d.product.strokeColor().toString()
  }

  const getBarHeight = (d: EnergyChartBarData, scaler, chartHeight): number => {
    // migration to strict mode batch disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const y0: number = scaler(d[0])
    // migration to strict mode batch disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const y1: number = d[1] > 0 ? scaler(d[1]) : 0

    const barHeight: number = Math.abs(y0 - y1)
    const minHeight = 0

    // Ensure the bar is not larger than the chart
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const clampedBarHeight: number = clamp(barHeight, minHeight, chartHeight)

    return clampedBarHeight
  }

  const getBarOffsetY = (
    currentProduct: Product,
    previousProduct: Product | undefined
  ): number => {
    const defaultOffset = 0
    const sameProductOffset = 1
    const currentProductIsSameAsPreviousProduct: boolean =
      currentProduct.displayName === previousProduct?.displayName

    if (!previousProduct) {
      return defaultOffset
    }

    if (currentProductIsSameAsPreviousProduct) {
      return sameProductOffset
    }

    return defaultOffset
  }

  const getBarOpacity = (
    d: EnergyChartBarData,
    activeUnixTimestamp: number,
    hasActiveBar: boolean
  ): number => (isBarActive(d, activeUnixTimestamp, hasActiveBar) ? 0.6 : 1)

  const getBarWidth = (
    initialWidth: number,
    padding: number,
    margin: number
  ): number => initialWidth - (padding + margin)

  const getBarX = (initialX: number, padding: number, margin: number): number =>
    initialX + padding / 2 + margin / 2

  const getBarY = (d: EnergyChartBarData, scaler): number => {
    const y: number = shouldOverlapPreviousBar(d) ? d[0] : d[1]

    // Subtract 0.5 to align bars with x-axis line
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return scaler(y) - d.offsetY - 0.5
  }

  // Inner bar
  const getInnerBarFill = (
    d: EnergyChartBarData,
    activeUnixTimestamp: number,
    hasActiveBar: boolean
  ): string => {
    const color: Color = isProductTypeReceivedBundledOrLongImbalance(
      d.product.productType
    )
      ? Color("rgba(255, 255, 255, 1)")
      : d.product.fillColor()

    if (isBarActive(d, activeUnixTimestamp, hasActiveBar)) {
      return colorDarken(color).toString()
    }

    return color.toString()
  }

  const getInnerBarHeight = (
    barHeight: number,
    barStrokeWidth: number
  ): number => {
    const totalStrokeWidth = barStrokeWidth * 2

    return Math.max(barHeight - totalStrokeWidth, 0)
  }

  const getInnerBarWidth = (
    initialWidth: number,
    padding: number,
    margin: number,
    barStrokeWidth: number
  ): number => {
    const totalStrokeWidth = barStrokeWidth * 2

    return getBarWidth(initialWidth, padding, margin) - totalStrokeWidth
  }

  const getInnerBarX = (barX: number, barStrokeWidth: number): number =>
    barX + barStrokeWidth

  const getInnerBarY = (
    barY: number,
    barHeight: number,
    barStrokeWidth: number
  ): number => {
    const totalStrokeWidth = barStrokeWidth * 2

    return barHeight >= totalStrokeWidth ? barY + barStrokeWidth : barY
  }

  // Clean up and add data for final rendering
  const dataset: EnergyChartBarData[][] = transposedDataset.map(
    (transposedProductData) => {
      // The total offset of a bar from it's original position
      // Includes offsets of previous bars in the stack
      let offsetYTotal = 0

      return (
        transposedProductData
          // Remove bars with invalid positions
          .filter(
            (datum: EnergyChartBarData) =>
              !Number.isNaN(datum[0]) && !Number.isNaN(datum[1])
          )
          // Add product
          // Done in a separate immutable operation so data can be used downstream
          .map((datum: EnergyChartBarData, i: number): EnergyChartBarData => {
            const currentProduct: Product = Product.sort(datum.data.products)[i]

            return {
              ...datum,
              product: currentProduct,
            }
          })
          // Add offsetY
          .map(
            (
              datum: EnergyChartBarData,
              i: number,
              datumCollection: EnergyChartBarData[]
            ): EnergyChartBarData => {
              const previousProduct: Product | undefined =
                datumCollection[i - 1]?.product
              const offsetYFromPrevious: number = getBarOffsetY(
                datum.product,
                previousProduct
              )

              offsetYTotal += offsetYFromPrevious

              return {
                ...datum,
                offset: offsetYFromPrevious,
                offsetY: offsetYTotal,
              }
            }
          )
      )
    }
  )

  const secondGraph: boolean = idx === 1
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let groupOffset: number = graphWidth * idx + outerPadding
  if (secondGraph) {
    groupOffset += middlePadding
  }

  React.useEffect(() => {
    let startWidth: number = graphWidth * idx
    if (secondGraph) {
      startWidth += middlePadding
    }

    const renderedXAxis = renderXAxis({
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      height,
      graphKey,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      minWidth: startWidth + outerPadding,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      maxWidth: startWidth + outerPadding + graphWidth,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      groupSize,
      timeRange,
      graphData,
    })

    setXAxis(renderedXAxis)

    return () => {
      selectAll(`g.xAxis${graphKey}`).remove()
    }
  }, [graphDataGroup, graphWidth, baseWidth])

  return !primaryYLineScale || !xAxis ? (
    <g />
  ) : (
    <g className="energy-graph">
      {dataset.length > 0 &&
        dataset.map(
          (productData, productIdx) =>
            productData.length > 0 && (
              <g
                key={`groupday-${productData[0].data.hour.unix()}`}
                className={`groupday-${productData[0].data.hour.unix()}`}
                onClick={() => {
                  handleClick(productIdx)
                }}
                onMouseLeave={() => {
                  handleHoverOut()
                }}
                onMouseOver={(e: React.MouseEvent) => {
                  // Mass lint disable
                  // Mass lint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                  handleHover(e.target, productData[0], xAxis.x)
                }}
              >
                {sortBy(
                  filter(
                    productData,
                    (data) =>
                      // Filter out bars with value/height of 0
                      !isEqual(data[0], data[1])
                  ),
                  (data) => data.product.order
                ).map((d, i) => {
                  // migration to strict mode batch disable
                  // Mass lint disable
                  // Mass eslint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  const barFullWidth: number = xAxis.x.bandwidth()
                  const barHeight: number = getBarHeight(
                    d,
                    primaryYLineScale,
                    height
                  )
                  const barX: number = getBarX(
                    // Mass lint disable
                    // Mass lint disable
                    // Mass eslint disable
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    xAxis.x(d.data.hour.unix()),
                    paddingX,
                    marginX
                  )
                  const barY: number = getBarY(d, primaryYLineScale)

                  return (
                    <g key={`groupday-${d.data.hour.unix()}-${i}`}>
                      {
                        // Don't render unnecessary "background bar" and "bar" for bars that overlap the previous product
                        // They get their background bar and colored bar from the previous product
                        d.product.productType !==
                          ProductType.ReceivedBundled && (
                          <>
                            <rect
                              className={`energy-graph-bg${graphKey}`}
                              fill={theme.palette.common.white}
                              height={barHeight}
                              width={barFullWidth}
                              x={getBackgroundBarX(barX, paddingX, marginX)}
                              y={barY}
                            />
                            <rect
                              className={`energy-graph-bar graph-bar-${d.data.hour.unix()}${
                                !(
                                  d.data.products[0].isBundledEnergyCustomer() &&
                                  timeRange === TIME_RANGES.DAY
                                )
                                  ? " link"
                                  : ""
                              }`}
                              data-e2e="energy-graph-bar"
                              fill={getBarFill(
                                d,
                                // Mass lint disable
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                hoverTimestamp,
                                // Mass lint disable
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                isFlyoutActive
                              )}
                              height={barHeight}
                              opacity={getBarOpacity(
                                d,
                                // Mass lint disable
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                hoverTimestamp,
                                // Mass lint disable
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                isFlyoutActive
                              )}
                              width={getBarWidth(
                                barFullWidth,
                                paddingX,
                                marginX
                              )}
                              x={barX}
                              y={barY}
                            />
                          </>
                        )
                      }
                      {
                        // Only render "inner bar" for bars with outlines when the bar is tall enough
                        isProductTypeReceivedBundledOrLongImbalance(
                          d.product.productType
                        ) &&
                          barHeight >= strokeWidth && (
                            <rect
                              className={`energy-graph-bar graph-bar-${d.data.hour.unix()}${
                                !(
                                  d.data.products[0].isBundledEnergyCustomer() &&
                                  timeRange === TIME_RANGES.DAY
                                )
                                  ? " link"
                                  : ""
                              }`}
                              fill={getInnerBarFill(
                                d,
                                // Mass lint disable
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                hoverTimestamp,
                                // Mass lint disable
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                isFlyoutActive
                              )}
                              height={getInnerBarHeight(barHeight, strokeWidth)}
                              width={getInnerBarWidth(
                                barFullWidth,
                                paddingX,
                                marginX,
                                strokeWidth
                              )}
                              x={getInnerBarX(barX, strokeWidth)}
                              y={getInnerBarY(barY, barHeight, strokeWidth)}
                            />
                          )
                      }
                    </g>
                  )
                })}

                {showCostOrCarbonIntensity ===
                  GRAPH_COST_OR_CARBON_INTENSITY.COST && (
                  <circle
                    className="avg-cost-circle"
                    cx={
                      // migration to strict mode batch disable
                      // Mass lint disable
                      // Mass eslint disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                      xAxis.x(productData[0].data.hour.unix()) +
                      // Mass lint disable
                      // Mass eslint disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                      xAxis.x.bandwidth() / 2
                    }
                    // migration to strict mode batch disable
                    // Mass eslint disable
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                    cy={secondaryYLineScale(avgCost(productData))}
                    fill={theme.palette.common.black}
                    r="2.5"
                  />
                )}

                {showCostOrCarbonIntensity ===
                  GRAPH_COST_OR_CARBON_INTENSITY.CARBON_INTENSITY && (
                  <circle
                    className="avg-cost-circle"
                    cx={
                      // migration to strict mode batch disable
                      // Mass lint disable
                      // Mass eslint disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                      xAxis.x(productData[0].data.hour.unix()) +
                      // Mass lint disable
                      // Mass eslint disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                      xAxis.x.bandwidth() / 2
                    }
                    // migration to strict mode batch disable
                    // Mass eslint disable
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                    cy={secondaryYLineScale(avgCarbonIntensity(productData))}
                    fill={theme.palette.common.black}
                    r="2.5"
                  />
                )}
              </g>
            )
        )}

      {
        // Must render out curtailment icons after bars so the curtailment tooltip displays on top of subsequent bars
        dataset.length > 0 &&
          dataset.map(
            (productData) =>
              productData.length > 0 &&
              productData[0].data.products.some((p) => p.curtailment()) && (
                <CurtailmentIcon
                  key={`groupday-curtailment-${productData[0].data.hour.unix()}`}
                  // migration to strict mode batch disable
                  // Mass lint disable
                  // Mass eslint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  barXPos={xAxis.x(productData[0].data.hour.unix())}
                  // migration to strict mode batch disable
                  // Mass eslint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                  barYPos={primaryYLineScale(
                    last(
                      filter(productData, (productDatum) => productDatum[1])
                    )[1]
                  )}
                  margin={marginX}
                  // migration to strict mode batch disable
                  // Mass lint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  x={xAxis.x}
                  xPadding={paddingX}
                  yPadding={paddingY}
                />
              )
          )
      }

      {showMeterData && (
        <MeterLine
          graphData={graphData}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          height={height}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          maxDomainValue={maxVolumeDomain}
          minWidth={groupOffset}
          // migration to strict mode batch disable
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          tickWidth={xAxis.x.bandwidth()}
        />
      )}

      {showCostOrCarbonIntensity === GRAPH_COST_OR_CARBON_INTENSITY.COST && (
        <AvgCostLine
          graphData={graphData}
          minWidth={groupOffset}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          secondaryYLineScale={secondaryYLineScale}
          // migration to strict mode batch disable
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          tickWidth={xAxis.x.bandwidth()}
        />
      )}

      {showCostOrCarbonIntensity ===
        GRAPH_COST_OR_CARBON_INTENSITY.CARBON_INTENSITY && (
        <AvgCarbonIntensityLine
          graphData={graphData}
          minWidth={groupOffset}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          secondaryYLineScale={secondaryYLineScale}
          // migration to strict mode batch disable
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          tickWidth={xAxis.x.bandwidth()}
        />
      )}

      <GroupLabel
        graphData={graphDataGroup}
        minWidth={groupOffset}
        timeRange={timeRange}
      />
    </g>
  )
}

import { useEffect } from "react"

import { useAvailableReportDates } from "@/services"
import { useActiveOrganizationId } from "@/services/organization"
import type { Moment } from "moment"

import useFilters from "../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../models/filter/dateFilter"
import SingleSelectStringFilter from "../../../../models/filter/singleSelectStringFilter"
import type { IRange } from "../../../../models/range"
import {
  ElectricityEmissionsFactor,
  ElectricityEmissionsFactorIds,
} from "../../../../models/scopeTwo"
import {
  getMostRecentPreviousYearDateRange,
  isValidCrossYearDateRange,
} from "../../../../utils/date"
import {
  MonthlySitesReportKey,
  emissionOptions,
} from "../../models/monthlySite"
import type { MonthlySiteDataFilters } from "../../models/monthlySiteData"

interface MonthlySiteDataResolverProps {
  children: JSX.Element
}

/**
 * A component that ensures the organization is set up before rendering the component
 *
 * @param props - The component properties
 * @param props.children - The children to render
 * @example
 * return (
 *   <MonthlySiteDataResolver>
 *     <MonthlySiteData />
 *   </MonthlySiteDataResolver>
 * )
 */
const MonthlySiteDataResolver = ({
  children,
}: MonthlySiteDataResolverProps): JSX.Element => {
  const { activeOrganizationId } = useActiveOrganizationId()

  const { availableReportDatesData, availableReportDatesIsFetched } =
    useAvailableReportDates(activeOrganizationId)

  const { filters, setFilters } = useFilters<MonthlySiteDataFilters>({
    start: DateFilter,
    end: DateFilter,
    data: SingleSelectStringFilter,
    electricityEmissionsFactor: SingleSelectStringFilter,
  })

  // Initialize "start" and "end" query params to valid values
  useEffect(() => {
    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // Don't rewrite valid date ranges
      !isValidCrossYearDateRange(
        {
          start: filters.start.value,
          end: filters.end.value,
        },
        availableReportDatesData
      )
    ) {
      const mostRecentPreviousYearDateRange: IRange<Moment> =
        getMostRecentPreviousYearDateRange(availableReportDatesData)

      setFilters(
        {
          start: new DateFilter(mostRecentPreviousYearDateRange.start),
          end: new DateFilter(mostRecentPreviousYearDateRange.end),
        },
        { replace: true }
      )
    }

    if (!filters.data.value || !emissionOptions.includes(filters.data.value)) {
      setFilters(
        {
          data: new SingleSelectStringFilter(MonthlySitesReportKey.TotalMtCo2e),
        },
        { replace: true }
      )
    }

    if (
      !ElectricityEmissionsFactorIds.includes(
        filters.electricityEmissionsFactor.value
      )
    ) {
      setFilters(
        {
          electricityEmissionsFactor: new SingleSelectStringFilter(
            ElectricityEmissionsFactor.AdvancedGridStudy.id
          ),
        },
        { replace: true }
      )
    }
  }, [
    availableReportDatesData,
    filters.end.value,
    filters.start.value,
    filters.data.value,
    filters.electricityEmissionsFactor.value,
    setFilters,
  ])

  if (!availableReportDatesIsFetched) {
    return null
  }

  return children
}

export default MonthlySiteDataResolver

import React from "react"
import type { FC } from "react"

import { Box, Paper, Typography } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import {
  lightestBlue,
  newBlue,
  pickledBluewood,
} from "../../../../utils/colors"
import { pxToRem } from "../../../../utils/unit"

interface DataAlertBaseProps {
  altText?: string
  body: string
  img?: string
  title: string
}

const styles = {
  container: {
    backgroundColor: lightestBlue.toString(),
    borderColor: newBlue.toString(),
    width: pxToRem(376),
    p: 2,
  },
}

const DataAlertBase: FC<DataAlertBaseProps> = ({
  title,
  body,
  img,
  altText,
}) => (
  <Paper sx={styles.container} variant="outlined">
    {img && (
      <Box
        alt={altText}
        component="img"
        src={img}
        sx={{ height: "57px", mb: 1 }}
      />
    )}
    <Typography
      color={pickledBluewood.toString()}
      component="h2"
      fontWeight="bold"
      mb={1}
    >
      {title}
    </Typography>
    <Typography
      color={pickledBluewood.toString()}
      mb={1}
      paragraph
      variant={TYPOGRAPHY_VARIANT.body2}
    >
      {body}
    </Typography>
  </Paper>
)

export default DataAlertBase
